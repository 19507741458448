import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContextfulBreadcrumbs } from '../app-components/breadcrumbs';
import { Layout, LayoutContentSheet } from '../app-components/layout';
import { SearchInputContext } from '../app-components/search-input';
import {
	SearchPageBottomCenterContainer,
	SearchPageSearchTipsContainer,
	SearchPaging,
	SearchResult,
} from '../app-components/search-page';
import { SearchTips } from '../app-components/search-shared';
import { SEO } from '../app-components/seo';
import { useSafeRoutingParameters } from '../app-hooks/route-parameters';
import {
	getPathForSearchRoute,
	useSearchQueryParameters,
	useSearchRouteNavigation,
} from '../app-hooks/search';
import { useCanonicalVersion } from '../app-hooks/versions';
import { Heading, Note, Paragraph } from '../content-components';

const RESULTS_PER_PAGE = 20;
const SEARCH_TIPS_RESULT_LIMIT = 10;

export const SearchRoute: FunctionComponent = () => {
	const { version: versionSlug } = useSafeRoutingParameters();

	const scrollContainerRef = useRef<HTMLDivElement | null>(null);

	const searchQueryParameters = useSearchQueryParameters();
	const initialSearchQueryRef = useRef(searchQueryParameters.query);
	const query = searchQueryParameters.query;

	const { error, isLoading, searchResults, onSearchResults } =
		useSearchRouteNavigation(
			versionSlug,
			searchQueryParameters.query,
			searchQueryParameters.page,
			scrollContainerRef
		);

	const navigate = useNavigate();
	const pagedSearchResults = useMemo(() => {
		if (error || !query) {
			return null;
		}
		const offset = searchQueryParameters.page * RESULTS_PER_PAGE;
		return (
			searchResults?.results.slice(offset, offset + RESULTS_PER_PAGE) ||
			null
		);
	}, [error, query, searchQueryParameters.page, searchResults?.results]);

	const handlePageChange = useCallback(
		(pageNumber: number) => {
			navigate(
				getPathForSearchRoute(
					versionSlug,
					searchQueryParameters.query,
					pageNumber
				)
			);
			window.document.getElementById(`scroll-container`)?.scrollTo(0, 0);
		},
		[versionSlug, searchQueryParameters.query, navigate]
	);

	const canonicalVersion = useCanonicalVersion();
	return (
		<SearchInputContext.Provider
			value={{
				onSearchResults,
				initialSearchQuery: initialSearchQueryRef.current,
			}}
		>
			<Layout enableSidebar={false}>
				<SEO
					title={`Search results for "${searchQueryParameters.query}"`}
					description={`Search results for "${searchQueryParameters.query}" on the Fonto Documentation website.`}
					canonicalLinkPath={
						typeof canonicalVersion === 'string'
							? null
							: canonicalVersion?.href || null
					}
				/>

				<LayoutContentSheet>
					<ContextfulBreadcrumbs />

					<Heading level={2}>
						{`Search returned ${
							searchResults?.resultsCount || 'no'
						} results. `}
						{isLoading && (
							<FontAwesomeIcon
								fixedWidth={true}
								icon={faSpinner}
								spin={true}
							/>
						)}
					</Heading>

					<SearchPageSearchTipsContainer>
						<SearchTips />
					</SearchPageSearchTipsContainer>

					{!!error && (
						<Note type="warning" icon={faExclamationTriangle}>
							<Paragraph>{error.message}</Paragraph>
						</Note>
					)}

					{pagedSearchResults &&
						pagedSearchResults.map((result) => (
							<SearchResult
								key={result.filePath}
								result={result}
								versionSlug={versionSlug}
							/>
						))}

					{(error ||
						!query ||
						!pagedSearchResults ||
						(!error &&
							!!searchResults &&
							searchResults.resultsCount <
								SEARCH_TIPS_RESULT_LIMIT)) && (
						<SearchPageBottomCenterContainer>
							<SearchTips />
						</SearchPageBottomCenterContainer>
					)}

					{!error &&
						!!searchResults &&
						!!searchResults.resultsCount && (
							<SearchPageBottomCenterContainer>
								{`(Viewed ${Math.min(
									searchResults.resultsCount,
									(searchQueryParameters.page + 1) *
										RESULTS_PER_PAGE
								)} out of ${
									searchResults.resultsCount
								} results)`}
							</SearchPageBottomCenterContainer>
						)}
				</LayoutContentSheet>

				{!error && !!searchResults && (
					<SearchPaging
						activePage={searchQueryParameters.page}
						numberOfPages={Math.ceil(
							searchResults.resultsCount / RESULTS_PER_PAGE
						)}
						onPageChange={handlePageChange}
					/>
				)}
			</Layout>
		</SearchInputContext.Provider>
	);
};
