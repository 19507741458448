import styled from '@emotion/styled/macro';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent, MouseEvent, PropsWithChildren } from 'react';

// A mapping of valid note "type" values and their associated colours.
const BUTTON_TYPES = {
	neutral: {
		backgroundColor: 'transparent',
		// @TODO Non-existent color code!
		borderColor: 'var(--legacy-color-purple)',
		// @TODO Non-existent color code!
		iconColor: 'var(--legacy-color-purple)',
	},
	passionate: {
		backgroundColor: 'transparent',
		borderColor: 'var(--color-purple-600)',
		iconColor: 'var(--color-purple-600)',
	},
};

const ButtonWrapper = styled.button`
	${({
		backgroundColor,
		borderColor,
	}: {
		backgroundColor: string;
		borderColor: string;
	}) => `
		color: ${borderColor};
		background-color: ${backgroundColor};
		border-color: ${borderColor};
	`};

	&:hover {
		border-color: var(--color-purple-600);
		background-color: var(--color-purple-600);
		color: var(--color-grey-50) !important;
		* {
			color: var(--color-grey-50) !important;
		}
	}
	&:focus {
		outline: none;
	}
	padding: var(--spacing-small) var(--spacing-medium-small);
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	border-style: solid;
	border-width: 1px;
	border-radius: var(--border-radius-small);

	cursor: pointer;
`;

const ButtonContentWrapper = styled.div`
	&:not(:first-of-type) {
		margin-left: var(--spacing-small);
	}

	> * + * {
		margin-top: var(--spacing-small);
	}
`;

type ButtonPropsType = PropsWithChildren & {
	type?: keyof typeof BUTTON_TYPES;
	icon?: IconProp;
	onClick?(event: MouseEvent<HTMLButtonElement, MouseEvent>): void;
};

export const Button: FunctionComponent<ButtonPropsType> = ({
	type,
	icon,
	onClick,
	children,
}) => {
	const colors = type ? BUTTON_TYPES[type] : BUTTON_TYPES.neutral;
	return (
		<ButtonWrapper
			backgroundColor={colors.backgroundColor}
			borderColor={colors.borderColor}
			onClick={onClick as any}
		>
			{icon && (
				<div>
					<FontAwesomeIcon
						icon={icon}
						fixedWidth
						color={colors.iconColor}
					/>
				</div>
			)}
			<ButtonContentWrapper>{children}</ButtonContentWrapper>
		</ButtonWrapper>
	);
};
