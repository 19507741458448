/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

/**
 * @file
 *
 * This entire file fucking sucks.
 *
 * It injects Cookiebot to retrieve consent for necessary, statistics or marketing cookies, unless
 * consent was already given.
 *
 * Then, if a Google Analytics key is available...
 *    ... and consent for marketing is given, GA tracks all pageviews
 *    ... and consent for statistic cookies is given, GA can show the helpful/not helpful button
 *
 * Also, if an ActiveCampaign key is available...
 *    ... and consent for marketing tracking is given, inject the AC script (and do nothing else).
 *
 *
 * See also the API docs for the CookieBot object:
 *   https://www.cookiebot.com/en/developer/
 *
 * https://developers.google.com/analytics/devguides/collection/analyticsjs/pages
 */

import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function injectScriptAtUrl(url: string, parent?: Element): void {
	const element = window.document.createElement('script');
	element.src = url;
	element.async = true;
	element.type = 'text/javascript';

	const parentElement =
		parent || window.document.getElementsByTagName('head')[0];
	parentElement.appendChild(element);
}

function injectScriptAtUrlAndCreateGlobal(
	url: string,
	alias: string,
	aliasKey: string
) {
	const api =
		(window as any)[alias] ||
		function (...args: unknown[]) {
			(api.q = api.q || []).push(args);
		};
	api.l = new Date().getTime();
	(window as any)[aliasKey] = alias;
	(window as any)[alias] = api;

	injectScriptAtUrl(url);

	return api;
}

function injectCookiebotScript(key: string): void {
	injectScriptAtUrl(`https://consent.cookiebot.com/uc.js?cbid=${key}`);
}
function injectCookiebotDom(key: string): void {
	injectScriptAtUrl(
		`https://consent.cookiebot.com/${key}/cd.js`,
		window.document.getElementsByTagName('body')[0]
	);
}

function injectGoogleAnalytics(key: string) {
	console.warn('Tracking you via Google Analytics…');
	const api = injectScriptAtUrlAndCreateGlobal(
		'https://www.google-analytics.com/analytics.js',
		'ga',
		'GoogleAnalyticsObject'
	);
	api('create', key, 'auto');
	api('send', 'pageview');
}

function injectActiveCampaign(key: string): void {
	console.warn('Tracking you via ActiveCampaign…');
	const api = injectScriptAtUrlAndCreateGlobal(
		'https://diffuser-cdn.app-us1.com/diffuser/diffuser.js',
		'vgo',
		'visitorGlobalObjectAlias'
	);
	api('setAccount', key);
	api('setTrackByDefault', true);
	api('process');
}

let IS_MARKETING_TRACKING_ENABLED = !!(window as any).Cookiebot?.consent
	?.marketing;

window.addEventListener('CookiebotOnConsentReady', () => {
	const cookieBot = (window as any).Cookiebot;
	if (!cookieBot) {
		return;
	}

	IS_MARKETING_TRACKING_ENABLED = cookieBot.consent.marketing;

	console.groupCollapsed('CookieBot');
	console.log(JSON.stringify(cookieBot.consent, null, '  '), cookieBot);
	console.log('You can resubmit your cookie preferences by running:');
	console.log('  window.Cookiebot.renew()');

	if (!IS_MARKETING_TRACKING_ENABLED) {
		console.log('No consent for marketing cookies received.');
		return;
	}

	if (process.env.REACT_APP_GOOGLE_ANALYTICS_KEY) {
		injectGoogleAnalytics(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
	}
	if (process.env.REACT_APP_ACTIVE_CAMPAIGN_KEY) {
		injectActiveCampaign(process.env.REACT_APP_ACTIVE_CAMPAIGN_KEY);
	}

	console.groupEnd();
});

if (process.env.REACT_APP_COOKIEBOT_KEY) {
	injectCookiebotScript(process.env.REACT_APP_COOKIEBOT_KEY);
	injectCookiebotDom(process.env.REACT_APP_COOKIEBOT_KEY);
}

export function usePageviewTrackingEffect(): void {
	const { pathname } = useLocation();
	useEffect(() => {
		if (!IS_MARKETING_TRACKING_ENABLED) {
			return;
		}

		(window as any).ga?.('send', 'pageview', pathname);
	}, [pathname]);
}

const NOT_VOTED = '__NOT_VOTED__';
type VoteValues = 'thumbsDown' | 'thumbsUp';

export function useUserFeedbackVote(path: string): {
	vote(value: VoteValues): void;
	hasVoted: boolean;
} {
	const [voteValue, setHasVoted] = useState(NOT_VOTED);
	const vote = useCallback(
		(value: VoteValues) => {
			const win = window as any;
			if (
				win.Cookiebot?.consent.statistics &&
				!win.ga &&
				process.env.REACT_APP_GOOGLE_ANALYTICS_KEY
			) {
				injectGoogleAnalytics(
					process.env.REACT_APP_GOOGLE_ANALYTICS_KEY
				);
			}
			// https://developers.google.com/analytics/devguides/collection/analyticsjs/events
			win.ga?.('send', 'event', 'Customer satisfaction', value, path, 1);
			setHasVoted(value);
		},
		[setHasVoted, path]
	);

	useEffect(() => {
		// Reset the state when voting on a different article
		setHasVoted(NOT_VOTED);
	}, [path]);

	const hasVoted = voteValue !== NOT_VOTED;
	return {
		vote,
		hasVoted,
	};
}
