import styled from '@emotion/styled/macro';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent } from 'react';
import { Fragment } from 'react';

import { Anchor } from '../atoms/links';

const BreadcrumbsContainer = styled.nav`
	font-family: var(--font-primary);
	font-size: var(--font-size-small);
	color: var(--color-grey-800);
	user-select: none;

	> a {
		// For breadcrumbs, the not/underline status is inverse from normal
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}

	> * + * {
		margin-left: var(--spacing-small);
	}
`;

export const Breadcrumbs: FunctionComponent<{
	trail: { label: string; href?: string }[];
}> = ({ trail }) => {
	return (
		<BreadcrumbsContainer>
			{trail.map((item, index) => {
				const caretIcon =
					index > 0 ? <FontAwesomeIcon icon={faAngleRight} /> : null;
				return (
					<Fragment key={index}>
						{caretIcon}
						{item.href ? (
							<Anchor href={item.href}>{item.label}</Anchor>
						) : (
							<span>{item.label}</span>
						)}
					</Fragment>
				);
			})}
		</BreadcrumbsContainer>
	);
};
