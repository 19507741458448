import { evaluateXPathToFirstNode, evaluateXPathToString } from 'fontoxpath';
import type { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

import { RULES_JSON_LD } from '../content-rules';

// Set in `.env`
const HARDCODED_TITLE = process.env.REACT_APP_TITLE;
const HARDCODED_DESCRIPTION = process.env.REACT_APP_DESCRIPTION;

// @TODO:
const HARDCODED_TWITTER_CREATOR = '@fontoxml';

export const SEO: FunctionComponent<{
	description?: string;
	title?: string;
	canonicalLinkPath: string | null;
	faqNodes?: Element[];
}> = ({ description = '', title, canonicalLinkPath, faqNodes }) => {
	const seoDescription = description || HARDCODED_DESCRIPTION;

	const seoTitle = title ? `${title} - ${HARDCODED_TITLE}` : HARDCODED_TITLE;

	const seoLinks = [];
	if (canonicalLinkPath) {
		// Use canonical URLs only when the canonicalLinkPath is set, and in that case prepend it with
		// the current hostname ("origin") if it's not already a full absolute URL.
		//
		// NOTE This assumes the SPA is always served from the URL root;
		seoLinks.push({
			rel: 'canonical',
			href: `${
				canonicalLinkPath.startsWith('http')
					? ''
					: window.location.origin
			}${canonicalLinkPath}`,
		});
	}

	const richFaqResultJson = !faqNodes?.length
		? null
		: {
				'@context': 'https://schema.org',
				'@type': 'FAQPage',
				mainEntity: faqNodes.map((node) => ({
					'@type': 'Question',
					name: evaluateXPathToString('./question', node),
					acceptedAnswer: {
						'@type': 'Answer',
						text: RULES_JSON_LD.render(
							(fn, props) => (fn ? fn(props) : ''),
							evaluateXPathToFirstNode(
								'./answer',
								node
							) as unknown as Node
						),
					},
				})),
		  };

	const seoMetas = [
		{
			name: `description`,
			content: seoDescription,
		},
		{
			property: `og:title`,
			content: seoTitle,
		},
		{
			property: `og:description`,
			content: seoDescription,
		},
		{
			property: `og:type`,
			content: `website`,
		},
		{
			name: `twitter:card`,
			content: `summary`,
		},
		{
			name: `twitter:creator`,
			content: HARDCODED_TWITTER_CREATOR,
		},
		{
			name: `twitter:title`,
			content: seoTitle,
		},
		{
			name: `twitter:description`,
			content: seoDescription,
		},
	];

	return (
		<Helmet
			htmlAttributes={{ lang: 'en' }}
			title={seoTitle}
			link={seoLinks}
			meta={seoMetas}
		>
			{richFaqResultJson && (
				<script type="application/ld+json">
					{JSON.stringify(richFaqResultJson, null, '\t')}
				</script>
			)}
		</Helmet>
	);
};
