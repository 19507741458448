import { evaluateXPathToBoolean, evaluateXPathToString } from 'fontoxpath';

import { SCHEMA_URI_FAD_2 } from '../../shared/constants';

/**
 * Our documentation site also shows the reader where an API is imported from. That text is generated, it is not written
 *   by a human. The rules as written for the original Confluence site have been adopted without further changes,
 *   because changing the import statement means a functional change to the documentation.
 *
 * The script will show an import message if the exported API has the same name as the file it is exported from, in
 *   which case it is assumed to be the default export.
 *
 * There is a special case for FDS, which _only_ uses named exports.
 */
function getFadV1ImportStatement(node: Node): string | null {
	let name = evaluateXPathToString('parent::*/name', node);
	const source = evaluateXPathToString('.', node);

	// If the element is a class and its name matches the filename then use it
	// to generate the documentation class name.
	// This way we can control which name to use for the singletons and classes.
	if (
		evaluateXPathToBoolean('parent::*/restrict/type[@base="class"]', node)
	) {
		const newName = name.charAt(0).toLowerCase() + name.substring(1);
		if (RegExp(`${newName}\\.\\w+$`, 'g').exec(source)) {
			name = newName;
		}
	}

	const [packageName, srcDir, ...otherSourceParts] = source.split('/');

	if (srcDir !== 'src') {
		// Source is not located in ./src, ignore it.
		// TODO: does this ever happen, and why?
		return null;
	}

	if (packageName === 'fds' || packageName === 'fontoxml-design-system') {
		const systemOrComponents = otherSourceParts[0];
		return `import { ${name} } from 'fds/${systemOrComponents}';`;
	}

	const moduleName = otherSourceParts[0];
	const moduleNameWithoutExtension = moduleName.replace(/\.[jt]sx?$/, '');
	// Ignore case differences to support an annotated Constructor vs. an
	// exported singleton.
	if (moduleNameWithoutExtension.toLowerCase() !== name.toLowerCase()) {
		// Item is different from file, assume it is not the default export for
		// this module. This happens, for example, with typedefs or with
		// actions inside an install.js.
		return null;
	}

	return `import ${name} from '${source.replace(/\.[jt]sx?$/, '')}';`;
}

function getFadV2ImportStatement(node: Node): string | null {
	if (evaluateXPathToBoolean('parent::*[not(@importable="true")]', node)) {
		return null;
	}

	const name = evaluateXPathToString('parent::*/name', node);
	const exportType = evaluateXPathToString('@export', node);
	const source = evaluateXPathToString('.', node);

	const [packageName, _src, fileName, ...deepImport] = source.split('/');

	if (packageName === 'fontoxml-design-system') {
		return `import { ${name} } from 'fds/${fileName}';`;
	}

	if (deepImport.length) {
		return null;
	}

	return `import ${
		exportType === 'named' ? `{ ${name} }` : name
	} from '${source.replace(/\.[jt]sx?$/, '')}';`;
}

export function getFadImportStatement(node: Node): string | null {
	if (
		evaluateXPathToBoolean(
			`parent::*/@xsi:noNamespaceSchemaLocation = "${SCHEMA_URI_FAD_2}"`,
			node
		)
	) {
		return getFadV2ImportStatement(node);
	}

	return getFadV1ImportStatement(node);
}
