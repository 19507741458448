import styled from '@emotion/styled/macro';

// This has the height of the full viewport (just like its sibling ContentContainer).
export const RightSidebarAnchor = styled.aside`
	flex: none;
	position: absolute;
	top: 0;
	right: 0;

	display: block;
`;

// This has a height based on the height of the H4 + page menu items.
export const RightSidebarContentContainer = styled.div`
	flex: none;
	position: fixed;

	display: flex;
	flex-direction: row;

	margin-left: var(--spacing-small);
	margin-right: var(--spacing-large);
	margin-bottom: var(--spacing-medium-small);

	@media (max-width: 1440px) {
		display: none;
	}
`;
