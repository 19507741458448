import styled from '@emotion/styled';
import { evaluateXPathToMap } from 'fontoxpath';
import type { FunctionComponent } from 'react';
import { Fragment, useMemo } from 'react';

import { DOCUMENT_NOT_FOUND } from '../app-hooks/content-rendering';
import { useSafeRoutingParameters } from '../app-hooks/route-parameters';
import urlToDocumentationDocsSvg from '../app-images/general-overview/documentation_docs.svg';
import { Anchor, Bold, Heading, Paragraph } from '../content-components';
import { ContextfulBreadcrumbs } from './breadcrumbs';
import { RssFeed } from './rss-feed';
import { SearchInput } from './search-input';

const SplitColumnsContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;

	margin-bottom: var(--spacing-large);
`;

const TextColumn = styled.div`
	flex: 1;

	display: flex;
	flex-direction: column;
`;

const ComicColumn = styled.div`
	flex: 1;

	margin-left: var(--spacing-large);

	max-width: 512px;

	@media (max-width: 864px) {
		display: none;
	}
`;

const SearchInputWithKeywords = styled.div`
	> * + * {
		margin-top: var(--spacing-small);
	}
`;

const PopularKeyWordsContainer = styled.div`
	margin-top: var(--spacing-medium-small);

	> P {
		display: inline;
		margin-right: var(--spacing-small);
		font-size: 14px;
		line-height: 2.07;
	}

	> a {
		color: var(--color-grey-800);
		font-family: var(--font-primary);
		font-size: 14px;

		margin-right: var(--spacing-small);

		line-height: 2.07;
		outline: none;

		text-decoration: none;

		&:hover {
			background-color: var(--color-purple-50);
		}
	}
`;

const BlogContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;

	margin-bottom: var(--spacing-large);
	text-align: center;
`;

// const MarginlessSection = styled.section`
// 	> * + * {
// 		margin-top: var(--spacing-medium-large) !important;
// 	}
// `;

// const RelatedInformationLinks = styled.div`
// 	margin-top: 0px !important;
// `;

const POPULAR_KEYWORDS = [
	'blockHeaderLeft',
	'configureAsListElements',
	'childNodeStructure',
	'concat',
	'documentsManager',
	'icon',
	'iframe',
	'addTransform',
	'attribute',
	'configureContextualOperations',
];

export const GeneralOverviewHeader: FunctionComponent<{
	xmlDocument: Node | typeof DOCUMENT_NOT_FOUND;
}> = ({ xmlDocument }) => {
	const { version } = useSafeRoutingParameters();

	const {
		title,
		intro,
		headerImageUrl,
		popularKeywords,
		popularKeywordsTitle,
	} = useMemo<{
		title?: string;
		intro?: string;
		headerImageUrl?: string;
		popularKeywordsTitle?: string;
		popularKeywords?: string[];
	}>(
		() =>
			xmlDocument === DOCUMENT_NOT_FOUND
				? {}
				: evaluateXPathToMap(
						`map {
							"title": string(/*/title),
							"intro": string(/*/shortdesc),
							"headerImageUrl": string(/*/body/fig[@outputclass="gatsby-header-image"]/image/@href),
							"popularKeywordsTitle": string(/*/body/section[@outputclass="gatsby-popular-keywords"]/title),
							"popularKeywords": array {
								/*/body/section[@outputclass="gatsby-popular-keywords"]/sl/sli/term/string()
							}
						}`,
						xmlDocument,
						null
				  ),
		[xmlDocument]
	);

	return (
		<>
			<SplitColumnsContainer>
				<TextColumn>
					<ContextfulBreadcrumbs />

					<Heading level={1}>
						{title || `Fonto Documentation`}
					</Heading>

					<Paragraph emphasis>
						{intro ||
							`Anything you'll need to get started with Fonto and
					anything you'll need to keep progressing with our
					products can be found right here.`}
					</Paragraph>
					<Paragraph>&nbsp;</Paragraph>

					<SearchInputWithKeywords>
						<SearchInput isStandalone />
						<PopularKeyWordsContainer>
							<Paragraph>
								<Bold>
									{popularKeywordsTitle ||
										'Popular keywords:'}
								</Bold>{' '}
							</Paragraph>
							{(popularKeywords?.length
								? popularKeywords
								: POPULAR_KEYWORDS
							).map((keyword) => (
								<Fragment key={keyword}>
									<Anchor
										href={`/${version}/search?q=${encodeURIComponent(
											keyword
										)}&page=0`}
									>
										{keyword}
									</Anchor>{' '}
								</Fragment>
							))}
						</PopularKeyWordsContainer>
					</SearchInputWithKeywords>
				</TextColumn>
				<ComicColumn>
					<img
						src={headerImageUrl || urlToDocumentationDocsSvg}
						alt=""
					/>
				</ComicColumn>
			</SplitColumnsContainer>
			<BlogContainer>
				<RssFeed />
			</BlogContainer>
		</>
	);
};
