import { useEffect, useState } from 'react';

import { useCurrentSitemapNode, useSitemap } from './sitemap';

export function useLayoutExpectation(initialEnableSidebar: boolean): {
	isGeneralOverview: boolean;
} {
	const [expectation, setExpectation] = useState({
		isGeneralOverview: !initialEnableSidebar,
	});

	const sitemap = useSitemap();
	const currentSitemapNode = useCurrentSitemapNode();

	useEffect(() => {
		if (
			typeof sitemap === 'string' ||
			typeof currentSitemapNode === 'string'
		) {
			return;
		}
		setExpectation({
			isGeneralOverview: sitemap[0] === currentSitemapNode,
		});
	}, [sitemap, currentSitemapNode]);

	return expectation;
}
