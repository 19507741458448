/**
 * A set of simple XML rendering rules directly to HTML nodes, for rendering JSON-LD that Google eats
 *   for rich search results.
 *
 * See also:
 *   https://developers.google.com/search/docs/advanced/structured-data/faqpage
 */

import { evaluateXPathToString } from 'fontoxpath';
import type { XmlRendererProps } from 'xml-renderer';
import { GenericRenderer } from 'xml-renderer';

const rules = new GenericRenderer<
	(props: XmlRendererProps<string>) => string,
	string
>();

rules.add('self::node()', ({ traverse }) => traverse().join(''));

rules.add('self::text()', ({ node }) =>
	String(node.nodeValue).replace(/\s{2,}/g, '')
);

rules.add('self::text()[ancestor::codeblock]', ({ node }) =>
	String(node.nodeValue)
);

rules.add('self::p', ({ traverse }) => `<p>${traverse().join('')}</p>`);

rules.add('self::ul', ({ traverse }) => `<ul>${traverse().join('')}</ul>`);

rules.add('self::ol', ({ traverse }) => `<ol>${traverse().join('')}</ol>`);

rules.add('self::li', ({ traverse }) => `<li>${traverse().join('')}</li>`);

rules.add('self::b', ({ traverse }) => `<b>${traverse().join('')}</b>`);

rules.add('self::i', ({ traverse }) => `<i>${traverse().join('')}</i>`);

rules.add('self::u', ({ traverse }) => `<u>${traverse().join('')}</u>`);

rules.add(
	'self::codeph',
	({ traverse }) => `<code>${traverse().join('')}</code>`
);

rules.add(
	'self::xref[@href]',
	({ traverse, node }) =>
		`<a href="${evaluateXPathToString('./@href', node)}">${traverse().join(
			''
		)}</a>`
);

rules.add(
	'self::codeblock',
	({ traverse }) => `<pre>${traverse().join('')}</pre>`
);

export default rules;
