import { ThemeProvider, useTheme } from '@emotion/react';
import type { FunctionComponent, PropsWithChildren } from 'react';
/**
 *
 * This part contains platform-specific options for the content rules. For example, `<Link>` may
 * be swapped out with a normal <a> when rendering for PDF instead of React.
 */
import { Link } from 'react-router-dom';

import type { LinkOptions } from '../atoms/links';
import type { PanelOptions } from '../molecules/panels';

// The component that handles all <a> type of things, in hyperlinks, breadcrumbs, buttons, etc.
const InnerAnchorComponent: FunctionComponent<
	PropsWithChildren & {
		href?: string;
		external?: boolean;
	}
> = ({ href, external, children, ...restProps }) => {
	if (!href) {
		return <b {...restProps}>{children}</b>;
	}
	// DEV-9921 Fragment link.
	if (href.startsWith('#')) {
		return (
			<a href={href} {...restProps}>
				{children}
			</a>
		);
	}

	const isLinkToFontoDotCom =
		!!/(http:|https:)?\/\/(www\.)?fontoxml\.com/.exec(href);
	if (external || isLinkToFontoDotCom) {
		// return (
		// 	<OutboundLink
		// 		href={href}
		// 		target={!isLinkToFontoDotCom ? '_blank' : undefined}
		// 		{...restProps}
		// 	/>
		// );
		return isLinkToFontoDotCom ? (
			<a href={href} {...restProps}>
				{children}
			</a>
		) : (
			<a href={href} target="_blank" rel="noreferrer" {...restProps}>
				{children}
			</a>
		);
	}

	return (
		<Link to={href} {...restProps}>
			{children}
		</Link>
	);
};

export const RENDERING_CONTEXT: StyledComponentsContext = {
	getAnchorIsExternal: (href: string): boolean =>
		// This is copied over from <Link>'s implementation in the "gatsby-link"
		// npm package; where Link resolves to.

		(href.startsWith('http://') ||
			href.startsWith('https://') ||
			href.startsWith('//')) &&
		!/(http:|https:)?\/\/(www\.)?fontoxml\.com/.exec(href),

	// The component that drives hyperlinks:
	innerAnchorComponent: InnerAnchorComponent,

	// The @media max-width at which clickable panels span half a row:
	responsivePanelHalfRowMaxViewportWidth: '1200px',

	// The @media max-width at which clickable panels span a full row:
	responsivePanelFullRowMaxViewportWidth: '864px',

	// The @media max-width at which horizontal panels span a full row:
	responsiveHorizontalPanelFullRowMaxViewportWidth: '864px',
};

/**
 *  End of part
 *
 */

export type StyledComponentsContext = LinkOptions & PanelOptions;

export function useStyledComponentsContext(
	this: void
): StyledComponentsContext {
	return useTheme() as StyledComponentsContext;
}

export const StyledComponentsContextProvider: FunctionComponent<
	PropsWithChildren & {
		options?: Partial<StyledComponentsContext>;
	}
> = ({ options, children }) => {
	return (
		<ThemeProvider theme={{ ...RENDERING_CONTEXT, ...options }}>
			{children}
		</ThemeProvider>
	);
};
