import styled from '@emotion/styled/macro';

export const HorizontalRuler = styled.hr`
	height: 1px;
	width: 75%;

	background-color: var(--color-grey-400);
`;

export const KeyboardButton = styled.span`
	border-radius: calc(var(--spacing-small) / 2);
	border: solid 1px var(--color-grey-400);
	box-shadow: 0 1px 0 0 var(--color-grey-400);
	background-color: var(--color-grey-100);
	line-height: var(--spacing-medium);
	padding: 1px var(--spacing-small);
	margin: -1px 0;

	color: var(--color-grey-800);
	font-family: var(--font-primary);
	font-size: 16px;
`;
