import styled from '@emotion/styled';
import { faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent, RefObject } from 'react';
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { useSafeRoutingParameters } from '../app-hooks/route-parameters';
import type { OnSearchResultsCallback } from '../app-hooks/search';
import { useSearchResultsFetcher } from '../app-hooks/search';
import { SearchDropResults } from './search-drop';

export const SearchFormContainer = styled.div<{ isStandalone: boolean }>`
	flex: none;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 var(--spacing-small);
	width: 100%;
	height: 40px;
	${({ isStandalone }) => isStandalone && `margin: 0; max-width: 100%;`}
	background-color: var(--color-grey-50);
	border: solid 1px var(--color-grey-500);
	outline: none;
	border-radius: var(--border-radius-small);
`;

export const SearchFormInput = styled.input`
	margin-left: var(--spacing-small);
	width: calc(100% - 3 * var(--spacing-small));
	box-sizing: border-box;
	height: 24px;
	border: none;
	outline: none;
	font-family: var(--font-primary);
	font-size: 16px;
	font-weight: var(--font-weight-normal);
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: var(--color-grey-800);
	&::placeholder {
		color: var(--color-grey-800);
		text-overflow: ellipsis;
	}
`;

export const SearchFormClear = styled.div`
	color: var(--color-grey-400);
	height: 16px;
	margin-left: auto;
	margin-right: 5px;
	cursor: pointer;
	&:hover {
		color: var(--color-grey-800);
	}
`;

export const SearchInputContext = createContext<
	| {
			onSearchResults?: OnSearchResultsCallback;
			initialSearchQuery?: string;
	  }
	| undefined
>(undefined);

// @TODO refactor this to debounce the expensive function call, instead of setting several states
//   at various intervals. Avoid unnecessary rerendering as well as (almost) duplicate state variables.
function useDelayedState(value: string, delay: number) {
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
}

function useClickOutsideHandler(
	ref: RefObject<HTMLDivElement>,
	onClickOutside: () => void
) {
	useEffect(() => {
		const handleWindowClick = (event: MouseEvent) => {
			const isContained =
				ref.current && ref.current.contains(event.target as Node);

			// Fix for the clean button which is removed from the page on click, and thus is was not
			// contained by ref.current anymore.
			const isContainedByPage = window.document.contains(
				event.target as Node
			);

			if (isContainedByPage && !isContained) {
				onClickOutside();
			}
		};

		window.document.addEventListener('click', handleWindowClick);

		return () => {
			window.document.removeEventListener('click', handleWindowClick);
		};
	}, [ref, onClickOutside]);
}

export const SearchInput: FunctionComponent<{
	isStandalone: boolean;
	onClickOutside?(): void;
	onFocus?(): void;
}> = ({ isStandalone, onFocus, onClickOutside }) => {
	const { version: versionSlug } = useSafeRoutingParameters();
	const [hasFocus, setHasFocus] = useState(false);

	// Register window events to handle expanded state.
	const ref = useRef<HTMLDivElement>(null);
	useClickOutsideHandler(
		ref,
		useCallback(() => {
			if (onClickOutside) {
				onClickOutside();
			}

			setHasFocus(false);
		}, [onClickOutside, setHasFocus])
	);

	// Optional context used by the search page.
	const searchInputContext = useContext(SearchInputContext);
	const onSearchResults = searchInputContext?.onSearchResults;

	// Handle the input field's text value.
	const [textInputValue, setTextInputValue] = useState(
		searchInputContext?.initialSearchQuery || ''
	);
	const handleTextInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setTextInputValue(event.currentTarget.value);
			if (!hasFocus) {
				// Make sure the drop is shown and the input focussed when value changes.
				if (onFocus) {
					onFocus();
				}

				setHasFocus(true);
			}
		},
		[hasFocus, onFocus]
	);

	// Debounce the text input value and make do a fetch if needed.
	const debouncedTextInputValue = useDelayedState(textInputValue, 400);
	const { searchResults, error, isLoading } = useSearchResultsFetcher(
		versionSlug,
		debouncedTextInputValue,
		onSearchResults ? undefined : 3,
		(hasFocus && !isStandalone) || !!onSearchResults,
		onSearchResults
	);

	const handleSearchInputFocus = useCallback(() => {
		if (onFocus) {
			onFocus();
		}
		setHasFocus(true);
	}, [onFocus]);

	// Keep a reference to the search input field so it can be focussed.
	const inputRef = useRef<HTMLInputElement>(null);

	const handleClearButtonClick = useCallback(() => {
		setTextInputValue('');
		inputRef.current?.focus();
	}, []);

	const handleSearchIconClick = useCallback(() => {
		inputRef.current?.focus();
	}, []);

	const navigate = useNavigate();
	const handleTextInputKeyUp = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			if (onSearchResults) {
				// There is a search context, so we're on the search page and don't need to navigate to it
				return;
			}

			if (event.key === 'Enter') {
				// Listen for enter/return key on input, and redirect to the search page.
				event.preventDefault();
				event.stopPropagation();

				navigate(
					`/${versionSlug}/search?q=${encodeURIComponent(
						event.currentTarget.value
					)}&page=0`
				);
			} else if (event.key === 'Escape') {
				// Listen for escape key on input, and simulate click on outside.
				event.preventDefault();
				event.stopPropagation();

				setHasFocus(false);
			}
		},
		[onSearchResults, versionSlug, navigate]
	);

	return (
		<SearchFormContainer
			onClick={handleSearchIconClick}
			onFocus={handleSearchInputFocus}
			ref={ref}
			isStandalone={!!isStandalone}
			tabIndex={0}
		>
			<FontAwesomeIcon icon={faSearch} fixedWidth />

			<SearchFormInput
				type="text"
				value={textInputValue}
				placeholder="Search documentation…"
				onChange={handleTextInputChange}
				onKeyUp={handleTextInputKeyUp}
				ref={inputRef}
			/>

			{hasFocus && !!textInputValue && (
				<SearchFormClear onClick={handleClearButtonClick}>
					<FontAwesomeIcon icon={faTimes} />
				</SearchFormClear>
			)}

			{hasFocus && !isStandalone && !onSearchResults && (
				<SearchDropResults
					error={error}
					isLoading={isLoading}
					query={debouncedTextInputValue}
					results={searchResults}
					versionSlug={versionSlug}
				/>
			)}
		</SearchFormContainer>
	);
};
