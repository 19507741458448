import styled from '@emotion/styled/macro';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useEffect, useRef } from 'react';

import { useSafeRoutingParameters } from '../app-hooks/route-parameters';
import { useScrollToToggleMainNavigation } from '../app-hooks/scroll-offset';
import { GlobalAppStyles } from '../app-styling/global';
import { DebugContent } from './debug-content';
import Footer from './footer';
import { MainNavigation } from './main-navigation';
import { ModalRoot } from './modals';
import { LeftSidebar } from './sidebar-left';

/**
 * The wrapper for all of the visible app. Is exactly full-screen, and provides the constraints in
 * which sidebars, content views and so on must provide their own scrollbars.
 */
const LayoutContainer = styled.div`
	width: 100vw;
	height: 100vh;
	flex: 1;
	display: flex;
	flex-direction: column;
	background-color: var(--legacy-color-light-grey);
	overflow: hidden;
	--sidebars-width: calc(11 * var(--spacing-medium));
`;

/**
 * The row of sidebar/body content directly below the main navigation bar
 */
const LayoutBody = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	flex: 1 1 auto;
	overflow: hidden;
`;

/**
 * The portion of the site that contains article content, and scrolls individually from sidebars
 * etc.
 */
const LayoutContent = styled.div`
	flex: 1;
	position: relative;
	overflow-x: auto;
	overflow-y: scroll;

	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0 var(--spacing-medium);

	@media (max-width: 1000px) {
		padding: 0;
	}
`;

/**
 * It looks like a sheet frame!
 */
export const LayoutContentSheet = styled.main`
	position: relative;
	flex-direction: column;
	flex: none;

	margin: var(--spacing-large) auto;
	padding: var(--spacing-large);

	box-sizing: border-box;
	max-width: 1152px;
	border-radius: var(--border-radius-smaller);

	background-color: var(--color-grey-50);
	box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.16);
	@media (max-width: 1000px) {
		margin-left: 0;
		margin-right: 0;
	}
`;

/**
 * This component literally provides the counterbalance against having a sidebar on the left, by
 * occupying space in the (scrollable) container where the page content lives.
 */
export const LayoutContentAlignmentFiller = styled.div`
	width: var(--sidebars-width);

	@media (max-width: 1440px) {
		display: none;
	}
`;
export const LayoutContentAndFooter = styled.div`
	flex: 1;
`;
export const LayoutContentAndFooterBoundary = styled.div<{ isWide: boolean }>`
	margin: 0 auto;
	max-width: ${({ isWide }) =>
		isWide ? `1152px` : `calc(41 * var(--spacing-medium))`};

	@media (max-width: 1440px) {
		max-width: 100%;
	}
`;
export const Layout: FunctionComponent<
	PropsWithChildren & {
		enableSidebar: boolean;
	}
> = ({ children, enableSidebar }) => {
	// A reference to the main navigation element, so it can be animated out of view on a scroll trigger
	const mainNavigationRef = useRef<HTMLElement>(null);
	const contentScrollContainerRef = useRef<HTMLDivElement | null>(null);

	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { onContentScroll, onContentWheel } = useScrollToToggleMainNavigation(
		{
			mainNavigationRef,
			contentScrollContainerRef,
		}
	);

	// When navigating to a new page (ignore the hash, look only at changes to the path), scroll
	//   the content back to top.
	const { path } = useSafeRoutingParameters();
	useEffect(() => {
		if (!contentScrollContainerRef?.current) {
			return;
		}
		contentScrollContainerRef.current.scrollTop = 0;
	}, [path]);

	return (
		<>
			<GlobalAppStyles />
			<LayoutContainer>
				<MainNavigation ref={mainNavigationRef} />

				<LayoutBody>
					<LeftSidebar isEnabled={enableSidebar} />

					<LayoutContent
						id="scroll-container"
						onScroll={onContentScroll}
						onWheel={onContentWheel}
						// @TODO:
						ref={contentScrollContainerRef}
					>
						<LayoutContentAndFooter>
							<LayoutContentAndFooterBoundary
								isWide={!enableSidebar}
							>
								<DebugContent />
								{children}

								<Footer layoutHasSidebar={true} />
							</LayoutContentAndFooterBoundary>
						</LayoutContentAndFooter>

						{enableSidebar && <LayoutContentAlignmentFiller />}
					</LayoutContent>
				</LayoutBody>

				<ModalRoot />
			</LayoutContainer>
		</>
	);
};
