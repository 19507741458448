import type { Location } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

type SafeRoutingParameters =
	| {
			isSearch: false;
			isDocument: true;
			version: string;
			document: string | null;
			path: string;
			hash?: string;
	  }
	| {
			isSearch: true;
			isDocument: false;
			version: string;
			document: null;
			path: string;
			hash?: string;
	  };

export function getSafeRoutingParametersFromLocation(
	location: Location
): SafeRoutingParameters {
	const { pathname, hash } = location;

	// The URLs `/`, `/latest` and `/latest/` are hardcoded to be the site root, `/`;
	const [version, document] = /^\/(latest\/?){0,1}$/.exec(pathname)
		? []
		: pathname.split('/').filter(Boolean);

	const effectiveVersion = version || 'latest';

	if (document === 'search') {
		return {
			isSearch: true,
			isDocument: false,

			version: effectiveVersion,
			document: null,
			hash,
			path: `/${[effectiveVersion, 'search'].join('/')}`,
		};
	}

	return {
		isSearch: false,
		isDocument: true,

		version: effectiveVersion,
		document: document || null,
		hash,
		path:
			!document && !version
				? '/'
				: `/${[effectiveVersion, document].filter(Boolean).join('/')}`,
	};
}

export function useSafeRoutingParameters(): SafeRoutingParameters {
	const location = useLocation();
	return getSafeRoutingParametersFromLocation(location);
}
