/* eslint-disable @typescript-eslint/no-explicit-any */

import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import type { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { Component } from 'react';
import type { Location, NavigateFunction, Params } from 'react-router-dom';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Layout, LayoutContentSheet } from '../app-components/layout';
import { getSafeRoutingParametersFromLocation } from '../app-hooks/route-parameters';
import { Heading, Note, Paragraph, Section } from '../content-components';
import { NotFoundRoute } from './NotFoundRoute';

type ErrorBoundaryEnabledRouteProps = PropsWithChildren<{
	happyFlowComponent: FunctionComponent<any>;
	restProps?: { [key: string]: any };
	router: {
		location: Location;
		navigate: NavigateFunction;
		params: Readonly<Params>;
	};
}>;

type ErrorBoundaryEnabledRouteState = { error: Error | null; path: string };

class ErrorBoundaryEnabledRouteInternal extends Component<
	ErrorBoundaryEnabledRouteProps,
	ErrorBoundaryEnabledRouteState
> {
	public constructor(props: ErrorBoundaryEnabledRouteProps) {
		super(props);

		const { path } = getSafeRoutingParametersFromLocation(
			this.props.router.location
		);
		this.state = { error: null, path };
	}

	public static getDerivedStateFromError(error: Error): { error: Error } {
		return { error };
	}

	public componentDidUpdate(
		_prevProps: ErrorBoundaryEnabledRouteProps,
		_prevState: ErrorBoundaryEnabledRouteState
	): void {
		const { path } = getSafeRoutingParametersFromLocation(
			this.props.router.location
		);
		if (this.state.error && this.state.path !== path) {
			// If there is an error and the path has changed, clear the error and update the path.
			this.setState({ error: null, path });
		} else if (this.state.path !== path) {
			// If the path has changed, update the path.
			this.setState({ path });
		}
	}

	public render(): ReactNode {
		if (!this.state.error) {
			const HappyFlow = this.props.happyFlowComponent;
			return <HappyFlow {...this.props.restProps} />;
		}
		return <ErrorRoute error={this.state.error} />;
	}
}

// This replaces the old 'withRouter' HOC of React Router. It's still required
// for the class component above because it cannot be converted to a function
// component as error boundaries are (currently) limited to class components.
function withRouter(Component: any) {
	function ComponentWithRouterProp(props: any) {
		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();
		return <Component {...props} router={{ location, navigate, params }} />;
	}

	return ComponentWithRouterProp;
}

export const ErrorBoundaryEnabledRoute = withRouter(
	ErrorBoundaryEnabledRouteInternal
);

export class Error404 extends Error {}

const ErrorRoute: FunctionComponent<{ error: Error | Error404 }> = ({
	error,
}) => {
	if (error instanceof Error404) {
		return <NotFoundRoute />;
	}
	return (
		<Layout enableSidebar={false}>
			<LayoutContentSheet>
				<Section>
					<Heading level={1}>
						🤭 Encountered an unforgivable error
					</Heading>
					<Paragraph>
						Oops! You&apos;re totally not supposed to see this.
						While serving you this page we encountered an error that
						we cannot recover from. It&apos;s not that the page
						doesn&apos;t exist, it&apos;s us that dropped the ball.
					</Paragraph>
					<Paragraph>
						If this error keeps occurring, please send us your
						question via our support board or email!
					</Paragraph>
					<Note type="warning" icon={faExclamationTriangle}>
						<pre style={{ fontSize: '0.7em' }}>
							{error.stack || error.message}
						</pre>
					</Note>
				</Section>
			</LayoutContentSheet>
		</Layout>
	);
};
