import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import type { FunctionComponent, PropsWithChildren, ReactElement } from 'react';

import { Heading } from '../atoms/headings';
import { Anchor } from '../atoms/links';
import { Section } from '../atoms/sections';
import { SPACING_SMALL } from '../shared/global';

type ClickablePanelPropsType = PropsWithChildren & {
	image?: string;
	imageAlternateText?: string;
	label?: ReactElement | string;
	href?: string;
};

/**
 * Container elements
 */

export const HorizontalClickablePanels = styled.section`
	--panel-margin: calc(var(--spacing-medium-large) / 2);

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex: none;
	justify-content: flex-start;
	margin-left: calc(var(--panel-margin) * -1);
	margin-right: calc(var(--panel-margin) * -1);
	> * {
		margin: var(--panel-margin);
	}
`;

export const ClickablePanels = styled.section`
	--panel-margin: calc(var(--spacing-medium) / 2);

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex: none;
	justify-content: flex-start;
	margin-left: calc(var(--panel-margin) * -1);
	margin-right: calc(var(--panel-margin) * -1);
	> * {
		margin: var(--panel-margin);
	}
`;

/**
 * Panel molecules
 */

export type PanelOptions = {
	responsivePanelFullRowMaxViewportWidth?: string;
	responsivePanelHalfRowMaxViewportWidth?: string;
	responsiveHorizontalPanelFullRowMaxViewportWidth?: string;
};

/**
 * Styled components for panels
 */

// This currently assumes all values for to will be internal links,
// if that changes, this needs a similar approach as used for ClickablePanel.
const HorizontalClickablePanelStyledComponent = styled(Anchor)`
	color: var(--color-grey-800);
	background-color: var(--color-grey-50);
	border: none;
	box-shadow: var(--elevation-200);
	border-radius: var(--border-radius-medium);
	cursor: pointer;
	display: flex;
	flex: none;
	text-decoration: none;
	transition: 0.2s box-shadow;
	&:hover {
		box-shadow: var(--elevation-500);
	}

	flex-direction: row;
	padding: var(--spacing-medium-small);

	> img {
		width: 128px;
		height: auto;
	}

	> img + ${Section} {
		margin-left: var(--spacing-medium);
	}

	> ${Section} {
		flex: 1 1 auto;
	}

	width: calc(50% - 2 * var(--panel-margin));

	${({ theme }) =>
		theme.responsiveHorizontalPanelFullRowMaxViewportWidth
			? css`
					@media (max-width: ${theme.responsiveHorizontalPanelFullRowMaxViewportWidth}) {
						width: calc(100% - 2 * var(--panel-margin));
					}
			  `
			: null}
`;

const ClickablePanelStyledComponent = styled(Anchor)`
	color: var(--color-grey-800);
	background-color: var(--color-grey-50);
	border: none;
	box-shadow: var(--elevation-200);
	border-radius: var(--border-radius-medium);
	cursor: pointer;
	display: flex;
	flex: none;
	text-decoration: none;
	transition: 0.2s box-shadow;
	&:hover {
		box-shadow: var(--elevation-500);
	}

	flex-direction: column;
	padding: 0;

	> img {
		background-color: var(--color-grey-100);
		height: 144px;
		padding: var(--spacing-medium-small);
	}

	> ${Section} {
		padding: var(--spacing-medium-small);
	}

	width: calc(33.33% - 2 * var(--panel-margin));

	${({ theme }) =>
		theme.responsivePanelHalfRowMaxViewportWidth
			? css`
					@media (max-width: ${theme.responsivePanelHalfRowMaxViewportWidth}) {
						width: calc(50% - 2 * var(--panel-margin));
					}
			  `
			: null}

	${({ theme }) =>
		theme.responsivePanelFullRowMaxViewportWidth
			? css`
					@media (max-width: ${theme.responsivePanelFullRowMaxViewportWidth}) {
						width: calc(100% - 2 * var(--panel-margin));
					}
			  `
			: null}
`;

export const HorizontalClickablePanel: FunctionComponent<ClickablePanelPropsType> =
	({ children, image, imageAlternateText, label, href }) => {
		return (
			<HorizontalClickablePanelStyledComponent
				href={href}
				disableExternalLinkIndicator
			>
				{image ? <img src={image} alt={imageAlternateText} /> : null}
				<Section spacing={SPACING_SMALL}>
					{typeof label === 'string' ? (
						<Heading level={4}>{label}</Heading>
					) : (
						label
					)}

					{children}
				</Section>
			</HorizontalClickablePanelStyledComponent>
		);
	};

export const ClickablePanel: FunctionComponent<ClickablePanelPropsType> = ({
	children,
	image,
	imageAlternateText,
	label,
	href,
}) => (
	<ClickablePanelStyledComponent href={href} disableExternalLinkIndicator>
		{typeof image === 'string' ? (
			<img src={image} alt={imageAlternateText} />
		) : (
			image
		)}
		<Section spacing={SPACING_SMALL}>
			{typeof label === 'string' ? (
				<Heading level={4}>{label}</Heading>
			) : (
				label
			)}

			{children}
		</Section>
	</ClickablePanelStyledComponent>
);
