import type { VersionJson } from '../types';
import type { ENDPOINT_LOADING } from './endpoint-caching';
import { useEndpointCache } from './endpoint-caching';
import { useSafeRoutingParameters } from './route-parameters';

export const VERSION_LIST_NOT_FOUND = '__VERSION_LIST_NOT_FOUND__';
export const VERSION_NOT_FOUND = '__VERSION_NOT_FOUND__';

const FETCH_VERSIONS_FOR_DOCUMENT = async (
	documentSlug: string | null
): Promise<VersionJson[] | typeof VERSION_LIST_NOT_FOUND> => {
	const response = await fetch(
		`/static/versions${documentSlug ? `/${documentSlug}` : ''}.json`
	);
	if (!response.ok) {
		return VERSION_LIST_NOT_FOUND;
	}
	return response.json();
};

export function useVersionList():
	| VersionJson[]
	| typeof ENDPOINT_LOADING
	| typeof VERSION_LIST_NOT_FOUND {
	const { document } = useSafeRoutingParameters();
	return useEndpointCache(FETCH_VERSIONS_FOR_DOCUMENT, document);
}

export function useCanonicalVersion():
	| VersionJson
	| typeof ENDPOINT_LOADING
	| typeof VERSION_LIST_NOT_FOUND
	| null {
	const { path } = useSafeRoutingParameters();
	const versions = useVersionList();
	if (typeof versions === 'string') {
		return versions;
	}
	if (path === versions[0].href) {
		// User is already visiting the canonical version
		return null;
	}

	return versions[0];
}

export function useCurrentVersion():
	| VersionJson
	| typeof ENDPOINT_LOADING
	| typeof VERSION_LIST_NOT_FOUND
	| typeof VERSION_NOT_FOUND {
	const { version } = useSafeRoutingParameters();
	const versions = useVersionList();
	if (typeof versions === 'string') {
		return versions;
	}
	const versionJson = versions.find((v) => v.slug === version);
	if (!versionJson) {
		return VERSION_NOT_FOUND;
	}
	return versionJson;
}
