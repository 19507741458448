import styled from '@emotion/styled/macro';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Bold } from '../atoms/inline-formatting';
import { Paragraph } from '../atoms/paragraphs';
import Prism from '../shared/prism';

export type CodeBlockLanguageType = {
	label?: string;
	prismClass: string;
	ditaAttributeMatches: string[];
	fadAttributeMatches: string[];
};

const backgroundColor = 'hsl(220, 13%, 18%)';
const backgroundColorSelected = 'hsl(220, 13%, 40%)';
const prismColors = {
	char: '#D8DEE9',
	comment: '#B2B2B2',
	keyword: '#c5a5c5',
	lineHighlight: '#353b45', // colors.dark + extra lightness
	primitive: '#5a9bcf',
	string: '#8dc891',
	variable: '#d7deea',
	boolean: '#ff8b50',
	punctuation: '#88C6BE',
	tag: '#fc929e',
	function: '#79b6f2',
	className: '#FAC863',
	method: '#6699CC',
	operator: '#fc929e',
};

export const CODE_BLOCK_LANGUAGE_MAP = {
	other: {
		label: 'Other',
		prismClass: 'other',
		ditaAttributeMatches: ['x-code-other'],
		fadAttributeMatches: ['other'],
	},
	csharp: {
		label: 'C#',
		prismClass: 'csharp',
		ditaAttributeMatches: ['x-code-csharp'],
		fadAttributeMatches: ['csharp'],
	},
	html: {
		label: 'HTML',
		prismClass: 'html',
		ditaAttributeMatches: ['x-code-html'],
		fadAttributeMatches: ['html'],
	},
	js: {
		label: 'JavaScript',
		prismClass: 'javascript',
		ditaAttributeMatches: ['x-code-js'],
		fadAttributeMatches: ['javascript', 'js'],
	},
	ts: {
		label: 'TypeScript',
		prismClass: 'typescript',
		ditaAttributeMatches: ['x-code-ts'],
		fadAttributeMatches: ['typescript', 'ts'],
	},
	json: {
		label: 'JSON',
		prismClass: 'json',
		ditaAttributeMatches: ['x-code-json'],
		fadAttributeMatches: ['json'],
	},
	jsx: {
		label: 'JSX',
		prismClass: 'jsx',
		ditaAttributeMatches: ['x-code-jsx'],
		fadAttributeMatches: ['jsx'],
	},
	tsx: {
		label: 'TSX',
		prismClass: 'tsx',
		ditaAttributeMatches: ['x-code-tsx'],
		fadAttributeMatches: ['tsx'],
	},
	shell: {
		label: 'Shell',
		prismClass: 'shell',
		ditaAttributeMatches: ['x-code-shell'],
		fadAttributeMatches: ['shell'],
	},
	xml: {
		label: 'XML',
		prismClass: 'xml',
		ditaAttributeMatches: ['x-code-xml'],
		fadAttributeMatches: ['xml'],
	},
	xquery: {
		label: 'XQuery',
		prismClass: 'xquery',
		ditaAttributeMatches: ['x-code-xquery'],
		fadAttributeMatches: ['xquery', 'xqm'],
	},
};

export const CODE_BLOCK_LANGUAGE_LIST: CodeBlockLanguageType[] = Object.keys(
	CODE_BLOCK_LANGUAGE_MAP
).map(
	(name) =>
		CODE_BLOCK_LANGUAGE_MAP[name as keyof typeof CODE_BLOCK_LANGUAGE_MAP]
);

const CodeBlockContainer = styled.div`
	border-radius: var(--border-radius-medium);
	overflow: hidden;

	background-color: ${backgroundColor};
`;

// @TODO: Better deal with long header text.
const CodeBlockHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	padding: var(--spacing-small) var(--spacing-medium);

	border-bottom: 1px solid var(--legacy-color-silver);

	> * {
		margin: 0;
		color: var(--legacy-color-silver) !important;
	}
`;

const Content = styled.div`
	// For IconContainer and Popover
	position: relative;
	text-align: left;
`;

const PrismContainer = styled.pre`
	// Styles based on Prism's 'Okaidia' theme, adjusted for a better fit and
	// an increased contrast. Colors inspired by Codepen's high contrast theme.
	// See: https://codepen.io/klare/pen/jgZBma.
	// See: https://github.com/PrismJS/prism/blob/master/themes/prism-okaidia.css

	&,
	* {
		background-color: transparent !important;

		::selection {
			background-color: ${backgroundColorSelected};
		}
	}

	--line-numbers-width: var(--spacing-large);

	&[class*='language-'] {
		padding: var(--spacing-small);
		margin: 0;

		border-radius: 0;

		overflow-x: auto;
		width: 100%;

		&,
		> code {
			color: #ffffff;

			font-family: var(--font-code);
			font-stretch: normal;
			font-style: normal;
			font-weight: var(--font-weight-normal);

			/* line-height: 1.81; */

			text-shadow: none;
		}

		&,
		> code,
		> code > .line-numbers-rows {
			font-size: 14px;
		}

		&::selection,
		::selection,
		> code::selection,
		> code ::selection {
			text-shadow: none;
		}

		// Prism 'Okaidia' theme color options.
		> code {
			.token.attr-name {
				color: ${prismColors.keyword};
			}

			.token.comment,
			.token.block-comment,
			.token.prolog,
			.token.doctype,
			.token.cdata {
				color: ${prismColors.comment};
			}

			.token.property,
			.token.number,
			.token.function-name,
			.token.constant,
			.token.symbol,
			.token.deleted {
				color: ${prismColors.primitive};
			}

			.token.boolean {
				color: ${prismColors.boolean};
			}
			.token.tag {
				color: ${prismColors.tag};
			}

			.token.string {
				color: ${prismColors.string};
			}

			.token.punctuation {
				color: ${prismColors.punctuation};
			}

			.token.selector,
			.token.char,
			.token.builtin,
			.token.inserted {
				color: ${prismColors.char};
			}

			.token.function {
				color: ${prismColors.function};
			}

			.token.operator,
			.token.entity,
			.token.url,
			.token.variable {
				color: ${prismColors.variable};
			}

			.token.attr-value {
				color: ${prismColors.string};
			}

			.token.keyword {
				color: ${prismColors.keyword};
			}

			.token.atrule,
			.token.class-name {
				color: ${prismColors.className};
			}

			.token.important {
				font-weight: var(--font-weight-normal);
			}

			.token.bold {
				font-weight: var(--font-weight-bold);
			}

			.token.italic {
				font-style: italic;
			}

			.token.entity {
				cursor: help;
			}

			.namespace {
				opacity: 0.7;
			}

			// Prism line-numbers plugin style options.
			// See: https://github.com/PrismJS/prism/blob/master/plugins/line-numbers/prism-line-numbers.css

			&.line-numbers {
				padding-left: calc(
					var(--line-numbers-width) + var(--spacing-medium-small)
				);
			}

			.line-numbers-rows {
				top: -3px;
				left: calc(
					(var(--line-numbers-width) + var(--spacing-small)) * -1
				);

				width: var(--line-numbers-width);

				> span:before {
					color: var(--legacy-color-silver);
				}
			}
		}
	}
`;

const IconContainer = styled.div`
	position: absolute;
	top: var(--spacing-small);
	right: var(--spacing-medium);
	z-index: 1;

	background-color: ${backgroundColor};
	padding: 3px;
	border-radius: var(--border-radius-small);

	cursor: pointer;

	display: none;
	*:hover > & {
		display: block;
	}
`;

const Popover = styled.div`
	position: absolute;

	// Same top as the icon t's pointing to.
	top: var(--spacing-small);
	// Position to the left of the icon it's pointing to.
	right: calc(var(--spacing-medium) + 32px);

	z-index: 1;

	color: var(--color-grey-800);
	background-color: var(--color-grey-100);

	font-size: 16px;
	// Don't let text rendering determine the height of the popover...
	line-height: 0px;
	// ...but use padding instead.
	padding: var(--spacing-medium-small) var(--spacing-small);

	&::after {
		content: '';
		position: absolute;

		width: 12px;
		height: 12px;
		transform: rotate(45deg);

		background-color: var(--color-grey-100);

		// Half of the parent's padding minus half height of own height to
		// to account for rotation.
		top: calc(var(--spacing-medium-small) - (12px / 2));
		// Negative half width.
		right: -6px;
	}
`;

export function getSupportedLanguageForDitaValue(
	xmlLangAttributeValue: string
): CodeBlockLanguageType {
	return (
		CODE_BLOCK_LANGUAGE_LIST.find((language) =>
			language.ditaAttributeMatches.includes(xmlLangAttributeValue)
		) || CODE_BLOCK_LANGUAGE_MAP.other
	);
}

export function getSupportedLanguageForFadValue(
	xmlLangAttributeValue: string
): CodeBlockLanguageType {
	return (
		CODE_BLOCK_LANGUAGE_LIST.find((language) =>
			language.fadAttributeMatches.includes(xmlLangAttributeValue)
		) || CODE_BLOCK_LANGUAGE_MAP.other
	);
}

export function getLanguageByPrismClass(
	prismClassName: string
): CodeBlockLanguageType {
	return (
		CODE_BLOCK_LANGUAGE_LIST.find((language) =>
			language.prismClass.includes(prismClassName)
		) || CODE_BLOCK_LANGUAGE_MAP.other
	);
}

type CodeBlockPropsType = PropsWithChildren & {
	language?: CodeBlockLanguageType;
	title?: ReactNode;
};

export const CodeBlock: FunctionComponent<CodeBlockPropsType> = ({
	children,
	language = CODE_BLOCK_LANGUAGE_MAP.other,
	title,
}) => {
	const codeRef = useRef<HTMLElement>(null);

	useEffect(() => {
		if (codeRef.current) {
			Prism.highlightElement(codeRef.current);
		}
	}, [codeRef]);

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const handleCopyButtonClick = useCallback(() => {
		if (!codeRef.current) {
			return;
		}
		codeRef.current.focus();

		const range = window.document.createRange();
		range.selectNodeContents(codeRef.current);

		const selection = window.getSelection();
		// see https://developer.mozilla.org/en-US/docs/Web/API/Window/getSelection
		/* When called on an <iframe> that is not displayed (eg. where
		display: none is set) Firefox will return null, whereas other browsers
		will return a Selection object with Selection.type set to None. */
		// So to satisfy TS, even though we know we won't get null in our case:
		if (!selection) {
			return;
		}
		selection.removeAllRanges();
		selection.addRange(range);

		window.document.execCommand('copy');
		selection.removeAllRanges();

		setIsPopoverOpen(true);

		setTimeout(() => {
			setIsPopoverOpen(false);
		}, 1000);
	}, []);

	return (
		<CodeBlockContainer>
			{title || (language.label && language.label !== '') ? (
				<CodeBlockHeader>
					<Paragraph>
						<Bold>{title}</Bold>
					</Paragraph>
					{language.label ? (
						<Paragraph>{language.label}</Paragraph>
					) : null}
				</CodeBlockHeader>
			) : null}

			<Content>
				<PrismContainer className="line-numbers">
					<code
						ref={codeRef}
						className={`language-${language.prismClass}`}
					>
						{children}
					</code>
				</PrismContainer>

				<IconContainer>
					<FontAwesomeIcon
						onClick={handleCopyButtonClick}
						icon={faCopy}
						size="lg"
						color="var(--color-grey-50)"
					/>
				</IconContainer>

				{isPopoverOpen && <Popover>Copied!</Popover>}
			</Content>
		</CodeBlockContainer>
	);
};
