import styled from '@emotion/styled/macro';

import { SPACING_MEDIUM_LARGE } from '../shared/global';
import { mixinSpaceChildren } from '../shared/mixins';

export const Section = styled.section<{
	spacing?: string;
	inset?: string;
}>`
	margin-top: var(--spacing-larger) !important;
	margin-bottom: var(--spacing-larger) !important;

	&:first-of-type {
		margin-top: 0 !important;
	}
	&:last-child {
		margin-bottom: 0 !important;
	}

	${({ spacing = SPACING_MEDIUM_LARGE }) => mixinSpaceChildren(spacing)};

	${({ inset }) =>
		inset
			? `
				background-color: var(--legacy-color-sand-grey);
				padding: var(--spacing-large) ${inset};
				margin-left: calc(${inset} * -1);
				margin-right: calc(${inset} * -1);
			`
			: null}
`;

export const TaskSection = styled(Section)`
	margin-top: 0 !important;
	margin-bottom: var(--spacing-large) !important;
`;
