import { useEffect, useRef } from 'react';

import type { useDocumentDom } from './content-rendering';
import { useSafeRoutingParameters } from './route-parameters';

export function useScrollToDeeplinkEffect(
	dom: ReturnType<typeof useDocumentDom>
): void {
	const { hash } = useSafeRoutingParameters();
	const lastDom = useRef<ReturnType<typeof useDocumentDom> | null>(null);
	useEffect(() => {
		if (typeof dom === 'string' || lastDom.current === dom) {
			// If the DOM is not fully mounted, or if we're not navigating to a new/different page.
			// Ignore all scroll mechanisms.
			return;
		}
		lastDom.current = dom;

		if (!hash) {
			return;
		}
		requestAnimationFrame(() => {
			const element = window.document.getElementById(hash.substr(1));
			const scrollContainer =
				window.document.getElementById('scroll-container');
			if (!element || !scrollContainer) {
				return;
			}

			scrollContainer.scroll(
				0,
				element.getBoundingClientRect().top -
					scrollContainer.getBoundingClientRect().top
			);
		});
	}, [dom, hash]);
}
