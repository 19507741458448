import { evaluateXPath } from 'fontoxpath';

import type { TableOfContentsItem } from '../types';

const QUERY = `
	declare function Q{get-dita-page-menu-items}recurse($node) {
		array {
			$node[self::task]/taskbody/section/map {
				"label": string(./title),
				"id": string(@id),
				"node": .,
				"children": Q{get-dita-page-menu-items}recurse(.),
				"traversal": "./node()"
			},
			$node[self::task]/taskbody/steps/step/(
				let $index := count(preceding-sibling::step) + 1
				return map {
					"label": concat("Step ", $index, ": ", string(./cmd)),
					"id": concat("step-", $index),
					"node": .,
					"children": array{},
					"traversal": "./node()"
				}
			),
			$node[self::topic or self::concept or self::task or self::faqlist[parent::*]]/(topic|concept|faqlist)/map {
				"label": string(./title),
				"id": string(@id),
				"node": .,
				"children": Q{get-dita-page-menu-items}recurse(.),
				"traversal": "./node()"
			},
			$node/inbound-references/map {
				"label": "This article is referenced from...",
				"id": "inbound-references",
				"node": .,
				"children": array{},
				"traversal": "./node()"
			}
		}
	};
	Q{get-dita-page-menu-items}recurse(/*)
`;

/**
 * Retrieves a hierarchical object that represents significant sections in DITA content.
 *
 * - For normal topics that means all sub- and descendant topics.
 * - For DITA tasks, each step is a section.
 *
 * The returned object can be used by <PageMenu>
 *
 */
export function getDitaTableOfContents(
	node: Document | Node
): TableOfContentsItem[] {
	return evaluateXPath(QUERY, node, undefined, undefined, undefined, {
		language: evaluateXPath.XQUERY_3_1_LANGUAGE,
	});
}
