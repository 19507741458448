import {
	evaluateXPathToBoolean,
	evaluateXPathToMap,
	evaluateXPathToNodes,
	evaluateXPathToString,
} from 'fontoxpath';
import type { FunctionComponent } from 'react';
import { createElement, useMemo } from 'react';

import { ContextfulBreadcrumbs } from '../app-components/breadcrumbs';
import { ChildPageList } from '../app-components/child-page-list';
import { GeneralOverviewHeader } from '../app-components/general-overview';
import { Layout, LayoutContentSheet } from '../app-components/layout';
import { SEO } from '../app-components/seo';
import {
	RightSidebarAnchor,
	RightSidebarContentContainer,
} from '../app-components/sidebar-right';
import WasThisPageHelpful from '../app-components/user-feedback';
import {
	DOCUMENT_NOT_FOUND,
	getUrlForPath,
	useDocumentDom,
	useRenderingRules,
} from '../app-hooks/content-rendering';
import { useScrollToDeeplinkEffect } from '../app-hooks/deep-linking';
import { ENDPOINT_LOADING } from '../app-hooks/endpoint-caching';
import { useLayoutExpectation } from '../app-hooks/layouting';
import { useSafeRoutingParameters } from '../app-hooks/route-parameters';
import {
	SITEMAP_NODE_NOT_FOUND,
	useCurrentSitemapNode,
} from '../app-hooks/sitemap';
import usePageMenuForDocumentScrollPosition from '../app-hooks/usePageMenuForDocumentScrollPosition';
import { usePageviewTrackingEffect } from '../app-hooks/user-tracking';
import { useCanonicalVersion } from '../app-hooks/versions';
import { Heading, Section, Spinner } from '../content-components';
import { SPACING_LARGEST } from '../content-components/shared/global';
import { NS_URI_FAD } from '../shared/constants';
import type { VersionJson } from '../types';
import { Error404 } from './ErrorRoute';
import { NotFoundLayoutContent } from './NotFoundRoute';

const WHY_HOW_PREFIX = 'Fonto Why & How: ';

function determineCanonicalLinkPath(
	canonicalVersion: VersionJson | string | null,
	dom: Node
): string | null {
	// Fonto Why and How posts are also on wordpress (the main site at
	// https://www.fontoxml.com/blog/).  That is actually the canonical location. We can recognize
	// why and how pages by their outputclass, and predict the new url from the title.  For example
	// 'https://www.fontoxml.com/blog/fonto-why-and-how-how-do-i-make-a-dynamic-toolbar/' is the
	// location for a post with the title 'How do I make a dynamic toolbar!?
	const isWhyAndHowPost = evaluateXPathToBoolean(
		'/topic/@outputclass = "why-and-how"',
		dom
	);
	if (isWhyAndHowPost) {
		const sanitizedTitle = evaluateXPathToString(
			`($WHY_HOW_PREFIX || /topic/title)
=> lower-case() (: Lower-case the title :)
=> normalize-space() (: Remove consecutive spaces :)
=> replace("[^a-z ]", '') (: Remove any special characters :)
=> replace(" +", "-") (: And replace spaces with dashes :)`,
			dom,
			null,
			{ WHY_HOW_PREFIX }
		);
		return `https://www.fontoxml.com/blog/${sanitizedTitle}`;
	}

	if (typeof canonicalVersion === 'string') {
		return null;
	}
	return canonicalVersion?.href || null;
}

const XmlRoutePageMenu: FunctionComponent<{ dom: Node }> = ({ dom }) => {
	return usePageMenuForDocumentScrollPosition(dom);
};

export const XmlRenderingInner: FunctionComponent<{
	dom: ReturnType<typeof useDocumentDom>;
}> = ({ dom }) => {
	const rules = useRenderingRules(dom);
	const canonicalVersion = useCanonicalVersion();
	const currentSitemapNode = useCurrentSitemapNode();

	if (currentSitemapNode === SITEMAP_NODE_NOT_FOUND) {
		throw new Error404('Expected the sitemap node to be found');
	}

	const renderedContent = useMemo(() => {
		if (typeof rules === 'string' || typeof dom === 'string') {
			return null;
		}
		return rules.render(createElement, dom as unknown as Node, {
			articleHeroWidget: null,
		});
	}, [rules, dom]);

	if (
		dom === ENDPOINT_LOADING ||
		rules === ENDPOINT_LOADING ||
		canonicalVersion === ENDPOINT_LOADING ||
		currentSitemapNode === ENDPOINT_LOADING
	) {
		return <Spinner size={SPACING_LARGEST} />;
	}

	if (dom === DOCUMENT_NOT_FOUND || rules === DOCUMENT_NOT_FOUND) {
		return (
			<>
				<SEO
					title={currentSitemapNode.label}
					canonicalLinkPath={
						typeof canonicalVersion === 'string'
							? null
							: canonicalVersion?.href || null
					}
				/>
				<Section>
					<Heading level={1}>{currentSitemapNode.label}</Heading>
					<ChildPageList />
				</Section>
			</>
		);
	}

	const seoData = evaluateXPathToMap(
		`if (namespace-uri(/*) = $fadNsUri)
				then map {
					"title": string(/*/name),
					"description": substring(string(/*/summary), 1, 300)
				}
				else map {
					"title": string(/*/title),
					"description": substring(string(/*/shortdesc), 1, 300)
				}`,
		dom,
		null,
		{
			fadNsUri: NS_URI_FAD,
		}
	) as {
		title: string;
		description: string;
	};

	return (
		<>
			<SEO
				title={seoData?.title}
				description={seoData?.description}
				canonicalLinkPath={determineCanonicalLinkPath(
					canonicalVersion,
					dom
				)}
				faqNodes={evaluateXPathToNodes('//faq', dom)}
			/>
			{renderedContent}
		</>
	);
};

export const XmlRoute: FunctionComponent<{ expectSidebar: boolean }> = ({
	expectSidebar,
}) => {
	usePageviewTrackingEffect();
	const { path } = useSafeRoutingParameters();
	const dom = useDocumentDom(getUrlForPath(path));
	useScrollToDeeplinkEffect(dom);
	const currentSitemapNode = useCurrentSitemapNode();

	const { isGeneralOverview } = useLayoutExpectation(expectSidebar);

	return (
		<Layout enableSidebar={!isGeneralOverview}>
			<LayoutContentSheet>
				{currentSitemapNode === SITEMAP_NODE_NOT_FOUND ? (
					<NotFoundLayoutContent />
				) : (
					<>
						{!isGeneralOverview && typeof dom !== 'string' && (
							<RightSidebarAnchor>
								<RightSidebarContentContainer>
									<XmlRoutePageMenu dom={dom} />
								</RightSidebarContentContainer>
							</RightSidebarAnchor>
						)}

						{isGeneralOverview ? (
							dom !== ENDPOINT_LOADING && (
								<GeneralOverviewHeader xmlDocument={dom} />
							)
						) : (
							<ContextfulBreadcrumbs />
						)}

						<XmlRenderingInner dom={dom} />

						<WasThisPageHelpful />
					</>
				)}
			</LayoutContentSheet>
		</Layout>
	);
};
