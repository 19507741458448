import { keyframes } from '@emotion/react';
import styled from '@emotion/styled/macro';

import type { CssColor, CssLength } from '../shared/global';
import { SPACING_LARGE } from '../shared/global';

const spinnerKeyframes = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

export const Spinner = styled.div<{
	size?: CssLength;
	carriageColor?: CssColor;
	trackColor?: CssColor;
}>`
	${({ size, carriageColor, trackColor }) => `
		border-top: 1px solid ${trackColor};
		border-right: 1px solid ${trackColor};
		border-bottom: 1px solid ${trackColor};
		border-left: 1px solid ${carriageColor};

		&,
		&:after {
			width: ${size};
			height: ${size};
		}
	`}
	flex-shrink: 0 !important;
	font-size: 10px;
	position: relative;
	transform: translateZ(0);
	box-sizing: border-box;
	animation: ${spinnerKeyframes} 0.3s infinite linear;

	&,
	&:after {
		border-radius: 50%;
	}
`;

Spinner.defaultProps = {
	size: SPACING_LARGE,
	carriageColor: 'var(--legacy-color-pink)',
	trackColor: 'var(--legacy-color-medium-grey)',
};
