import styled from '@emotion/styled/macro';
import {
	faGithub,
	faLinkedin,
	faTwitter,
	faVimeo,
} from '@fortawesome/free-brands-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent } from 'react';
import { useCallback, useState } from 'react';

import useWindowWidth from '../app-hooks/useWindowWidth';
import {
	Anchor,
	Heading,
	ListItem,
	Paragraph,
	UnorderedList,
} from '../content-components';

const FOOTER_ITEMS: FooterMenuCategory[] = [
	{
		category: 'Getting started',
		links: [
			{
				label: 'Fonto Editor',
				to: '/latest/fonto-editor-352846dc93f6',
			},
			{
				label: 'Fonto Review',
				to: '/latest/fonto-review-8ca615260377',
			},
			{
				label: 'Fonto Content Quality',
				to: '/latest/fonto-content-quality-21845e46ef2c',
			},
			{
				label: 'Fonto Document History',
				to: '/latest/fonto-document-history-5a117c375303',
			},
		],
	},
	{
		category: 'Developers',
		links: [
			{
				label: 'Integrate Fonto into a CMS',
				to: '/latest/integrate-0cd4a24d50c0',
			},
			{
				label: 'CMS contracts',
				to: '/latest/cms-contracts-5d5a426d1041',
			},
			{
				label: 'Release notes',
				to: '/latest/release-notes-4a9c8592513b',
			},
			{
				label: 'Add-ons',
				to: '/latest/add-ons-56fcb6851ff4',
			},
			{ label: 'Github', to: 'https://github.com/FontoXML/' },
			{
				label: 'XPath playground',
				to: 'https://xpath.playground.fontoxml.com/',
			},
			{
				label: 'FDS playground',
				to: 'https://fds.playground.fontoxml.com/',
			},
		],
	},
	{
		category: 'API reference',
		links: [
			{
				label: 'Operations',
				to: '/latest/operations-94d59bf035f0',
			},
			{
				label: 'Managers',
				to: '/latest/managers-dcc1bfc2ce25',
			},
			{
				label: 'FDS components',
				to: '/latest/design-system-components-5757818c57ab',
			},
			{
				label: 'Families',
				to: '/latest/cvk-families-9de78fd43088',
			},
			{
				label: 'Widgets',
				to: '/latest/cvk-widgets-b341170b9c23',
			},
			{
				label: 'Transforms',
				to: '/latest/transforms-3ba38c45118c',
			},
			{
				label: 'Actions',
				to: '/latest/actions-cf01064275b4',
			},
		],
	},
	{
		category: 'Fonto',
		links: [
			{ label: 'Support desk', to: 'https://support.fontoxml.com/' },
			{ label: 'About', to: 'https://www.fontoxml.com/about/' },
			{ label: 'Jobs', to: 'https://www.fontoxml.com/jobs/' },
			{
				label: 'Internships',
				to: 'https://www.fontoxml.com/jobs/internships-graduation-assignments/',
			},
			{
				label: 'Graduation Assignments',
				to: 'https://www.fontoxml.com/jobs/internships-graduation-assignments/',
			},
			{
				label: 'Become a Fonto Partner',
				to: 'https://www.fontoxml.com/partners/',
			},
		],
	},
];

type FooterMenuCategory = {
	// Determines the caption of this group of links:
	category: string;

	links: FooterMenuItem[];
};

type FooterMenuItem = {
	// The human-readable text for this link or menu item:
	label: string;

	// The URL for this link or menu item. Could be a path relative to the site root (starts with "/"), or an external
	// URL (starts with "//", "http://" or "https://")
	to: string;
};

type FooterContainerProps = {
	layoutHasSidebar: boolean;
};

const FooterContainer = styled.footer<FooterContainerProps>`
	flex: none;
	user-select: none;

	margin: ${({ layoutHasSidebar }) =>
		layoutHasSidebar
			? 'var(--spacing-large) auto'
			: 'var(--spacing-large)'};
	margin-top: ${({ layoutHasSidebar }) => !layoutHasSidebar && 0};

	max-width: ${({ layoutHasSidebar }) =>
		layoutHasSidebar ? '936px' : '1152px'};
`;

const ColumnsContainer = styled.nav<FooterContainerProps>`
	display: flex;
	justify-content: space-between;

	@media (max-width: 600px) {
		flex-direction: column;
		border-top: 1px solid var(--color-grey-300);
	}

	margin-bottom: 32px;

	> * + * {
		margin-left: ${({ layoutHasSidebar }) =>
			layoutHasSidebar ? '24px' : '64'};

		@media (max-width: 1167px) {
			margin-left: ${({ layoutHasSidebar }) =>
				!layoutHasSidebar && '16px'};
		}

		@media (max-width: 600px) {
			margin-left: 0;
		}
	}
`;

const Column = styled.div<FooterContainerProps>`
	width: ${({ layoutHasSidebar }) => (layoutHasSidebar ? '216px' : '240px')};

	@media (max-width: 1280px) {
		width: ${({ layoutHasSidebar }) => layoutHasSidebar && '184px'};
	}

	@media (max-width: 1024px) {
		width: ${({ layoutHasSidebar }) =>
			layoutHasSidebar ? '160px' : '224px'};
	}

	@media (max-width: 860px) {
		width: ${({ layoutHasSidebar }) =>
			layoutHasSidebar ? '120px' : '184px'};
	}

	@media (max-width: 600px) {
		width: 100%;
	}

	> ul {
		margin: 16px 0;
		@media (max-width: 600px) {
			border-top: 1px solid var(--color-grey-300);
			border-bottom: 1px solid var(--color-grey-300);
			margin: 0;
		}
		padding: 0;

		> li {
			padding-left: var(--spacing-small);
			@media (max-width: 600px) {
				padding-left: 30px;
			}

			list-style-type: none;

			height: 40px;

			font-size: 14px;
			font-family: var(--font-primary);

			&:hover {
				background-color: var(--color-purple-200);
				border-radius: var(--border-radius-small);
			}

			> a {
				display: flex;
				align-items: center;

				height: 40px;
			}
		}

		> li + li {
			@media (max-width: 600px) {
				border-top: 1px solid var(--color-grey-300);
			}
		}
	}
`;

type IconAndH3ContainerProps = {
	menuItemIsCollapsed: boolean;
};

const IconAndH3Container = styled.div<IconAndH3ContainerProps>`
	display: flex;

	@media (max-width: 600px) {
		border-bottom: ${({ menuItemIsCollapsed: isCollapsed }) =>
			isCollapsed && '1px solid var(--color-grey-300)'};

		background-color: ${({ menuItemIsCollapsed: isCollapsed }) =>
			!isCollapsed && 'var(--color-purple-200)'};

		&:hover {
			background-color: var(--color-purple-200);
		}

		cursor: pointer;
	}

	> H3 {
		font-size: 16px;

		padding-left: var(--spacing-small);

		@media (max-width: 600px) {
			display: flex;
			align-items: center;
			height: 40px;
		}
	}
`;

const DropIconContainer = styled.span`
	@media (max-width: 600px) {
		padding-left: var(--spacing-small);
	}

	> svg {
		display: flex;
		align-items: center;

		height: 40px;

		color: var(--color-grey-800);

		@media (min-width: 601px) {
			display: none;
		}
	}
`;

const FooterBottomColumnContainer = styled.div`
	display: flex;
	justify-content: space-between;

	margin-bottom: 32px;

	@media (max-width: 600px) {
		margin-left: var(--spacing-small);
	}

	@media (max-width: 860px) {
		flex-direction: column;
	}

	@media (min-width: 601px) {
		border-top: 1px solid var(--color-grey-300);
		border-bottom: 1px solid var(--color-grey-300);
	}
`;

const FooterBottomColumn = styled.div`
	display: flex;
	align-items: center;

	height: 48px;

	> * + * {
		margin-left: var(--spacing-small);
	}

	> p {
		font-size: 12px;
	}
`;

const FooterLinksContainer = styled.div`
	display: flex;
`;

const FooterLink = styled.div`
	> a {
		display: flex;
		align-items: center;

		height: 40px;

		text-decoration: none;
		color: var(--color-purple-900);

		font-size: 12px;
		font-family: var(--font-primary);

		margin-left: 4px;
	}
`;

const FooterBottomColumnLinkSeparator = styled.div`
	display: flex;
	align-items: center;

	color: var(--color-grey-300);

	font-size: 12px;

	margin-left: 4px;
`;

const IconContainer = styled.div`
	> a {
		display: flex;
		align-items: center;
		justify-content: center;

		height: 40px;
		width: 40px;
		@media (max-width: 600px) {
			height: 48px;
			width: 48px;
		}

		font-size: 16px;
		color: var(--color-grey-600);

		&:hover {
			color: var(--color-pink-600);
		}
	}
`;

type CollapsibleMenuItemProps = {
	category: string;
	links: FooterMenuItem[];
};

const CollapsibleMenuItem: FunctionComponent<CollapsibleMenuItemProps> = ({
	category,
	links,
}) => {
	const [isCollapsed, setIsCollapsed] = useState(true);

	const toggleIsCollapsed = useCallback(() => {
		setIsCollapsed((isCollapsed) => !isCollapsed);
	}, []);

	const windowWidth = useWindowWidth();

	return (
		<>
			<IconAndH3Container
				menuItemIsCollapsed={isCollapsed}
				onClick={toggleIsCollapsed}
			>
				<DropIconContainer>
					<FontAwesomeIcon
						icon={isCollapsed ? faChevronDown : faChevronUp}
						color="var(--color-grey-400)"
					/>
				</DropIconContainer>
				<Heading level={3}>{category}</Heading>
			</IconAndH3Container>
			{(!isCollapsed || windowWidth > 600) && (
				<UnorderedList>
					{links.map((link) => (
						<ListItem key={link.label}>
							<FooterLink>
								<Anchor href={link.to}>{link.label}</Anchor>
							</FooterLink>
						</ListItem>
					))}
				</UnorderedList>
			)}
		</>
	);
};

type FooterProps = {
	layoutHasSidebar: boolean;
};

const Footer: FunctionComponent<FooterProps> = ({ layoutHasSidebar }) => {
	const currentYear = new Date().getFullYear();

	return (
		<FooterContainer layoutHasSidebar={layoutHasSidebar}>
			<ColumnsContainer layoutHasSidebar={layoutHasSidebar}>
				{FOOTER_ITEMS.map((item) => (
					<Column
						layoutHasSidebar={layoutHasSidebar}
						key={item.category}
					>
						<CollapsibleMenuItem
							category={item.category}
							links={item.links}
						/>
					</Column>
				))}
			</ColumnsContainer>

			<FooterBottomColumnContainer>
				<FooterBottomColumn>
					<Paragraph>©{currentYear} Fonto Group</Paragraph>

					<FooterLinksContainer>
						<FooterLink>
							<Anchor href="https://www.fontoxml.com/eula/">
								EULA
							</Anchor>
						</FooterLink>

						<FooterBottomColumnLinkSeparator>
							|
						</FooterBottomColumnLinkSeparator>

						<FooterLink>
							<Anchor href="https://www.fontoxml.com/privacy-policy/">
								Privacy Policy
							</Anchor>
						</FooterLink>

						<FooterBottomColumnLinkSeparator>
							|
						</FooterBottomColumnLinkSeparator>

						<FooterLink>
							<Anchor href="https://www.fontoxml.com/cookie-policy/">
								Cookie Policy
							</Anchor>
						</FooterLink>
					</FooterLinksContainer>
				</FooterBottomColumn>

				<FooterBottomColumn>
					<IconContainer>
						<Anchor
							href="https://twitter.com/fontoxml"
							disableExternalLinkIndicator
						>
							<FontAwesomeIcon icon={faTwitter} />
						</Anchor>
					</IconContainer>

					<IconContainer>
						<Anchor
							href="https://www.linkedin.com/company/fonto/"
							disableExternalLinkIndicator
						>
							<FontAwesomeIcon icon={faLinkedin} />
						</Anchor>
					</IconContainer>

					<IconContainer>
						<Anchor
							href="https://vimeo.com/fontowebsite"
							disableExternalLinkIndicator
						>
							<FontAwesomeIcon icon={faVimeo} />
						</Anchor>
					</IconContainer>

					<IconContainer>
						<Anchor
							href="https://github.com/FontoXML/"
							disableExternalLinkIndicator
						>
							<FontAwesomeIcon icon={faGithub} />
						</Anchor>
					</IconContainer>
				</FooterBottomColumn>

				{/* <FooterBottomColumn>
					<FooterLink>
						<Anchor href="https://liones.us1.list-manage.com/unsubscribe?u=5a815a65a72f09469499bb8b9&id=811fd68901">
							Unsubscribe your e-mail address
						</Anchor>
					</FooterLink>
				</FooterBottomColumn> */}
			</FooterBottomColumnContainer>
		</FooterContainer>
	);
};

export default Footer;
