import { useCallback, useEffect, useState } from 'react';

const useWindowWidth = (): number => {
	const [windowWidth, setWindowWidth] = useState(
		typeof window !== 'undefined' ? window.innerWidth : 600
	);

	const handleResize = useCallback(() => {
		setWindowWidth(() => window.innerWidth);
	}, []);

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [handleResize]);

	return windowWidth;
};

export default useWindowWidth;
