// Modern reset stylesheet that targets latest versions of modern browsers.
import 'modern-normalize/modern-normalize.css';
// Google Fonts from the FontSource project.
import '@fontsource/fira-sans/latin-400.css';
import '@fontsource/fira-sans/latin-500.css';
import '@fontsource/fira-sans/latin-600.css';
import '@fontsource/fira-sans/latin-700.css';
import '@fontsource/fira-sans/latin-800.css';
import '@fontsource/source-code-pro/latin-400.css';
import '@fontsource/source-code-pro/latin-500.css';
import '@fontsource/ubuntu/latin-700.css';
// Manually load the FontAwesome styles to ensure these styles are properly
// baked into the pre-rendered HTML page for a prod build.
// This prevents a huge > icon (from the breadcrumbs) from flashing initially.
import '@fortawesome/fontawesome-svg-core/styles.css';

import { css, Global } from '@emotion/react';
import type { FunctionComponent } from 'react';

const globalStyles = css`
	:root {
		/*
		 * COLORS
		 * matches colors as per styleguide @ Feb. 17 2022:
		 * https://www.figma.com/proto/SojCMgnDzYGyRUTQLoU0VL/Style-guide
		 */
		--color-grey-50: #ffffff;
		--color-grey-100: #f1f3f5;
		--color-grey-200: #dfe1e4;
		--color-grey-300: #c4c6cd;
		--color-grey-400: #a7adb2;
		--color-grey-500: #808890;
		--color-grey-600: #5b6066;
		--color-grey-700: #373a3f;
		--color-grey-800: #212121;
		--color-grey-900: #000000;

		--color-green-50: #ecf3f2;
		--color-green-100: #cedfd9;
		--color-green-200: #b8d1cd;
		--color-green-300: #9fc1b6;
		--color-green-400: #6fa287;
		--color-green-500: #5b8f73;
		--color-green-600: #4e7961;
		--color-green-700: #3e6048;
		--color-green-800: #304b36;
		--color-green-900: #203225;

		--color-orange-50: #feeeec;
		--color-orange-100: #fee0dd;
		--color-orange-200: #fecebe;
		--color-orange-300: #fea486;
		--color-orange-400: #fe8158;
		--color-orange-500: #fe5f2a;
		--color-orange-600: #e93b01;
		--color-orange-700: #cb0101;
		--color-orange-800: #980101;
		--color-orange-900: #6f0101;

		--color-pink-50: #fde8ef;
		--color-pink-100: #fad6e5;
		--color-pink-200: #f9bed7;
		--color-pink-300: #f8a0c9;
		--color-pink-400: #f471ae;
		--color-pink-500: #f24a98;
		--color-pink-600: #ce0f69;
		--color-pink-700: #a20b52;
		--color-pink-800: #7b093e;
		--color-pink-900: #55062b;

		--color-purple-50: #f2f0f9;
		--color-purple-100: #e1dcf4;
		--color-purple-200: #cbc4ed;
		--color-purple-300: #a89de2;
		--color-purple-400: #9784dc;
		--color-purple-500: #755cd1;
		--color-purple-600: #5537c3;
		--color-purple-700: #38247f;
		--color-purple-800: #1e1545;
		--color-purple-900: #110c27;

		/* Derivatives. Please take care to update these if the color palette changes */
		--color-green-300-translucent: #9fc1b690;

		/* Legacy colors, please avoid creating new references to these variables */
		--legacy-color-green: #6ea185;
		--legacy-color-light-grey: #f4f4f4;
		--legacy-color-medium-grey: #d7d7d7;
		--legacy-color-orange: #ff8159;
		--legacy-color-pink: #d11a70;
		--legacy-color-purple-300: #5a3cc8;
		--legacy-color-red-400: #a72a01;
		--legacy-color-sand-grey: #f5f4f0;
		--legacy-color-silver: #c7c9d3;
		--legacy-color-translucent-green: rgba(110, 161, 133, 0.1);
		--legacy-color-translucent-orange: rgba(255, 129, 89, 0.1);

		/*
		 * SHADOWS
		 * use together with the CSS box-shadow property
		 */
		--elevation-100: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
			0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
		--elevation-200: 0 1px 5px 0 rgba(0, 0, 0, 0.2),
			0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
		--elevation-300: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
			0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
		--elevation-400: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
			0 3px 14px 2px rgba(0, 0, 0, 0.12),
			0 8px 10px 1px rgba(0, 0, 0, 0.14);
		--elevation-500: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
			0 5px 22px 4px rgba(0, 0, 0, 0.12),
			0 12px 17px 2px rgba(0, 0, 0, 0.14);
		--elevation-600: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
			0 9px 46px 8px rgba(0, 0, 0, 0.12),
			0 24px 38px 3px rgba(0, 0, 0, 0.14);

		/*
		 * LENGTHS
		 * use for margins/padding or anything that creates visual "space"
		 */
		--spacing-small: 8px;
		--spacing-medium-small: 16px;
		--spacing-medium: 24px;
		--spacing-medium-large: 32px;
		--spacing-large: 48px;
		--spacing-larger: 64px;
		--spacing-largest: 72px;

		/*
		 * FONTS
		 */
		--font-primary: 'Fira Sans', Trebuchet MS, sans-serif;
		--font-secondary: 'Ubuntu', Trebuchet MS, sans-serif;
		--font-code: 'Source Code Pro', monospace;

		--font-size-small: 0.875rem;
		--font-size-large: 1.125rem;

		/**
		 * FONT WEIGHTS
		 */
		--font-weight-normal: 400;
		--font-weight-subtle: 500;
		--font-weight-bold: 700;

		/**
		 * BORDER-RADIUSES
		 * Matches sizing in the issue description:
		 *   https://fontoxml.atlassian.net/browse/DEV-14088
		 */
		--border-radius-smaller: 2px;
		--border-radius-small: 4px;
		--border-radius-medium: 8px;
		--border-radius-large: 16px;
	}

	body {
		font-family: var(--font-primary);
		line-height: 1.81;
	}

	* {
		margin: 0;
	}
`;

export const GlobalStyles: FunctionComponent = () => {
	return <Global styles={globalStyles} />;
};

// Expporting so that typing can use it
export const SPACING_SMALL = 'var(--spacing-small)';
export const SPACING_MEDIUM_SMALL = 'var(--spacing-medium-small)';
export const SPACING_MEDIUM = 'var(--spacing-medium)';
export const SPACING_MEDIUM_LARGE = 'var(--spacing-medium-large)';
export const SPACING_LARGE = 'var(--spacing-large)';
export const SPACING_LARGER = 'var(--spacing-larger)';
export const SPACING_LARGEST = 'var(--spacing-largest)';

export type CssLength = string;

export type CssColor = string;
