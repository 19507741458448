import { evaluateXPathToBoolean, evaluateXPathToString } from 'fontoxpath';

export function getFadAddOnPackageName(node: Node): string | undefined {
	if (evaluateXPathToBoolean('.[not(@add-on="true")]', node)) {
		return undefined;
	}

	const source = evaluateXPathToString('.', node);
	return source.split('/').shift();
}
