/* eslint-disable simple-import-sort/imports */

// WARNING: All further imports require Prism (in the global scope).
import Prism from 'prismjs';

// Load the base theme.
import 'prismjs/themes/prism-okaidia.css';

// Load additional plugins.
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';

// Load additional languages. Note that these can have a dependency on each
// other and should be loaded in the appropriate order.
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-xquery';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-tsx';

export default Prism;
