import styled from '@emotion/styled/macro';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent, PropsWithChildren } from 'react';

import { SPACING_SMALL } from '../shared/global';
import { mixinSpaceChildren } from '../shared/mixins';

// A mapping of valid note "type" values and their associated colours.

const NOTE_TYPES = {
	tip: {
		backgroundColor: 'var(--legacy-color-translucent-green)',
		borderColor: 'var(--legacy-color-green)',
		iconColor: 'var(--legacy-color-green)',
	},
	warning: {
		backgroundColor: 'var(--legacy-color-translucent-orange)',
		borderColor: 'var(--legacy-color-orange)',
		iconColor: 'var(--legacy-color-orange)',
	},
	neutral: {
		backgroundColor: 'var(--legacy-color-light-grey)',
		borderColor: 'var(--color-grey-500)',
		iconColor: 'var(--color-grey-500)',
	},
};

const NoteStyledComponent = styled.div`
	${({
		backgroundColor,
		borderColor,
	}: {
		backgroundColor: string;
		borderColor: string;
	}) => `
		background-color: ${backgroundColor};
		border-color: ${borderColor};
	`};
	border-radius: var(--border-radius-medium);
	padding: var(--spacing-medium);
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: start;
	border-style: solid;
	border-width: 1px;
`;

const NoteContentStyledComponent = styled.div`
	flex: 1 1 auto;
	&:not(:first-of-type) {
		margin-left: var(--spacing-medium);
	}

	${mixinSpaceChildren(SPACING_SMALL)};
`;

type NotePropsType = PropsWithChildren & {
	type?: keyof typeof NOTE_TYPES;
	icon?: IconProp;
};

/**
 * Notes are highlighted bits of information, usually indicating an important ✨pro-tip, or ☠️ imminent danger.
 *
 * In XML, notes correlate with DITA `<note>` elements.
 */

export const Note: FunctionComponent<NotePropsType> = ({
	type,
	icon,
	children,
}) => {
	const colors = NOTE_TYPES[type || 'neutral'] || NOTE_TYPES.neutral;
	return (
		<NoteStyledComponent
			backgroundColor={colors.backgroundColor}
			borderColor={colors.borderColor}
		>
			{icon && (
				<FontAwesomeIcon
					icon={icon}
					fixedWidth
					color={colors.iconColor}
					style={{
						marginTop: '0.25em',
						marginRight: 'var(--spacing-medium)',
					}}
				/>
			)}
			<NoteContentStyledComponent>{children}</NoteContentStyledComponent>
		</NoteStyledComponent>
	);
};

Note.defaultProps = {
	type: 'neutral',
	icon: undefined,
};
