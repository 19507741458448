import styled from '@emotion/styled/macro';
import {
	faExclamationTriangle,
	faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons';
import {
	evaluateXPathToBoolean,
	evaluateXPathToNodes,
	evaluateXPathToNumber,
	evaluateXPathToString,
} from 'fontoxpath';
import type {
	FunctionComponent,
	PropsWithChildren,
	ReactNodeArray,
} from 'react';
import { createElement, Fragment } from 'react';
import { ReactRenderer } from 'xml-renderer';

import {
	Anchor,
	Bold,
	BrokenAnchor,
	CodeBlock,
	CodePhrase,
	CollapsibleBox,
	CollapsibleBoxGroup,
	getLanguageByPrismClass,
	getSupportedLanguageForFadValue,
	Heading,
	HorizontalRuler,
	Italic,
	KeyboardButton,
	ListItem,
	Note,
	OrderedList,
	Paragraph,
	Section,
	StrikeThrough,
	Table,
	TableCell,
	TableHeader,
	TableRow,
	Underline,
	UnorderedList,
} from '../../content-components';
import { SPACING_MEDIUM_SMALL } from '../../content-components/shared/global';
import { mixinFrame } from '../../content-components/shared/mixins';
import { SCHEMA_URI_FAD_1 } from '../../shared/constants';
import { getFadAddOnPackageName } from '../querying/fad-add-on-package-name';
import { getFadBaseTypeLabel } from '../querying/fad-base-types';
import { getFadImportStatement } from '../querying/fad-import-statement';
import {
	getClosestTableOfContentsItem,
	getFadTableOfContentsItems,
	QUERY_FAD_SECTIONS,
} from '../querying/fad-table-of-contents';
import type { RuleContext } from '../types';
import fadRestrictionElementRules from './fad-restrict';
import sharedElementRules from './shared';

const rules = new ReactRenderer<RuleContext>(sharedElementRules);

const TypeRestrictionBadge: FunctionComponent<PropsWithChildren> = ({
	children,
}) => (
	<Paragraph>
		<Bold>Type: </Bold>
		<span>{children}</span>
	</Paragraph>
);

rules.add('self::code-block', ({ traverse, node }) => {
	const xmlLang = evaluateXPathToString('./@language', node);
	return (
		<CodeBlock language={getSupportedLanguageForFadValue(xmlLang)}>
			{traverse()}
		</CodeBlock>
	);
});

rules.add('self::value[parent::type]', ({ traverse, node }) => {
	const xmlLang = evaluateXPathToString('./@serialization', node);
	return (
		<CodeBlock language={getSupportedLanguageForFadValue(xmlLang)}>
			{traverse()}
		</CodeBlock>
	);
});

rules.add('self::code-phrase', ({ traverse }) => (
	<CodePhrase>{traverse()}</CodePhrase>
));

rules.add('self::paragraph', ({ traverse }) => (
	<Paragraph>{traverse()}</Paragraph>
));

// #############
// :
// :   FAD TABLES
// :
// <<<<<<<

// here position works
rules.add('self::table', ({ traverse }) => <Table>{traverse('./row')}</Table>);

rules.add('self::row', ({ traverse }) => <TableRow>{traverse()}</TableRow>);

rules.add(
	'self::cell[parent::row[not(preceding-sibling::*)]]',
	({ traverse }) => (
		<TableHeader>
			<Paragraph>{traverse()}</Paragraph>
		</TableHeader>
	)
);

rules.add('self::cell', ({ traverse }) => (
	<TableCell>
		<Paragraph>{traverse()}</Paragraph>
	</TableCell>
));

rules.add('self::operation', ({ traverse }) => <>{traverse()}</>);

rules.add('self::operation-step', ({ traverse }) => <>{traverse()}</>);

rules.add(
	'self::operation[not(parent::*)]',
	({ traverse, articleHeroWidget }) => {
		return (
			<Section>
				<header>
					{traverse('./name')}
					<TypeRestrictionBadge>
						{getFadBaseTypeLabel('operation')}
					</TypeRestrictionBadge>
				</header>
				{articleHeroWidget}
				{traverse(QUERY_FAD_SECTIONS)}
			</Section>
		);
	}
);

rules.add(
	'self::operation-step[not(parent::*)]',
	({ traverse, node, articleHeroWidget }) => {
		return (
			<Section>
				<header>
					{traverse('./name')}
					<TypeRestrictionBadge>
						{getFadBaseTypeLabel(
							evaluateXPathToString('@type', node)
						)}
					</TypeRestrictionBadge>
				</header>
				{articleHeroWidget}
				{traverse(QUERY_FAD_SECTIONS)}
			</Section>
		);
	}
);

rules.add(
	`
			self::arguments[
				parent::operation-step and
				count(./type) = 1 and
				count(./type/members/*) > 0
			]
		`,
	({ traverse, node }) => {
		const sectionInfo = getClosestTableOfContentsItem(node);
		return (
			<Section id={sectionInfo.id}>
				<Heading level={2} deepLinkableId={sectionInfo.id}>
					{sectionInfo.label}
				</Heading>
				<OrderedList>
					{traverse(sectionInfo.traversal)
						.filter((x) => !!x)
						.map((x, i) => (
							<ListItem key={i}>{x}</ListItem>
						))}
				</OrderedList>
			</Section>
		);
	}
);

rules.add(
	`
			self::return[
				parent::operation-step and
				count(./type) = 1 and
				count(./type/members/*) > 0
			]
		`,
	({ traverse, node }) => {
		const sectionInfo = getClosestTableOfContentsItem(node);
		return (
			<Section id={sectionInfo.id}>
				<Heading level={2} deepLinkableId={sectionInfo.id}>
					{sectionInfo.label}
				</Heading>
				<OrderedList>
					{traverse(sectionInfo.traversal)
						.filter((x) => !!x)
						.map((x, i) => (
							<ListItem key={i}>{x}</ListItem>
						))}
				</OrderedList>
			</Section>
		);
	}
);

rules.add(
	`self::returns[
		parent::operation
	]`,
	({ traverse, node }) => {
		const sectionInfo = getClosestTableOfContentsItem(node);
		return (
			<Section id={sectionInfo.id}>
				<Heading level={2} deepLinkableId={sectionInfo.id}>
					{sectionInfo.label}
				</Heading>
				<OrderedList>
					{traverse('./type').map((x, i) => (
						<ListItem key={i}>{x}</ListItem>
					))}
				</OrderedList>
			</Section>
		);
	}
);

// The inner workings of an operation, albeit documented, are not published:
rules.add('self::alternatives', () => null);

// The inner workings of an operation, albeit documented, are not published:
rules.add('self::steps', () => null);

// The inner workings of an operation, albeit documented, are not published:
rules.add('self::state-steps', () => null);

// User-facing informationis shown in a table:
rules.add('self::user-facing', ({ traverse }) => (
	<Table>{traverse('./element()')}</Table>
));

rules.add('self::description[parent::user-facing]', ({ traverse }) => (
	<TableRow>
		<TableHeader>
			<Paragraph>Tooltip</Paragraph>
		</TableHeader>
		<TableCell>
			<Paragraph>{traverse()}</Paragraph>
		</TableCell>
	</TableRow>
));

rules.add('self::label[parent::user-facing]', ({ traverse }) => (
	<TableRow>
		<TableHeader>
			<Paragraph>Label</Paragraph>
		</TableHeader>
		<TableCell>
			<Paragraph>{traverse()}</Paragraph>
		</TableCell>
	</TableRow>
));

rules.add('self::icon[parent::user-facing]', ({ traverse }) => (
	<TableRow>
		<TableHeader>
			<Paragraph>Icon</Paragraph>
		</TableHeader>
		<TableCell>
			<Paragraph>
				<CodePhrase>{traverse()}</CodePhrase>
			</Paragraph>
		</TableCell>
	</TableRow>
));

function joinReactElementsWithComponent(joinElement: any) {
	return (all: any[], reactElement: any, i: number) => [
		...all,
		<Fragment key={i}>
			{i === 0 ? (
				reactElement
			) : (
				<>
					{joinElement}
					{reactElement}
				</>
			)}
		</Fragment>,
	];
}

rules.add('self::key-bindings[parent::user-facing]', ({ node }) => (
	<TableRow>
		<TableHeader>
			<Paragraph>Key binding</Paragraph>
		</TableHeader>
		<TableCell>
			<Paragraph>
				{evaluateXPathToNodes<Node>('./key-binding', node)
					.map((n: unknown) => rules.render(createElement, n as Node))
					.reduce(
						joinReactElementsWithComponent(<span> or </span>),
						[]
					)}
			</Paragraph>
		</TableCell>
	</TableRow>
));

rules.add('self::key-binding', ({ node }) => {
	const keys = evaluateXPathToString('./@simultaneous', node).split(' ');
	return (
		<>
			{keys
				.map((key) => <KeyboardButton key={key}>{key}</KeyboardButton>)
				.reduce(joinReactElementsWithComponent(<span> + </span>), [])}
		</>
	);
});

/**
 * Top level elements
 * For (JS) types, operations, operation steps
 *
 * Select any <type> without a parent, or any <type> that is the only public overload of a top-level <type>
 */
rules.add(
	`
		self::type[
			not(parent::*) or
			count(parent::overloads[
				parent::type[not(parent::*)]
			]/type[@sdk]) = 1
		]
	`,
	({ traverse, node, articleHeroWidget }) => {
		const hasOverloads = evaluateXPathToNumber(
			'count(./overloads/type[@sdk])',
			node
		);

		if (hasOverloads === 1) {
			// If this type has only one public overload, transparently display _that_ overload
			//   and nothing else.
			return <>{traverse('./overloads/type[@sdk]')}</>;
		}

		if (hasOverloads > 0) {
			// If this type has several public overloads, tell the reader how many and then list
			//   them one by one.
			//
			// In which case this same ruleset is used again for each overload, see further down;
			return (
				<Section>
					<header>
						{traverse('./name')}
						{traverse('./restrict')}
					</header>
					<Paragraph>This API has {hasOverloads} overloads</Paragraph>
					<Heading level={2}>Overloads</Heading>
					<OrderedList>
						{traverse('./overloads/type[@sdk]').map((x, i) => (
							<ListItem key={i}>{x}</ListItem>
						))}
					</OrderedList>
				</Section>
			);
		}

		return (
			<Section>
				<header>
					{traverse('./name, ./parent::overloads/parent::type/name')}
					{traverse('./restrict')}
				</header>
				{articleHeroWidget}

				{traverse(QUERY_FAD_SECTIONS)}
			</Section>
		);
	}
);

rules.add('self::narrative', ({ traverse, articleHeroWidget }) => {
	const queryForFirstVisibleElement = `child::*[not(name() = ('source', 'name'))][1]`;
	return (
		<Section>
			{traverse(queryForFirstVisibleElement)}
			{articleHeroWidget}
			{traverse(`${queryForFirstVisibleElement}/following-sibling::*`)}
		</Section>
	);
});

/**
 * API name
 */
// Display the name element with 1 ancestor
rules.add('self::name[count(ancestor::*) = 1]', ({ traverse }) => (
	<Heading level={1} deepLinkableId="">
		{traverse()}
	</Heading>
));

// Hide the name element with 1 parent and ancestor: narrative
rules.add('self::name[parent::narrative][count(ancestor::*) = 1]', () => null);

/**
 * NARRATIVE API DOCUMENTATION
 * All the markdown-ish content used to describe stuff. Paragraphs, lists, tables, the works.
 */

rules.add('self::heading', ({ node, traverse }) => {
	const sectionInfo = getClosestTableOfContentsItem(node);
	const level = parseInt(evaluateXPathToString('@level', node), 10);
	return (
		<Heading
			level={level}
			id={sectionInfo.id}
			deepLinkableId={sectionInfo.id}
		>
			{traverse()}
		</Heading>
	);
});

rules.add('self::paragraph[ancestor::summary]', ({ traverse }) => (
	<Paragraph emphasis>{traverse()}</Paragraph>
));
rules.add(
	'self::paragraph[ancestor::summary[not(parent::element())]]',
	({ traverse }) => <Paragraph>{traverse()}</Paragraph>
);

rules.add('self::list', ({ traverse }) => (
	<UnorderedList style={{ margin: 'var(--spacing-medium) 0' }}>
		{traverse()}
	</UnorderedList>
));

rules.add('self::list-item', ({ traverse }) => (
	<ListItem>{traverse()}</ListItem>
));

rules.add('self::horizontal-ruler', () => <HorizontalRuler />);

rules.add('self::link', ({ node, traverse }) => {
	const href = evaluateXPathToString('./@reference', node);
	return <Anchor href={href}>{traverse()}</Anchor>;
});

rules.add('self::link[@unresolved]', ({ node, traverse }) => {
	const href = evaluateXPathToString('./@reference', node);
	return <BrokenAnchor href={href}>{traverse()}</BrokenAnchor>;
});

rules.add('self::italic', ({ traverse }) => <Italic>{traverse()}</Italic>);
rules.add('self::bold', ({ traverse }) => <Bold>{traverse()}</Bold>);
rules.add('self::underline', ({ traverse }) => (
	<Underline>{traverse()}</Underline>
));
rules.add('self::strike-trough', ({ traverse }) => (
	<StrikeThrough>{traverse()}</StrikeThrough>
));

/**
 * SECTIONS
 * Many pages have a specific set of sections, eg. "arguments", "members", "returns", "operation steps", etc.
 */

rules.add('self::deprecated', ({ traverse }) => (
	<Note type="warning" icon={faExclamationTriangle}>
		<Paragraph>
			<Bold>Deprecated!</Bold>
		</Paragraph>
		{traverse()}
	</Note>
));

rules.add('self::restrict', ({ node }) => (
	<TypeRestrictionBadge>
		{fadRestrictionElementRules.render(createElement, node)}
	</TypeRestrictionBadge>
));

rules.add('self::type-parameters', ({ traverse, node }) => {
	const sectionInfo = getClosestTableOfContentsItem(node);
	return (
		<Section id={sectionInfo.id}>
			<Heading level={2} deepLinkableId={sectionInfo.id}>
				{sectionInfo.label}
			</Heading>
			<OrderedList>
				{traverse('./element()')
					.filter(Boolean)
					.map((x, i) => (
						<ListItem key={i}>{x}</ListItem>
					))}
			</OrderedList>
		</Section>
	);
});

// Select any <type-parameters> that are nested deep enough (eg. the returns of a class method)
// Except the ones that are the only public top-level type overload, or are constructors of a top-level class type.
rules.add(
	`
		self::type-parameters and
		count(ancestor::type) >= 2 and
		not(parent::type/parent::overloads[count(./type[@sdk]) <= 1]/parent::type[not(parent::*)]) and
		not(parent::type/parent::overloads/parent::type/parent::members/parent::type[./restrict/type/@base = "class" and not(parent::*)])
	`,
	({ traverse, node }) => {
		const sectionInfo = getClosestTableOfContentsItem(node);
		return (
			<CollapsibleBox label={sectionInfo.label}>
				{traverse()}
			</CollapsibleBox>
		);
	}
);

rules.add('self::summary', ({ traverse }) => <>{traverse()}</>);

rules.add('self::description', ({ traverse }) => <>{traverse()}</>);

rules.add('self::source', ({ node }) => {
	const importStatement = getFadImportStatement(node);
	const name = evaluateXPathToString('parent::*/name', node);
	const addOnPackageName = getFadAddOnPackageName(node);

	return (
		<>
			{addOnPackageName && (
				<Note type="neutral" icon={faInfoCircle}>
					<Paragraph>
						{`This API is part of the "${addOnPackageName}" add-on.`}
					</Paragraph>
				</Note>
			)}

			{importStatement && (
				<CodeBlock
					language={getLanguageByPrismClass('javascript')}
					title={`How to get ${name}?`}
				>
					{importStatement}
				</CodeBlock>
			)}
		</>
	);
});

rules.add('self::return or self::returns', ({ traverse, node }) => {
	const sectionInfo = getClosestTableOfContentsItem(node);
	return (
		<Section id={sectionInfo.id}>
			<Heading level={2} deepLinkableId={sectionInfo.id}>
				{sectionInfo.label}
			</Heading>
			{traverse()}
		</Section>
	);
});

// Select any <return> or <returns> that are nested deep enough (eg. the returns of a class method)
// Except the ones that are the only public top-level type overload, or are constructors of a top-level class type.
rules.add(
	`
		(self::return or self::returns) and
		count(ancestor::type) >= 2 and
		not(parent::type/parent::overloads[count(./type[@sdk]) <= 1]/parent::type[not(parent::*)]) and
		not(parent::type/parent::overloads/parent::type/parent::members/parent::type[./restrict/type/@base = "class" and not(parent::*)])
	`,
	({ traverse, node }) => {
		const sectionInfo = getClosestTableOfContentsItem(node);
		return (
			<CollapsibleBox label={sectionInfo.label}>
				{traverse()}
			</CollapsibleBox>
		);
	}
);

rules.add('self::default', ({ traverse, node }) => {
	const sectionInfo = getClosestTableOfContentsItem(node);
	return (
		<Section id={sectionInfo.id}>
			<Heading level={2} deepLinkableId={sectionInfo.id}>
				{sectionInfo.label}
			</Heading>
			{traverse()}
		</Section>
	);
});

rules.add(
	'self::default[count(ancestor::type|ancestor::operation) >= 2]',
	({ traverse, node }) => {
		const sectionInfo = getClosestTableOfContentsItem(node);
		return (
			<CollapsibleBox
				label={sectionInfo.label}
				labelAside={<>{traverse('./type/restrict')}</>}
			>
				{traverse('./type/value')}
			</CollapsibleBox>
		);
	}
);

rules.add('self::arguments', ({ traverse, node }) => {
	const sectionInfo = getClosestTableOfContentsItem(node);
	return (
		<Section id={sectionInfo.id}>
			<Heading level={2} deepLinkableId={sectionInfo.id}>
				{sectionInfo.label}
			</Heading>
			<OrderedList>
				{traverse('./element()')
					.filter(Boolean)
					.map((x, i) => (
						<ListItem key={i}>{x}</ListItem>
					))}
			</OrderedList>
		</Section>
	);
});

// For FAD v2, the "constructor" member is rendered as its separate section
//
// See also fad-table-of-contents.ts
rules.add(
	'self::type[./restrict/type/@base = "constructor" and not(parent::overloads) and not(./return or ./type-parameters/type) and parent::*]',
	({ traverse, node }) => {
		if (evaluateXPathToBoolean('not(./arguments/type)', node)) {
			return (
				<Paragraph>
					This constructor does not have any arguments.
				</Paragraph>
			);
		}
		return (
			<OrderedList>
				{traverse('./arguments/element()')
					.filter(Boolean)
					.map((x, i) => (
						<ListItem key={i}>{x}</ListItem>
					))}
			</OrderedList>
		);
	}
);

// Select any <arguments> that are nested far enough (eg. method arguments),
// Except the ones that are the only public top-level type overload, or are constructors of a top-level class type.
rules.add(
	`self::arguments[
		count(ancestor::type) >= 2 and
		not(parent::type/parent::overloads[count(./type[@sdk]) <= 1]/parent::type[not(parent::*)]) and
		not(parent::type/parent::overloads/parent::type/parent::members/parent::type[./restrict/type/@base = "class" and not(parent::*)])
	]`,
	({ traverse, node }) => {
		const sectionInfo = getClosestTableOfContentsItem(node);
		return (
			<CollapsibleBox label={sectionInfo.label}>
				{traverse()}
			</CollapsibleBox>
		);
	}
);

rules.add('self::members', () => null);

rules.add('self::members[child::*[@sdk]]', ({ traverse, node }) => {
	const sectionInfos = getFadTableOfContentsItems(node);
	return (
		<>
			{sectionInfos.map((sectionInfo, i) => (
				<Section key={i} id={sectionInfo.id}>
					<Heading level={2} deepLinkableId={sectionInfo.id}>
						{sectionInfo.label}
					</Heading>
					{traverse(sectionInfo.traversal)}
				</Section>
			))}
		</>
	);
});

rules.add(
	'self::members[child::*[@sdk] and count(ancestor::type) >= 2]',
	({ traverse, node }) => {
		const sectionInfo = getClosestTableOfContentsItem(node);
		return (
			<CollapsibleBox label={sectionInfo.label}>
				{traverse()}
			</CollapsibleBox>
		);
	}
);

rules.add('self::inner-members', () => null);

rules.add('self::static-members', () => null);

rules.add('self::static-members[child::*[@sdk]]', ({ traverse, node }) => {
	const sectionInfo = getClosestTableOfContentsItem(node);
	return (
		<Section id={sectionInfo.id}>
			<Heading level={2} deepLinkableId={sectionInfo.id}>
				{sectionInfo.label}
			</Heading>
			{traverse('./*[@sdk]')}
		</Section>
	);
});

rules.add(
	'self::static-members[child::*[@sdk] and count(ancestor::type) >= 2]',
	({ traverse, node }) => {
		const sectionInfo = getClosestTableOfContentsItem(node);
		return (
			<CollapsibleBox label={sectionInfo.label}>
				{traverse()}
			</CollapsibleBox>
		);
	}
);

rules.add('self::related-links', ({ traverse, node }) => {
	const sectionInfo = getClosestTableOfContentsItem(node);
	return (
		<Section id={sectionInfo.id}>
			<Heading level={2} deepLinkableId={sectionInfo.id}>
				{sectionInfo.label}
			</Heading>
			<UnorderedList>{traverse()}</UnorderedList>
		</Section>
	);
});

rules.add(
	'self::related-links[count(ancestor::type) >= 2]',
	({ traverse, node }) => {
		const sectionInfo = getClosestTableOfContentsItem(node);
		return (
			<CollapsibleBox label={sectionInfo.label}>
				<UnorderedList>{traverse()}</UnorderedList>
			</CollapsibleBox>
		);
	}
);

rules.add('self::related-link', ({ traverse }) => (
	<ListItem>{traverse()}</ListItem>
));

// The <example> element has a different content model between FAD v1 and v2. In v1, its content is flat text and
//   assumed to be code. In FAD v2, based on TSDoc, its content is markdown-ish, narrative content.
rules.add('self::example', ({ traverse, node }) =>
	evaluateXPathToBoolean(
		`
			ancestor-or-self::*[@xsi:noNamespaceSchemaLocation]/@xsi:noNamespaceSchemaLocation = "${SCHEMA_URI_FAD_1}"
		`,
		node
	) ? (
		<CodeBlock>{traverse()}</CodeBlock>
	) : (
		<>{traverse()}</>
	)
);

/**
 * TYPE NESTING
 * The following rules deal with _nested_ occurrences of <type>, eg. as an argument or return value.
 */
const TypeBlockWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: var(--spacing-medium);

	${mixinFrame(SPACING_MEDIUM_SMALL)}

	> :not(section) + section {
		margin-top: var(--spacing-small);
	}

	> :not(section) + :not(section) {
		margin-top: var(--spacing-medium-small) !important;
	}
`;
const TypeBlockHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const TypeBlockHeaderRight = styled.div`
	display: flex;
	flex-direction: row;
`;
const TypeBlockOptionality = styled.div`
	background-color: var(--color-purple-50);
	padding: 0 var(--spacing-small);
	margin-right: var(--spacing-medium-small);
`;
const TypeBlock: FunctionComponent<{
	name?: ReactNodeArray;
	restrict?: ReactNodeArray;
	includeWrapper?: boolean;
	includeHeading?: boolean;
	optional: boolean | undefined;
	children: ReactNodeArray;
	id: string;
	readOnly: boolean;
	writeOnly: boolean;
	sequence: boolean | undefined;
}> = ({
	name,
	restrict,
	includeWrapper = true,
	includeHeading = true,
	optional,
	children,
	id,
	readOnly,
	writeOnly,
	sequence,
}) => {
	const isNameEmpty =
		!name ||
		(Array.isArray(name) && name.length === 0) ||
		(typeof name === 'string' && (name as string).trim() === '');

	const header = includeHeading && (
		<TypeBlockHeader id={id}>
			{!isNameEmpty && <div>{name}</div>}
			<TypeBlockHeaderRight>
				{readOnly && (
					<TypeBlockOptionality>
						<Paragraph>Read only</Paragraph>
					</TypeBlockOptionality>
				)}
				{writeOnly && (
					<TypeBlockOptionality>
						<Paragraph>Write only</Paragraph>
					</TypeBlockOptionality>
				)}
				{optional === undefined ? null : optional === true ? (
					<TypeBlockOptionality>
						<Paragraph>Optional</Paragraph>
					</TypeBlockOptionality>
				) : (
					<TypeBlockOptionality>
						<Paragraph>Required</Paragraph>
					</TypeBlockOptionality>
				)}
				{sequence && (
					<TypeBlockOptionality>
						<Paragraph>Sequence</Paragraph>
					</TypeBlockOptionality>
				)}
				<div>{restrict}</div>
			</TypeBlockHeaderRight>
		</TypeBlockHeader>
	);

	if (includeWrapper) {
		return (
			<TypeBlockWrapper>
				{header}
				{children}
			</TypeBlockWrapper>
		);
	}

	return (
		<>
			{header}
			{children}
		</>
	);
};

// FAD re-uses the documentation for written for things like FDS~Icon by referencing them as the type of a prop,
// argument or whatever. This only works if the reference target is an _inner member_ of some object, hence the "~".
// When that happens, the type and description (etc.) of the referenced <type> are used. Some properties, such as
// the name and optionality, continue to come from the <type> that references the inner member.
const GET_VISIBLE_TYPE = `(
		let $innerMemberRef := ./restrict/type[@included-from-context]
		return if ($innerMemberRef) then $innerMemberRef
		else .
	)`;

rules.add(`self::type[parent::*]`, ({ traverse, node }) => {
	// if (evaluateXPathToBoolean('child::overloads', node)) {
	// 	return <>{traverse('./overloads/type[@sdk]')}</>;
	// }
	const shouldMentionOptionality = evaluateXPathToBoolean(
		'parent::arguments or parent::members[parent::*/restrict/type[@base = "type-literal"]]',
		node
	);
	const readOnly = evaluateXPathToBoolean(
		'./restrict/@readonly = "true"',
		node
	);
	const writeOnly = evaluateXPathToBoolean(
		'./restrict/@writeonly = "true"',
		node
	);
	const queryForStuffInCollapsibleBoxes = `./${GET_VISIBLE_TYPE}/*[
			not(
				self::name or
				self::restrict or
				self::default or
				self::summary or
				self::description)
		]`;
	const isOptional = shouldMentionOptionality
		? evaluateXPathToBoolean('./restrict/@optional = "true"', node)
		: undefined;
	const isSequence =
		evaluateXPathToBoolean(
			'./restrict/restrict/@type = "xpath:sequence"',
			node
		) || undefined;
	const includeWrapperBorder = evaluateXPathToBoolean(
		`
			count(ancestor::type[parent::overloads]) <= 1 or
			parent::overloads or
			parent::arguments/parent::type/restrict/type[@base = "constructor"]
		`,
		node
	);
	const isOverloadOfRoot = evaluateXPathToBoolean(
		'parent::overloads/parent::type[not(parent::*)]',
		node
	);

	return (
		<TypeBlock
			name={traverse('(./name, parent::overloads/parent::type/name)')}
			restrict={traverse(`./${GET_VISIBLE_TYPE}/restrict`)}
			optional={isOptional}
			includeWrapper={includeWrapperBorder}
			includeHeading={!isOverloadOfRoot}
			id={evaluateXPathToString('@id', node)}
			readOnly={readOnly}
			writeOnly={writeOnly}
			sequence={isSequence}
		>
			{traverse(`./${GET_VISIBLE_TYPE}/(summary|description)`)}
			{evaluateXPathToBoolean(queryForStuffInCollapsibleBoxes, node) ? (
				<CollapsibleBoxGroup>
					{traverse(queryForStuffInCollapsibleBoxes)}
				</CollapsibleBoxGroup>
			) : null}
			{traverse('./default')}
		</TypeBlock>
	);
});

rules.add(
	`
			self::name[
				count(
					ancestor::*[
						name() = ('type', 'operation', 'operation-step', 'configuration')
					]
				) > 1
			]
		`,
	({ traverse, node }) => {
		const deepLinkableId = evaluateXPathToString(`parent::*/@id`, node);
		return (
			<Heading level={4} deepLinkableId={deepLinkableId}>
				{traverse()}
			</Heading>
		);
	}
);

rules.add(
	`
			self::name[
				count(
					ancestor::*[name() = ('type', 'operation', 'operation-step', 'configuration')]
				) > 2
			]
		`,
	({ traverse }) => (
		<Paragraph>
			<Bold>{traverse()}</Bold>
		</Paragraph>
	)
);

export default rules;
