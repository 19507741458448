import { evaluateXPathToFirstNode, evaluateXPathToString } from 'fontoxpath';
import { useMemo } from 'react';
import { parseXmlDocument } from 'slimdom';
import type { ReactRenderer } from 'xml-renderer';

import { RULES_DITA, RULES_FAD } from '../content-rules';
import type { RuleContext } from '../content-rules/types';
import { NS_URI_FAD } from '../shared/constants';
import { ENDPOINT_LOADING, useEndpointCache } from './endpoint-caching';

export const DOCUMENT_NOT_FOUND = '__DOCUMENT_NOT_FOUND__';

// Helper function to convert a path as returned by the router to a location on disk.
export function getUrlForPath(path: string): string {
	return `/static/${
		// The urls `/`, `/latest` and `/latest/` all fall back to use "xml.xml"
		/^\/(latest\/?){0,1}$/.exec(path) ? 'xml' : `xml${path}`
	}.xml`;
}

const FETCH_DOCUMENT = (
	url: string
): Promise<string | typeof DOCUMENT_NOT_FOUND> => {
	return fetch(url)
		.then((response) => {
			if (!response.ok) {
				return DOCUMENT_NOT_FOUND;
			}
			const text = response.text();
			return text;
		})
		.catch((error) => {
			console.error(error);
			return DOCUMENT_NOT_FOUND;
		});
};

/**
 * Get the string of an XML document
 */
export function useDocumentString(
	url: string
): string | typeof DOCUMENT_NOT_FOUND | typeof ENDPOINT_LOADING {
	return useEndpointCache(FETCH_DOCUMENT, url);
}

/**
 * Get the parsed slimdom document out of an XML document. Additional argument lets you target
 * any specific element immediately.
 */
export function useDocumentDom(
	url: string,
	firstNodeSelector?: string
): Node | typeof DOCUMENT_NOT_FOUND | typeof ENDPOINT_LOADING {
	const documentString = useDocumentString(url);
	const document = useMemo(() => {
		if (
			documentString === ENDPOINT_LOADING ||
			documentString === DOCUMENT_NOT_FOUND
		) {
			return documentString;
		}
		return parseXmlDocument(documentString) as unknown as Document;
	}, [documentString]);
	return useMemo(() => {
		if (document === ENDPOINT_LOADING || document === DOCUMENT_NOT_FOUND) {
			return document;
		}

		const el = firstNodeSelector
			? evaluateXPathToFirstNode<Node>(firstNodeSelector, document)
			: document;
		if (!el) {
			throw new Error(
				`Expected a result for query "${firstNodeSelector}"`
			);
		}
		return el;
	}, [document, firstNodeSelector]);
}

/**
 * Get the set of `xml-renderer` rules that best suits the given slimdom node.
 */
export function useRenderingRules(
	element:
		| Document
		| Node
		| typeof DOCUMENT_NOT_FOUND
		| typeof ENDPOINT_LOADING
):
	| ReactRenderer<RuleContext>
	| typeof DOCUMENT_NOT_FOUND
	| typeof ENDPOINT_LOADING {
	const renderedRules = useMemo(() => {
		if (element === ENDPOINT_LOADING || element === DOCUMENT_NOT_FOUND) {
			return element;
		}
		return evaluateXPathToString('namespace-uri(/*)', element, null) ===
			NS_URI_FAD
			? RULES_FAD
			: RULES_DITA;
	}, [element]);
	return renderedRules;
}
