// Global styles that define the CSS vars and style the html/body/gatsby-wrapper
import './global.css';

import type { FunctionComponent } from 'react';

import { GlobalStyles } from '../content-components';

export const GlobalAppStyles: FunctionComponent = () => {
	return <GlobalStyles />;
};
