import styled from '@emotion/styled/macro';
import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import type { FunctionComponent } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useSafeRoutingParameters } from '../app-hooks/route-parameters';
import { useUserFeedbackVote } from '../app-hooks/user-tracking';
import urlToQuestionIllustration from '../app-images/user-feedback-question.svg';
import urlToReplyIllustration from '../app-images/user-feedback-thanks.svg';
import { Anchor, Button, Heading, Paragraph } from '../content-components';

const URL_SUPPORT_DESK = 'http://support.fontoxml.com';

const HorizontalButtons = styled.div`
	display: flex;
	flex-direction: row;
	> * + * {
		margin-left: var(--spacing-small);
	}
`;

const HelpfulBlockRow = styled.aside`
	display: flex;
	flex-direction: row;
	> * + * {
		margin-left: var(--spacing-small);
	}
`;
const HelpfulBlock = styled.aside`
	margin-top: calc(2 * var(--spacing-largest));
	user-select: none;

	border: 1px solid var(--legacy-color-medium-grey);
	border-radius: var(--border-radius-medium);
	padding: var(--spacing-medium-large);
`;
const HelpfulBlockIllustration = styled.div`
	flex: 0 0 auto;

	// Multiplying by 2.75 aligns it nicely with the HelpfulBlock border...
	width: calc(4 * var(--spacing-largest));
	// ... until the image must be rescaled fluidly
	max-width: 33%;

	> img {
		width: 100%;
		transform: translate(0, calc(var(--spacing-large) * -1.4));
		margin-bottom: calc(var(--spacing-large) * -1.4);
	}

	@media (max-width: 800px) {
		display: none;
	}
`;
const HelpfulBlockContent = styled.div`
	flex: auto;
	> * + * {
		margin-top: var(--spacing-medium);
	}
`;

const UpperBlockWrapper = styled.div`
	display: block;
	@media (max-width: 1100px) {
		display: none;
	}
`;
const LowerBlockWrapper = styled.div`
	margin-top: var(--spacing-medium);
	display: none;
	@media (max-width: 1100px) {
		display: block;
	}
`;

/**
 * Shows the thumbs up/down button to vote a page as helpful or not.
 *
 * Votes are registered to Google Analytics. If a user wishes not to to be tracked (specifically did
 * not consent to statistics tracking), this component is hidden.
 */
const WasThisPageHelpful: FunctionComponent = () => {
	const { path } = useSafeRoutingParameters();
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { vote, hasVoted } = useUserFeedbackVote(path);
	const [consent, setConsent] = useState(
		!!(window as any)?.Cookiebot?.consent?.statistics
	);
	const buttons = useMemo(
		() => (
			<HorizontalButtons>
				<Button
					type="passionate"
					icon={faThumbsUp}
					onClick={() => {
						vote('thumbsUp');
					}}
				>
					<Paragraph>Great love it</Paragraph>
				</Button>
				<Button
					type="neutral"
					icon={faThumbsDown}
					onClick={() => {
						vote('thumbsDown');
					}}
				>
					<Paragraph>I miss some info</Paragraph>
				</Button>
			</HorizontalButtons>
		),
		[vote]
	);
	useEffect(() => {
		function ss() {
			setConsent(!!(window as any)?.Cookiebot?.consent?.statistics);
		}
		window.addEventListener('CookiebotOnConsentReady', ss);
		return () => {
			window.removeEventListener('CookiebotOnConsentReady', ss);
		};
	}, []);

	if (!consent) {
		return null;
	}

	if (!hasVoted) {
		return (
			<HelpfulBlock>
				<HelpfulBlockRow>
					<HelpfulBlockContent>
						<Heading level={4}>Was this page helpful?</Heading>
						<Paragraph>
							We are aiming for a helpful and well documented
							Fonto Documentation. Let us know if you are
							satisfied with this page.{' '}
						</Paragraph>
						<UpperBlockWrapper>{buttons}</UpperBlockWrapper>
					</HelpfulBlockContent>
					<HelpfulBlockIllustration>
						<img
							src={urlToQuestionIllustration}
							alt="An abstract illustration for the question posed"
						/>
					</HelpfulBlockIllustration>
				</HelpfulBlockRow>
				<LowerBlockWrapper>{buttons}</LowerBlockWrapper>
			</HelpfulBlock>
		);
	}
	// When a vote was already cast
	return (
		<HelpfulBlock>
			<HelpfulBlockRow>
				<HelpfulBlockContent>
					<Heading level={4}>Thank you</Heading>
					<Paragraph>
						We appreciate your feedback and keep on working to make
						sure you are happy. With urgent questions, you always
						can contact us at the{' '}
						<Anchor href={URL_SUPPORT_DESK}>Support desk</Anchor>.
					</Paragraph>
				</HelpfulBlockContent>
				<HelpfulBlockIllustration>
					<img
						src={urlToReplyIllustration}
						alt="An abstract illustration for our appreciation of your feedback"
					/>
				</HelpfulBlockIllustration>
			</HelpfulBlockRow>
		</HelpfulBlock>
	);
};

export default WasThisPageHelpful;
