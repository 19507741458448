const labels: {
	[baseAttributeValue: string]: string;
} = {
	'*': 'Any',
	'named-tuple': 'Named tuple',
	'react:element': 'React element',
	'react:node': 'React node',
	'type-literal': 'Type literal',
	action: 'Action step',
	any: 'Any',
	array: 'Array',
	bigint: 'BigInt',
	boolean: 'Boolean',
	class: 'Class',
	component: 'React component',
	constructor: 'Constructor',
	date: 'Date',
	enum: 'Enumeration',
	event: 'Event',
	false: 'False',
	function: 'Function',
	intersection: 'Intersection',
	intrinsic: 'Intrinsic',
	never: 'Never',
	null: 'Null',
	number: 'Number',
	object: 'Object',
	operation: 'Operation',
	parenthesized: 'Parenthesized',
	promise: 'Promise',
	string: 'String',
	symbol: 'Symbol',
	transform: 'Transform step',
	true: 'True',
	tuple: 'Tuple',
	undefined: 'Undefined',
	union: 'Union',
	unknown: 'Unknown',
	void: 'Void',
};

/**
 * FAD uses some "base types", which are the type of memory objects that are not other documented types. For example,
 *   strings, numbers, and so on. This function is used to map those types to human-readable labels.
 *
 * Find all @base values currently in use by running the following CLI in fonto-documentation:
 *
 *   npx xquery-cli -g "**\*.xml" -x "//type/@base/string()" -b 1000 | npx count-duplicate-lines
 */
export function getFadBaseTypeLabel(baseAttributeValue?: string): string {
	if (baseAttributeValue === undefined) {
		return '';
	}
	return labels[baseAttributeValue] || baseAttributeValue;
}
