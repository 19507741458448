import { evaluateXPathToString } from 'fontoxpath';
import { ReactRenderer } from 'xml-renderer';

import { Anchor } from '../../content-components';
import type { RuleContext } from '../types';
import sharedElementRules from './shared';

const rules = new ReactRenderer<RuleContext>(sharedElementRules);

rules.add('self::*[not(parent::*)]', ({ traverse }) => (
	<>{traverse('child::channel')}</>
));

rules.add('self::channel', ({ traverse }) => (
	<>{traverse('child::item[position() < 2]')}</>
));

rules.add('self::item', ({ node }) => {
	const title = evaluateXPathToString('child::title/text()', node);
	const href = evaluateXPathToString('child::link/text()', node);

	return (
		<Anchor
			external={true}
			href={`${href}?utm_source=documentation&utm_medium=feed&utm_campaign=documentation`}
		>
			{title.replace('/^Fonto Why & How:s*/gi', '')}
		</Anchor>
	);
});

export default rules;
