import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type {
	AnchorHTMLAttributes,
	DetailedHTMLProps,
	FunctionComponent,
} from 'react';

import { useStyledComponentsContext } from '../hooks/styled-components-context';

const IconWrapperStyledComponent = styled.span`
	font-size: 80%;
	vertical-align: center;
	line-height: inherit;
	margin-left: 0.25em;
`;

type AnchorPropsType = DetailedHTMLProps<
	AnchorHTMLAttributes<HTMLAnchorElement>,
	HTMLAnchorElement
> & {
	external?: boolean;
	disableExternalLinkIndicator?: boolean;
	href?: string;
	css?: any;
};

export type LinkOptions = {
	/**
	 * Determine wether a link is "external" based on the reference target. External links are given an icon to indicate
	 * so. Only determined automatically if the "external" prop on Anchor is not set.
	 */
	getAnchorIsExternal?(this: void, href: string): boolean;

	innerAnchorComponent?: FunctionComponent<AnchorPropsType>;
};

const anchorStyles = css`
	color: var(--color-purple-600);
	text-decoration: underline;
	cursor: pointer;
	outline: none;

	&:hover {
		text-decoration: none;
	}
`;

export const BrokenAnchor = styled.a`
	${anchorStyles};
	text-decoration: line-through;
	&:hover {
		text-decoration: line-through;
	}
`;

export const Anchor: FunctionComponent<AnchorPropsType> = ({
	external,
	disableExternalLinkIndicator,
	href,
	children,
	...restProps
}) => {
	const { getAnchorIsExternal, innerAnchorComponent } =
		useStyledComponentsContext();
	const showAsExternal =
		external !== undefined
			? external
			: getAnchorIsExternal && href
			? getAnchorIsExternal(href)
			: false;
	return jsx(
		innerAnchorComponent || 'a',
		{
			css: anchorStyles,
			external: innerAnchorComponent ? showAsExternal : undefined,
			href,
			...restProps,
		},
		<>
			{children}
			{showAsExternal && !disableExternalLinkIndicator ? (
				<IconWrapperStyledComponent>
					<FontAwesomeIcon icon={faExternalLinkAlt} />
				</IconWrapperStyledComponent>
			) : null}
		</>
	);
};
