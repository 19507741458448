import { evaluateXPathToBoolean } from 'fontoxpath';
import type { FunctionComponent, ReactNode } from 'react';
import { createElement } from 'react';
import { parseXmlDocument } from 'slimdom';

import { ENDPOINT_LOADING } from '../app-hooks/endpoint-caching';
import {
	SITEMAP_NODE_NOT_FOUND,
	useCurrentSitemapNode,
} from '../app-hooks/sitemap';
import {
	LargeList,
	LargeListItem,
	LargeListItemChild,
	Spinner,
} from '../content-components';
import { RULES_DITA, RULES_FAD } from '../content-rules';

function renderDescriptionForChildPage(targetExcerpt?: string): ReactNode {
	if (!targetExcerpt) {
		return null;
	}

	const dom = parseXmlDocument(targetExcerpt);
	return (
		evaluateXPathToBoolean('name(/*) = "shortdesc"', dom)
			? RULES_DITA
			: RULES_FAD
	).render(createElement, dom as unknown as Node, {
		articleHeroWidget: null,
	});
}

export const ChildPageList: FunctionComponent = () => {
	const activeSidebarMenuItem = useCurrentSitemapNode();
	if (activeSidebarMenuItem === ENDPOINT_LOADING) {
		// @TODO
		return <Spinner />;
	}
	if (activeSidebarMenuItem === SITEMAP_NODE_NOT_FOUND) {
		return null;
	}
	return (
		<LargeList>
			{activeSidebarMenuItem.children.map((childPage, index) => (
				<LargeListItem
					key={index}
					label={childPage.label}
					href={childPage.href}
				>
					{/* {renderDescriptionForChildPage(childPage.targetExcerpt)} */}
					{(childPage.children || []).map((childChildPage, index) => (
						<LargeListItemChild
							key={index}
							label={childChildPage.label}
							href={childChildPage.href}
						>
							{/* {renderDescriptionForChildPage(childChildPage.targetExcerpt)} */}
						</LargeListItemChild>
					))}
				</LargeListItem>
			))}
		</LargeList>
	);
};
