import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { faHome } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent } from 'react';
import { forwardRef, useCallback, useMemo, useState } from 'react';

import { ENDPOINT_LOADING } from '../app-hooks/endpoint-caching';
import { useSafeRoutingParameters } from '../app-hooks/route-parameters';
import { SITEMAP_NOT_FOUND, useSitemap } from '../app-hooks/sitemap';
import fontoLogoSvg from '../app-images/fonto-logo.svg';
import { Anchor, Spinner } from '../content-components';
import {
	SPACING_MEDIUM,
	SPACING_MEDIUM_SMALL,
} from '../content-components/shared/global';
import { SearchInput } from './search-input';
import VersionSelector from './version-selector';

const CorporateMenuContainer = styled.div`
	flex: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: var(--responsive-corporate-menu-height);

	padding-left: var(--spacing-medium-small);

	background-color: var(--color-grey-50);

	.logo {
		width: auto;
		height: var(--responsive-logo-height);
	}
`;
const CorporateMenuItemsContainer = styled.ul`
	flex: 1;
	display: flex;
	flex-direction: row;

	justify-content: flex-end;
	align-items: center;

	margin: 0 var(--spacing-medium) 0 0;
	padding: 0;
	width: 100%;

	@media (max-width: 700px) {
		// At the approximate screen width where the CorporateMenuItems start to overlap the logo
		// Hide the menu items
		display: none;
	}
`;
const CorporateMenuItem = styled.li`
	flex: none;

	display: flex;
	flex-direction: row;

	margin: 0;
	padding: 0;

	> a {
		display: flex;
		flex-direction: row;

		align-items: center;

		margin: calc(var(--spacing-medium) / 2);

		text-decoration: none;
		font-family: var(--font-primary);
		font-size: 16px;
		font-stretch: normal;
		font-style: normal;
		line-height: 19px;
		letter-spacing: normal;

		outline: none;
		color: var(--color-grey-800);
		font-weight: var(--font-weight-normal);

		&:hover {
			color: var(--color-pink-600);
		}

		&.current {
			font-weight: var(--font-weight-subtle);
			box-shadow: 0px 4px var(--legacy-color-purple-300);
		}

		@media (max-width: 800px) {
			& {
				margin: calc(var(--spacing-medium) / 2) var(--spacing-small);
			}
		}
	}
`;
const CorporateWebsiteMenu: FunctionComponent = () => {
	const sitemap = useSitemap();
	return (
		<CorporateMenuContainer>
			<Anchor href="/" external={false} style={{ display: 'flex' }}>
				<img className="logo" alt="The Fonto logo" src={fontoLogoSvg} />
			</Anchor>

			<CorporateMenuItemsContainer>
				<CorporateMenuItem>
					<Anchor href="https://www.fontoxml.com/demo/">
						Request Demo
					</Anchor>
				</CorporateMenuItem>
				<CorporateMenuItem>
					<Anchor href="https://www.fontoxml.com/blog/">Blog</Anchor>
				</CorporateMenuItem>

				<CorporateMenuItem>
					<Anchor
						className="current"
						href={
							typeof sitemap === 'string'
								? '/'
								: sitemap?.[0]?.href
						}
					>
						Documentation
					</Anchor>
				</CorporateMenuItem>
				<CorporateMenuItem>
					<Anchor href="https://www.fontoxml.com/contact/">
						Contact
					</Anchor>
				</CorporateMenuItem>
				<CorporateMenuItem>
					<Anchor href="https://support.fontoxml.com/">
						Support desk
					</Anchor>
				</CorporateMenuItem>
			</CorporateMenuItemsContainer>
		</CorporateMenuContainer>
	);
};

const MainMenuItem = styled.li<{
	isActive?: boolean;
	isMobileVersion?: boolean;
}>`
	flex: none;
	display: flex;
	flex-direction: row;
	margin: 0;
	padding: 0;

	> a {
		cursor: pointer;
		outline: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 var(--spacing-medium);
		text-decoration: none;
		font-family: var(--font-primary);
		font-size: 16px;
		font-weight: ${({ isActive = false }) =>
			isActive
				? 'var(--font-weight-subtle)'
				: 'var(--font-weight-normal)'};
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: var(--color-grey-800);
		${({ isActive = false }) =>
			isActive
				? css`
						background-color: var(--color-grey-200);
						box-shadow: inset 0px 1px 2px 1px rgba(0, 0, 0, 0.16),
							inset 0px -4px var(--color-grey-700);
				  `
				: null}

		${({ isMobileVersion }) =>
			isMobileVersion
				? css`
						border-top-left-radius: var(--border-radius-small);
						border-top-right-radius: var(--border-radius-small);
				  `
				: null}
		&:hover {
			background-color: var(--color-grey-200);
		}
		&:focus {
			box-shadow: inset 0 0 0px 1px var(--color-grey-700);
			background-color: var(--color-grey-200);
		}
	}
`;
const MainMenuItemsWrapper = styled.ul`
	display: flex;
	flex-direction: row;
	margin: 0;
	padding: 0;
	&.for-mobile {
		height: var(--spacing-large);
		display: none;
	}
	&.for-desktop {
		height: var(--spacing-largest);
		display: flex;
	}
	@media (max-width: 1280px) {
		&.for-desktop a {
			padding: var(--spacing-small);
		}
	}
	// Approximately the window width at which the search, menu item buttons and version selector each at their smallest
	// size still fit next to one another:
	@media (max-width: 1000px) {
		&.for-mobile {
			display: flex;
		}
		&.for-desktop {
			display: none;
		}
	}
	@media (max-width: 840px) {
		&.for-mobile {
			padding-left: var(--spacing-small);
		}
		&.for-mobile a {
			padding: var(--spacing-small);
		}
	}
`;
const MainMenuItemsSpinnerContainer = styled.div`
	padding: ${SPACING_MEDIUM_SMALL} 0;
	align-items: center;
	&.for-mobile {
		height: var(--spacing-large);
		display: none;
	}
	&.for-desktop {
		height: var(--spacing-largest);
		display: flex;
	}
	@media (max-width: 1000px) {
		&.for-mobile {
			display: flex;
		}
		&.for-desktop {
			display: none;
		}
	}
`;
const MainMenuItems: FunctionComponent<{
	isMobileVersion: boolean;
}> = ({ isMobileVersion }) => {
	const sitemap = useSitemap();
	const { path } = useSafeRoutingParameters();
	const menuItems = useMemo(() => {
		if (typeof sitemap === 'string') {
			return sitemap;
		}
		return (sitemap ? [sitemap[0], ...sitemap[0].children] : [])
			.filter((menuItem) => !menuItem.deprecated)
			.map((menuItem, i) => (
				<MainMenuItem
					key={menuItem.href}
					isMobileVersion={isMobileVersion}
					isActive={[menuItem].reduce<boolean>(function r(
						isActive,
						menuItem
					): boolean {
						if (isActive || menuItem.href === path) {
							return true;
						}
						if (menuItem === sitemap?.[0]) {
							// The homepage only matches if it is visited directly, it does not match if
							// any of its children (= entire site) are visited.
							return false;
						}
						return menuItem.children.reduce(r, isActive);
					},
					false)}
				>
					<Anchor href={menuItem.href}>
						{i === 0 ? (
							<FontAwesomeIcon icon={faHome} />
						) : (
							menuItem.label
						)}
					</Anchor>
				</MainMenuItem>
			));
	}, [sitemap, path, isMobileVersion]);

	if (menuItems === ENDPOINT_LOADING) {
		return (
			<MainMenuItemsSpinnerContainer
				className={isMobileVersion ? 'for-mobile' : 'for-desktop'}
			>
				<Spinner size={SPACING_MEDIUM} trackColor="transparent" />
			</MainMenuItemsSpinnerContainer>
		);
	}
	if (menuItems === SITEMAP_NOT_FOUND) {
		// @TODO Should there be an error message or other for the scenario in which the main
		//   menu cannot be rendered because there is no sitemap?
		return null;
	}

	return (
		<MainMenuItemsWrapper
			className={isMobileVersion ? 'for-mobile' : 'for-desktop'}
		>
			{menuItems}
		</MainMenuItemsWrapper>
	);
};

// The left-hand side of the "normal" (= desktop sized) purple navigation bar that contains the search input and
//   links to the top-level categories. This string of components makes sure that the main menus are compressed or hidden
//   based on the size of the screen and the open-ness of the search input.
const MainNavigationMenuLeftContainer = styled.div`
	flex: 1;

	display: flex;
	flex-direction: row;
	justify-content: space-start;
	align-items: center;

	margin: 0;
	padding: 0;
`;
const MainNavigationMenuSearch = styled.div<{
	isSearchInputExpanded: boolean;
}>`
	--padding: var(--spacing-medium-small);
	padding: var(--padding);
	max-width: ${({ isSearchInputExpanded = false }) =>
		isSearchInputExpanded
			? '100%'
			: 'calc(4 * var(--spacing-largest) + 2 * var(--padding))'};
	min-width: calc(2 * var(--spacing-largest));
	flex: 1;
	z-index: 1;
`;
const MainNavigationMenuLeft: FunctionComponent = () => {
	const [isSearchInputExpanded, setIsSearchInputExpanded] = useState(false);

	const handleSearchInputClickOutside = useCallback(() => {
		setIsSearchInputExpanded(false);
	}, [setIsSearchInputExpanded]);

	const handleSearchInputFocus = useCallback(() => {
		setIsSearchInputExpanded(true);
	}, [setIsSearchInputExpanded]);

	return (
		<MainNavigationMenuLeftContainer>
			<MainNavigationMenuSearch
				isSearchInputExpanded={isSearchInputExpanded}
			>
				<SearchInput
					onClickOutside={handleSearchInputClickOutside}
					onFocus={handleSearchInputFocus}
					isStandalone={false}
				/>
			</MainNavigationMenuSearch>

			{!isSearchInputExpanded && (
				<MainMenuItems isMobileVersion={false} />
			)}
		</MainNavigationMenuLeftContainer>
	);
};
const MainNavigationContainer = styled.nav`
	flex: none;
	/* To counteract align-items: center on parent element in Layout.tsx */
	align-self: stretch;

	display: flex;
	flex-direction: column;

	box-shadow: var(--elevation-300);
	/* So it is always on top of the content, even when there is a modal open
	on top of the content (see VersionInfoModal). */
	z-index: 2;

	will-change: margin-top;
	transition: 0.2s margin-top ease-out;

	user-select: none;
`;
const MainNavigationMenuContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: var(--color-grey-50);
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 0;
	padding: 0;
`;

export const MainNavigation = forwardRef<HTMLElement>(
	(_props, forwardedRef) => (
		<MainNavigationContainer ref={forwardedRef}>
			<CorporateWebsiteMenu />
			<MainNavigationMenuContainer>
				<MainNavigationMenuLeft />
				<VersionSelector />
			</MainNavigationMenuContainer>
			<MainNavigationMenuContainer>
				<MainMenuItems isMobileVersion={true} />
			</MainNavigationMenuContainer>
		</MainNavigationContainer>
	)
);
MainNavigation.displayName = 'MainNavigation';
