import styled from '@emotion/styled/macro';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type {
	FunctionComponent,
	HtmlHTMLAttributes,
	ReactElement,
} from 'react';
import { useState } from 'react';

import { Paragraph } from '../atoms/paragraphs';

const Box = styled.section`
	border: 1px solid var(--legacy-color-medium-grey);
	border-radius: var(--border-radius-medium);
	/* & + & {
		border-top: none;
	} */

	> header {
		display: flex;
		flex-direction: row;
		align-items: center;

		min-height: var(--spacing-medium-large);

		> svg {
			margin-left: var(--spacing-medium-small);
		}

		> * + * {
			margin-left: var(--spacing-medium-small);
		}
		&:hover {
			cursor: pointer;
			background-color: var(--color-purple-50);
		}
	}

	> div {
		margin: var(--spacing-medium-small);

		> :not(section) + * {
			margin-top: var(--spacing-medium-small);
		}
	}
`;

export const CollapsibleBoxGroup = styled.div`
	${Box} {
		background-color: var(--color-grey-50);
		border-radius: 0;
	}
	${Box} + ${Box} {
		border-top: none;
	}
	${Box}:first-of-type {
		border-top-left-radius: var(--border-radius-medium);
		border-top-right-radius: var(--border-radius-medium);
	}
	${Box}:last-child {
		border-bottom-left-radius: var(--border-radius-medium);
		border-bottom-right-radius: var(--border-radius-medium);
	}
`;

const Header = styled.header`
	user-select: none;
`;

const HeaderRightSide = styled.div`
	margin-left: auto !important;
	margin-right: var(--spacing-medium-small);
`;

export const CollapsibleBox: FunctionComponent<
	HtmlHTMLAttributes<HTMLElement> & {
		label: ReactElement | string;
		labelAside?: ReactElement | string;
		openedInitially?: boolean;
	}
> = ({ label, labelAside, children, openedInitially, ...rest }) => {
	const [isCollapsed, setIsCollapsed] = useState(!openedInitially);

	return (
		<Box {...rest}>
			<Header
				onClick={() => {
					setIsCollapsed((isCollapsed) => !isCollapsed);
				}}
			>
				<FontAwesomeIcon
					icon={isCollapsed ? faAngleDown : faAngleUp}
					fixedWidth
				/>
				<Paragraph>{label}</Paragraph>
				{labelAside && <HeaderRightSide>{labelAside}</HeaderRightSide>}
			</Header>

			{!isCollapsed && <div>{children}</div>}
		</Box>
	);
};
