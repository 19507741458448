import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons';
import {
	faAngleDown,
	faAngleUp,
	faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { ENDPOINT_LOADING } from '../app-hooks/endpoint-caching';
import {
	useCurrentVersion,
	useVersionList,
	VERSION_LIST_NOT_FOUND,
	VERSION_NOT_FOUND,
} from '../app-hooks/versions';
import { Anchor, Spinner } from '../content-components';
import {
	SPACING_MEDIUM,
	SPACING_MEDIUM_SMALL,
} from '../content-components/shared/global';
import type { VersionJson } from '../types';
import { VersionInfoModal } from './version-info-modal';

const Dropdown = styled.div<{ isActive?: boolean }>`
	flex: none;
	display: flex;
	position: relative;
	margin: 0 var(--spacing-medium-small) 0;
	padding: 0 0 0 var(--spacing-medium-small);
	color: var(--color-grey-800);
	height: var(--spacing-largest);

	${({ isActive = false }) =>
		isActive
			? css`
					background-color: var(--color-grey-200);
					box-shadow: inset 0px 1px 2px 1px rgba(0, 0, 0, 0.16);
			  `
			: null}
`;
const DropdownButton = styled.div`
	flex: none;

	display: flex;
	flex-direction: row;
	align-items: center;

	height: var(--spacing-largest);

	cursor: pointer;

	user-select: none;

	> span {
		text-decoration: none;
		font-family: var(--font-primary);
		font-size: 16px;
		font-weight: var(--font-weight-bold);
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
	}
`;

const Drop = styled.ul`
	flex: none;

	position: absolute;

	margin: 0;
	padding: 0;
	width: 280px;
	right: var(--spacing-small);

	top: calc(var(--spacing-largest) - var(--spacing-medium-small));

	box-shadow: var(--elevation-400);
	background-color: var(--color-grey-50);
	border-top-left-radius: var(--border-radius-small);
	border-bottom-left-radius: var(--border-radius-small);
	border-bottom-right-radius: var(--border-radius-small);
	overflow: hidden;
`;

type DropdownItemProps = {
	isActive?: boolean;
	isFindVersionHelp?: boolean;
	onClick?(): void;
};
const DropdownItem = styled.li<DropdownItemProps>`
	flex: none;

	display: flex;
	flex-direction: column;
	align-items: stretch;

	margin: 0;
	padding: 0;

	> a,
	> span {
		flex: none;

		display: flex;
		flex-direction: row;

		margin: 0;
		padding: calc(var(--spacing-small) / 2) var(--spacing-medium-small);

		font-family: var(--font-primary);
		font-size: 16px;
		font-weight: var(--font-weight-normal);
		font-stretch: normal;
		font-style: normal;
		line-height: var(--spacing-medium-large);
		letter-spacing: normal;
		text-decoration: none;

		user-select: none;

		color: var(--color-grey-800);
		${({ isActive }) =>
			isActive
				? css`
						background-color: var(--color-grey-200);
						box-shadow: inset 0px 1px 2px 1px rgba(0, 0, 0, 0.16),
							inset 4px 0px var(--color-grey-800);
				  `
				: null}
		border-bottom: ${({ isFindVersionHelp }: DropdownItemProps) =>
			isFindVersionHelp ? '1px solid var(--color-purple-900);' : 'none'};

		&:hover {
			background-color: var(--color-grey-200);
		}
	}
	> span {
		${({ onClick }: DropdownItemProps) => onClick && 'cursor: pointer;'}
	}

	> a {
		outline: none;
	}
`;

const UnavailableVersion = styled.span`
	color: var(--legacy-color-red-400);

	/* "Not available" label */
	> span {
		font-size: 12px;
		margin-top: 4px;

		/* container around (?) FontAwesome icon + popover */
		> span {
			margin-left: 2px;

			position: relative;
		}
	}
`;

const VersionSelector: FunctionComponent = () => {
	const versionList = useVersionList();

	if (versionList === ENDPOINT_LOADING) {
		return (
			<div style={{ padding: SPACING_MEDIUM_SMALL }}>
				<Spinner size={SPACING_MEDIUM} trackColor="transparent" />
			</div>
		);
	}
	if (versionList === VERSION_LIST_NOT_FOUND) {
		return (
			<div
				style={{
					padding: SPACING_MEDIUM_SMALL,
					color: 'var(--legacy-color-orange)',
				}}
			>
				<FontAwesomeIcon icon={faExclamationTriangle} />
			</div>
		);
	}
	return <InnerVersionSelector versionList={versionList} />;
};
const AnchorForUnavailableVersion: FunctionComponent<
	PropsWithChildren & {
		href: string;
	}
> = ({ href, children }) => {
	return (
		<Anchor href={href}>
			<UnavailableVersion>
				{children} <span>Not available</span>
			</UnavailableVersion>
		</Anchor>
	);
};

const InnerVersionSelector: FunctionComponent<{
	versionList: VersionJson[];
}> = ({ versionList }) => {
	const [isDropOpen, setIsDropOpen] = useState(false);
	const handleDropdownClick = useCallback(() => {
		setIsDropOpen((isDropOpen) => !isDropOpen);
	}, []);

	const [isVersionInfoModalOpen, setIsVersionInfoModalOpen] = useState(false);
	const handleVersionHelpDropdownItemClick = useCallback(() => {
		setIsDropOpen(false);
		setIsVersionInfoModalOpen(
			(isVersionInfoModalOpen) => !isVersionInfoModalOpen
		);
	}, []);
	const closeVersionInfoModal = useCallback(() => {
		setIsVersionInfoModalOpen(false);
	}, []);

	const dropdownRef = useRef<HTMLDivElement>(null);
	const handleWindowClick = useCallback((event: Event) => {
		if (
			dropdownRef.current &&
			dropdownRef.current.contains(event.target as Node)
		) {
			return;
		}

		setIsDropOpen(false);
	}, []);

	const handleWindowKeyUp = useCallback((event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			setIsDropOpen(false);
		}
	}, []);

	useEffect(() => {
		if (!isVersionInfoModalOpen) {
			window.document.addEventListener('click', handleWindowClick);
			window.document.addEventListener('keyup', handleWindowKeyUp);
		}

		return () => {
			window.document.removeEventListener('click', handleWindowClick);
			window.document.removeEventListener('keyup', handleWindowKeyUp);
		};
	}, [handleWindowClick, handleWindowKeyUp, isVersionInfoModalOpen]);

	const activeVersion = useCurrentVersion();

	let dropdownButtonLabel = '';
	if (activeVersion === ENDPOINT_LOADING) {
		dropdownButtonLabel = 'Loading…';
	} else if (
		activeVersion === VERSION_LIST_NOT_FOUND ||
		activeVersion === VERSION_NOT_FOUND
	) {
		dropdownButtonLabel = 'Unknown!';
	} else {
		dropdownButtonLabel = activeVersion.label;
		if (activeVersion.sortOrder === 0) {
			dropdownButtonLabel += ' (latest)';
		}
	}

	return (
		<Dropdown ref={dropdownRef} isActive={isDropOpen}>
			<DropdownButton onClick={handleDropdownClick}>
				<FontAwesomeIcon icon={faLayerGroup} fixedWidth />

				<span>Version: {dropdownButtonLabel}</span>

				<FontAwesomeIcon
					icon={isDropOpen ? faAngleUp : faAngleDown}
					size="lg"
					fixedWidth
				/>
			</DropdownButton>

			{isDropOpen && (
				<Drop>
					<DropdownItem
						isFindVersionHelp
						onClick={handleVersionHelpDropdownItemClick}
					>
						<span>Need help to find your version?</span>
					</DropdownItem>

					{versionList.map((pageVersion) => {
						let text = pageVersion.label;
						if (pageVersion.sortOrder === 0) {
							text += ' (latest)';
						}
						const isActive = pageVersion === activeVersion;
						return (
							<DropdownItem
								key={pageVersion.label}
								isActive={isActive}
							>
								{isActive && <span>{text}</span>}
								{!isActive && pageVersion.isAvailable && (
									<Anchor href={pageVersion.href}>
										{text}
									</Anchor>
								)}

								{!pageVersion.isAvailable && (
									<AnchorForUnavailableVersion
										href={pageVersion.href}
									>
										{text}
									</AnchorForUnavailableVersion>
								)}
							</DropdownItem>
						);
					})}
				</Drop>
			)}

			{isVersionInfoModalOpen && (
				<VersionInfoModal close={closeVersionInfoModal} />
			)}
		</Dropdown>
	);
};

export default VersionSelector;
