import type { FunctionComponent } from 'react';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ErrorBoundaryEnabledRoute } from './app-routes/ErrorRoute';
import { NotFoundRoute } from './app-routes/NotFoundRoute';
import { SearchRoute } from './app-routes/SearchRoute';
import { XmlRoute } from './app-routes/XmlRoute';
import { StyledComponentsContextProvider } from './content-components';

const App: FunctionComponent = () => {
	return (
		<Routes>
			<Route
				path="/:version/search"
				element={
					<ErrorBoundaryEnabledRoute
						happyFlowComponent={SearchRoute}
					/>
				}
			/>

			<Route
				path="/"
				element={
					<ErrorBoundaryEnabledRoute
						happyFlowComponent={XmlRoute}
						restProps={{ expectSidebar: false }}
					/>
				}
			/>

			<Route
				path="/:version"
				element={
					<ErrorBoundaryEnabledRoute
						happyFlowComponent={XmlRoute}
						restProps={{ expectSidebar: false }}
					/>
				}
			/>

			<Route
				path="/:version/:document"
				element={
					<ErrorBoundaryEnabledRoute
						happyFlowComponent={XmlRoute}
						restProps={{ expectSidebar: true }}
					/>
				}
			/>
			<Route
				element={
					<ErrorBoundaryEnabledRoute
						happyFlowComponent={NotFoundRoute}
					/>
				}
			/>
		</Routes>
	);
};
render(
	<StrictMode>
		<StyledComponentsContextProvider>
			<Router>
				<App />
			</Router>
		</StyledComponentsContextProvider>
	</StrictMode>,
	window.document.getElementById('root')
);
