import {
	evaluateXPathToBoolean,
	evaluateXPathToFirstNode,
	evaluateXPathToNodes,
	evaluateXPathToString,
} from 'fontoxpath';
import type { ReactNode } from 'react';
import { createElement, Fragment } from 'react';
import type { XmlRendererReactOutput } from 'xml-renderer';
import { ReactRenderer } from 'xml-renderer';

import { Anchor, BrokenAnchor, CodePhrase } from '../../content-components';
import { getFadBaseTypeLabel } from '../querying/fad-base-types';

const rules = new ReactRenderer();

// #############
// :
// :   FAD RESTRICTIONS
// :
// <<<<<<<

rules.add('self::text()', ({ node }) => <>{node.nodeValue}</>);

rules.add('self::type', ({ traverse, node }) => {
	const base = evaluateXPathToString('@base', node);
	const restrict = traverse('./restrict');
	return (
		<>
			{restrict.length
				? restrict
				: getFadBaseTypeLabel(base) ||
				  evaluateXPathToString('./name', node)}
		</>
	);
});

// A <type> referring to a <type> defined elsewhere.
rules.add('self::type[@reference]', ({ node }) => {
	const href = evaluateXPathToString('@reference', node);
	return <Anchor href={href}>{evaluateXPathToString('./name', node)}</Anchor>;
});

// A <type> referring to a <type> defined elsewhere.
rules.add('self::type[child::value[@serialization="json"]]', ({ node }) => {
	const literal = evaluateXPathToString('./value', node);
	return <CodePhrase>{literal}</CodePhrase>;
});

// Content references to <type> that are themselves @sdk can simply hyperlink to that page. This avoids infinitely
// recursing the type restrictions all the way down to simply "object", which becomes useless.
rules.add('self::type[@included-from and @sdk]', ({ node }) => {
	const href = evaluateXPathToString('@included-from', node);
	const label = evaluateXPathToString('./name', node);
	return href ? (
		<Anchor href={href}>{label}</Anchor>
	) : (
		<BrokenAnchor>{label}</BrokenAnchor>
	);
});

export function getSyntaxPartsForRestrictNode(node: Element): {
	prefix: ReactNode | string;
	suffix: string;
	join: string;
	types: XmlRendererReactOutput[];
} {
	// Convert/cast XPath Node result as Slimdom Node (array).
	let types: XmlRendererReactOutput[] = evaluateXPathToNodes(
		'./(type|restrict)',
		node
	).map((typeNode) => rules.render(createElement, typeNode as Node));
	const isNestedRestrict = evaluateXPathToBoolean(
		`
			let $restrictUnderResetBoundary := ancestor::tips[@type = "named"]/descendant::tips,
				$restricts := if ($restrictUnderResetBoundary)
					then $restrictUnderResetBoundary
					else ancestor::tips
			return count($restricts) > 1
		`,
		node
	);
	const type = node.getAttribute('type');

	let join = ' | ';
	let prefix: ReactNode | string = '';
	let suffix = '';

	// TS FAD.
	if (type === 'intersection') {
		join = ' & ';
		prefix = isNestedRestrict ? '(' : '';
		suffix = isNestedRestrict ? ')' : '';
	} else if (type === 'union') {
		join = ' | ';
		prefix = isNestedRestrict ? '(' : '';
		suffix = isNestedRestrict ? ')' : '';
	} else if (type === 'class') {
		join = ' ';
		prefix = 'Class ';
	} else if (type === 'extends') {
		prefix = 'extends ';
	} else if (type === 'implements') {
		join = ', ';
		prefix = 'implements ';
	} else if (type === 'infer') {
		prefix = 'infer ';
	} else if (type === 'conditional') {
		// A Conditional restrict consists of four types: check, extends, true, and false.
		// Using the first three types with appropriate keywords and operators as prefix, and
		// leaving the remaining type (false type) to render normally.
		prefix = (
			<>
				{types.shift()}
				{' extends '}
				{types.shift()}
				{' ? '}
				{types.shift()}
				{' : '}
			</>
		);
	} else if (type === 'generic') {
		// The first type indicates the generic.
		prefix = (
			<>
				{types.shift()}
				{'<'}
			</>
		);
		suffix = '>';
		join = ', ';
	} else if (type === 'named') {
		join = ': ';
		prefix = isNestedRestrict ? '(' : '';
		suffix = isNestedRestrict ? ')' : '';
	} else if (type === 'nested') {
		join = '.';
		prefix = isNestedRestrict ? '(' : '';
		suffix = isNestedRestrict ? ')' : '';
	} else if (type === 'named-tuple') {
		prefix = '[';
		suffix = ']';
		join = ', ';
	} else if (type === 'keyof') {
		prefix = 'keyof ';
	} else if (type === 'parenthesized') {
		prefix = '(';
		suffix = ')';
	} else if (type === 'template-literal') {
		prefix = '`';
		suffix = '`';
		join = '';
		types = evaluateXPathToNodes('./(type|restrict)', node).map(
			(typeNode) => {
				// Output string literals as is, without quotes.
				if (
					evaluateXPathToBoolean(
						'self::type[@base = "string" and child::value[@serialization="json"]]',
						typeNode
					)
				) {
					return evaluateXPathToString('./value', typeNode).replace(
						/(^"|"$)/g,
						''
					);
				}
				// Output others as a variable.
				return (
					<>
						{'${'}
						{rules.render(createElement, typeNode as Node)}
						{'}'}
					</>
				);
			}
		);
	} else if (type === 'tuple') {
		prefix = '[';
		suffix = ']';
		join = ', ';
	} else if (type === 'rest') {
		prefix = '...';
	} else if (type === 'type-literal') {
		prefix = '{ ';
		suffix = ' }';
		join = ', ';
	} else if (type === 'type-predicate') {
		join = ' is';
		prefix = isNestedRestrict ? '(' : '';
		suffix = isNestedRestrict ? ')' : '';
	} else if (type === 'typeof') {
		prefix = 'typeof ';
	} else if (type === 'function' || type === 'constructor') {
		const isConstructor = type === 'constructor';
		prefix = isNestedRestrict ? '(' : '';
		suffix = isNestedRestrict ? ')' : '';
		const renderedTypeParameterNodes = evaluateXPathToNodes(
			'./restrict[@type="type-parameter"]',
			node
		).map((typeParameterNode, index) => {
			const nameNode = evaluateXPathToFirstNode(
				'./type',
				typeParameterNode
			);
			const renderedNameNode =
				nameNode && rules.render(createElement, nameNode as Node);
			const extendsNode = evaluateXPathToFirstNode(
				'./restrict[@type="extends"]',
				typeParameterNode
			);
			const renderedExtendsNode =
				extendsNode && rules.render(createElement, extendsNode as Node);
			const defaultNode = evaluateXPathToFirstNode(
				'./restrict[@type="default"]',
				typeParameterNode
			);
			const renderedDefaultNode =
				defaultNode && rules.render(createElement, defaultNode as Node);
			return (
				<>
					{index === 0 ? null : ', '}
					{renderedNameNode}
					{renderedExtendsNode ? ' extends ' : null}
					{renderedExtendsNode || null}
					{renderedDefaultNode ? ' = ' : null}
					{renderedDefaultNode || null}
				</>
			);
		});
		// Filter all type-parameters.
		types = evaluateXPathToNodes(
			'./(type|restrict)[not(@type="type-parameter")]',
			node
		).map((typeNode) => rules.render(createElement, typeNode as Node));
		// Use the first type as return type.
		// TODO: Only render return type for constructor if it does not return a reference (to the same class).
		const renderedReturnType = types.shift();
		types = [
			<>
				{isConstructor ? 'new ' : null}
				{renderedTypeParameterNodes.length ? '<' : null}
				{renderedTypeParameterNodes.length
					? renderedTypeParameterNodes
					: null}
				{renderedTypeParameterNodes.length ? '>' : null}(
				{types.map((renderedType, i) => {
					return i ? (
						<Fragment key={i}>
							{', '}
							{renderedType}
						</Fragment>
					) : (
						renderedType
					);
				})}
				{') => '}
				{renderedReturnType}
			</>,
		];
	} else {
		// Legacy JS FAD.
		switch (type) {
			case 'array':
				prefix = 'Array<';
				suffix = '>';
				break;
			case 'promise':
				prefix = 'Promise<';
				suffix = '>';
				break;
			case 'object':
				prefix = 'Object<';
				suffix = '>';
				join = ', ';
				break;
			case '[object Object]':
				// DEV-9014: This scenario is a known bug in FAD:
				//   <restrict type="[object Object]">
				prefix = 'Object<';
				suffix = '>';
				join = ', ';
				break;
		}
	}

	return {
		prefix,
		types,
		join,
		suffix,
	};
}

// Flattening an XML structure that expresses type information into a string
// This is also where a <type> with @reference could download another XML file in order to fully resolve, although
// this is normally not useful to the reader. Instead, a hyperlink to that API page is probably preferred.
// TODO fix that the rules currently "fully resolve", which is not useful.
rules.add('self::restrict', ({ node }) => {
	const isOptional = evaluateXPathToBoolean(
		'self::*[@optional = "true"] and not(parent::type[parent::arguments or parent::members[parent::*/restrict/type[@base = "type-literal"]]])',
		node
	);
	const { prefix, suffix, join, types } = getSyntaxPartsForRestrictNode(
		node as Element
	);

	return (
		<>
			{prefix}
			{types.map((renderedType, i) => (
				<Fragment key={i}>
					{i ? join : null}
					{renderedType}
				</Fragment>
			))}
			{suffix}
			{isOptional ? '?' : null}
		</>
	);
});

export default rules;
