import styled from '@emotion/styled/macro';
import { faInfo, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { Bold, CodePhrase, Heading, Paragraph } from '../content-components';

const Modal = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;

	/* So it is always on top of the content, including content highlighted with
	prism.js in a CodeBlock. */
	z-index: 1;

	// Color --color-purple-900, but with 0.6 opacity
	background-color: rgba(17, 12, 39, 0.6);
`;

const ModalContent = styled.div`
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	border-radius: var(--border-radius-large);
	width: 704px;
	padding: var(--spacing-medium);
	box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
		0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
	background-color: var(--color-grey-50);
`;

const CloseIconContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;

	width: 40px;
	height: 40px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	color: var(--color-grey-400);

	&:hover {
		color: var(--color-grey-800);
	}
`;

const TextContainer = styled.div`
	> * + * {
		margin-top: var(--spacing-medium);
	}
`;

const SectionContainer = styled.div`
	> * + * {
		margin-top: var(--spacing-medium-small);
	}
`;

const VersionInfoModalContents: FunctionComponent = () => (
	<TextContainer>
		<Heading level={3}>SDK version number.</Heading>

		<SectionContainer>
			<Paragraph>
				<Bold>
					Where do I find the version number of my Fonto product?
				</Bold>
			</Paragraph>

			<Paragraph>
				The SDK version number can be found by clicking on the{' '}
				<FontAwesomeIcon icon={faInfo} /> icon in the status bar on the
				bottom right of your Editor.
			</Paragraph>

			<Paragraph>
				If you have access to the configuration code, you can also find
				the SDK version number in <CodePhrase>manifest.json</CodePhrase>
				.
			</Paragraph>
		</SectionContainer>

		<SectionContainer>
			<Paragraph>
				<Bold>Page not available for this release.</Bold>
			</Paragraph>

			<Paragraph>
				If a page is not available for a specific release it is either
				because the function or product did not yet exist or it has
				since been deprecated.
			</Paragraph>
		</SectionContainer>
	</TextContainer>
);

export const VersionInfoModal: FunctionComponent<{
	close(): void;
}> = ({ close }) => {
	const modalRootElement = window.document.querySelector('#modal-root');
	if (!modalRootElement) {
		throw new Error('modal root cannot be found');
	}

	const modalContentRef = useRef<HTMLDivElement>(null);

	const handleWindowClick = useCallback(
		(event: Event) => {
			if (
				modalContentRef.current &&
				modalContentRef.current.contains(event.target as Node)
			) {
				return;
			}

			close();
		},
		[close]
	);

	const handleWindowKeyUp = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				close();
			}
		},
		[close]
	);

	useEffect(() => {
		window.document.addEventListener('click', handleWindowClick);
		window.document.addEventListener('keyup', handleWindowKeyUp);

		return () => {
			window.document.removeEventListener('click', handleWindowClick);
			window.document.removeEventListener('keyup', handleWindowKeyUp);
		};
	}, [handleWindowClick, handleWindowKeyUp]);

	return createPortal(
		<Modal>
			<ModalContent ref={modalContentRef}>
				<CloseIconContainer onClick={close}>
					<FontAwesomeIcon icon={faTimes} />
				</CloseIconContainer>

				<VersionInfoModalContents />
			</ModalContent>
		</Modal>,
		modalRootElement
	);
};
