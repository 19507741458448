import styled from '@emotion/styled/macro';

export const Paragraph = styled.p<{ emphasis?: boolean }>`
	/* line-height: 1.81; */
	${({ emphasis }) =>
		emphasis
			? `
				font-size: var(--font-size-large);
				font-weight: var(--font-weight-subtle);
				// color: var(--color-pink-600);
			`
			: null}
`;
