import {
	evaluateXPathToBoolean,
	evaluateXPathToFirstNode,
	evaluateXPathToNodes,
	evaluateXPathToString,
} from 'fontoxpath';

import type { TableOfContentsItem } from '../types';

type SectionTypeWithoutVariants = {
	test: string;
	label: string | ((node: Node) => string);
	id: string | ((node: Node) => string);
	// Decides what is shown in the section. Defaults to "."
	traversal?: string;
};

type SectionTypeWithVariants = {
	// Decides what the context node is. If no context node, this section is not shown:
	test: string;
	variants: SectionType[];
};

type SectionType = SectionTypeWithoutVariants | SectionTypeWithVariants;

// This is a format in which the table-of-contents of a FAD file can be specified without repeating too much of
//   ourselves. Any element may yield zero, one or more table-of-contents items (eg. <members> results in both a
//   "Properties" and "Methods" section.
export const FAD_SECTIONS = {
	ARGUMENTS: {
		test: 'self::arguments',
		variants: [
			{
				label: 'Component props',
				id: 'arguments',
				test: '../restrict/type/@base = "component"',
			},
			{
				// Arguments to Javascript classes are constructor arguments
				//
				// nb; Only works for FAD v1. In FAD v2, the constructor description is found in <members>
				label: 'Constructor arguments',
				id: 'arguments',
				test: '../restrict/type/@base = "class" or ../restrict/type/@base = "constructor"',
			},
			{
				// Arguments to operation data are called "imported operation data",
				//   and they are only shown if they are properly documented.
				label: 'Imported operation data',
				id: 'arguments',
				test: 'ancestor-or-self::operation or ancestor-or-self::operation-step',
				traversal: './type[1]/members/*',
			},
			{
				// All other types of arguments are simply called arguments
				label: 'Function arguments',
				id: 'arguments',
				test: `
					not(../restrict/type/@base = "component") and
					not(../restrict/type/@base = "class") and
					not(ancestor-or-self::operation or ancestor-or-self::operation-step)
				`,
				traversal: './*, ',
			},
		],
	},

	TYPE_PARAMETERS: {
		label: 'Type parameters',
		id: 'type-parameters',
		test: 'self::type-parameters',
	},

	RETURNS: {
		test: 'self::return or self::returns',
		variants: [
			{
				label: 'Returns',
				id: 'returns',
				test: 'not(ancestor-or-self::operation or ancestor-or-self::operation-step)',
			},
			{
				label: 'Exported operation data',
				id: 'returns',
				test: 'ancestor-or-self::operation or ancestor-or-self::operation-step',
				traversal: './type[1]/members/*',
			},
		],
	},

	DEFAULT: {
		label: 'Default value',
		id: 'default',
		test: 'self::default',
	},

	MEMBERS: {
		test: 'self::members[child::*[@sdk]]',
		variants: [
			{
				// In FADv2, the constructor is part of class <members>
				label: 'Constructor arguments',
				id: 'constructor',
				test: 'child::type[./restrict/type/@base = "constructor" and not(./overloads)]',
				// Traverse into member types, so long as they are public SDK and not the
				// constructor (FAD v2)
				traversal: `
					./type[
						@sdk and
						./restrict/type/@base = "constructor" and
						not(./overloads)
					]
				`,
			},
			{
				// In FADv2, the constructor is part of class <members>
				label: 'Constructors',
				id: 'constructors',
				test: `
					child::type[./restrict/type/@base = "constructor"] and
					child::type[./overloads/type[@sdk]/restrict/type/@base = "constructor"]
				`,
				// Traverse into member type overloads, so long as they are public SDK and not the
				// constructor (FAD v2)
				traversal: `
					./type[
						@sdk and
						./overloads/type[@sdk]
					]/overloads/type[@sdk and ./restrict/type/@base = "constructor"]
				`,
			},
			{
				label: 'Properties',
				id: 'properties',
				test: `child::type[
					not(./restrict/type/@base = "constructor") and
					not(./restrict/type/@base = "function") and
					not(./overloads)
				]`,
				traversal: `./type[
					@sdk and
					not(./restrict/type/@base = "constructor") and
					not(./restrict/type/@base = "function") and
					not(./overloads)
				]`,
			},
			{
				label: 'Methods',
				id: 'methods',
				test: `
					child::type[./restrict/type/@base = "function"] or
					child::type[./overloads/type[@sdk]/restrict/type/@base = "function"]
				`,
				// Traverse into member types or their overloads, but skip private members as well
				// as the constructor member (FAD v2)
				traversal: `
					./type[
						@sdk and
						./restrict/type/@base = "function" and
						not(./overloads)
					],
					./type[
						child::overloads
					]/overloads/*[@sdk and ./restrict/type/@base = "function"]`,
			},
		],
	},

	STATIC_MEMBERS: {
		label: 'Static properties',
		id: 'static-members',
		test: 'self::static-members[child::*[@sdk]]',
	},

	RELATED_LINKS: {
		label: 'Related links',
		id: 'related-links',
		test: 'self::related-links',
	},

	HEADING: {
		label: (node: Node): string => evaluateXPathToString('.', node),
		id: (node: Node): string =>
			evaluateXPathToString('.', node)
				.toLowerCase()
				.replace(/\W+/g, ' ')
				.trim()
				.replace(/\s/g, '-'),
		test: 'self::heading',
	},

	INBOUND_REFERENCES: {
		label: 'This article is referenced from...',
		id: 'inbound-references',
		test: 'self::inbound-references',
	},
};

// The order of FAD section types;
const FAD_SECTIONS_LIST: SectionType[] = [
	FAD_SECTIONS.TYPE_PARAMETERS,
	FAD_SECTIONS.ARGUMENTS,
	FAD_SECTIONS.RETURNS,
	FAD_SECTIONS.DEFAULT,
	FAD_SECTIONS.MEMBERS,
	FAD_SECTIONS.STATIC_MEMBERS,
	FAD_SECTIONS.RELATED_LINKS,
	FAD_SECTIONS.HEADING,
	FAD_SECTIONS.INBOUND_REFERENCES,
];

const FAD_SECTIONS_FLAT_LIST = (function getFlattenedSectionTypeList(
	sectionTypes: SectionType[]
): SectionTypeWithoutVariants[] {
	return sectionTypes.reduce<SectionTypeWithoutVariants[]>(
		(flattenedSectionTypes, sectionType) => {
			if ((sectionType as SectionTypeWithVariants).variants) {
				const children = getFlattenedSectionTypeList(
					(sectionType as SectionTypeWithVariants).variants
				);
				return flattenedSectionTypes.concat(
					children.map((c) => ({
						...c,
						test: `[${sectionType.test}]${c.test}`,
					}))
				);
			}
			flattenedSectionTypes.push({
				...(sectionType as SectionTypeWithoutVariants),
				test: `[${sectionType.test}]`,
			});
			return flattenedSectionTypes;
		},
		[]
	);
})(FAD_SECTIONS_LIST);

function getMatchingFadSectionsTypesForNode(
	node: Node,
	possibleSectionTypes: SectionType[]
): SectionTypeWithoutVariants[] {
	return possibleSectionTypes
		.filter((sectionType) => evaluateXPathToBoolean(sectionType.test, node))
		.reduce<SectionTypeWithoutVariants[]>(
			(sectionTypes, sectionType) =>
				(sectionType as SectionTypeWithVariants).variants
					? sectionTypes.concat(
							getMatchingFadSectionsTypesForNode(
								node,
								(sectionType as SectionTypeWithVariants)
									.variants
							)
					  )
					: sectionTypes.concat([
							sectionType as SectionTypeWithoutVariants,
					  ]),
			[]
		);
}

// The sections of a <type> documentation, in specific order, and skipping sections without public SDK items
export const QUERY_FAD_SECTIONS = `
	source,
	deprecated,
	summary,
	description,
	example,
	user-facing,
	${FAD_SECTIONS_LIST.map((sectionType) => `./*[${sectionType.test}]`).join(',')}
`;

function insertItemAtLevelRelativeToLast(
	// The (depth-first) previous item
	lastSectionInfo: TableOfContentsItem & {
		parent?: TableOfContentsItem;
	},
	sectionInfo: TableOfContentsItem & {
		parent?: TableOfContentsItem;
	}
): TableOfContentsItem {
	// level 0 = insert as sibling
	// level > 1 = insert as descendant
	// level < 1 = insert as sibling of ancestor
	const levelDiff =
		parseInt(
			(sectionInfo.node as Element).getAttribute('level') || '0',
			10
		) -
		parseInt(
			(lastSectionInfo.node as Element | undefined)?.getAttribute(
				'level'
			) || '0',
			10
		);
	if (levelDiff < 1 && lastSectionInfo.parent) {
		insertItemAtLevelRelativeToLast(lastSectionInfo.parent, sectionInfo);
	} else if (levelDiff > 1 && lastSectionInfo.children.length > 0) {
		insertItemAtLevelRelativeToLast(
			lastSectionInfo.children[lastSectionInfo.children.length - 1],
			sectionInfo
		);
	} else {
		const parentItem = lastSectionInfo;
		parentItem.children.push(sectionInfo);
		sectionInfo.parent = parentItem;
	}
	return sectionInfo;
}

function getFadTableOfContentsForReadme(
	document: Document | Node
): TableOfContentsItem[] {
	const root = {
		children: [],
		parent: undefined,
	} as unknown as TableOfContentsItem;
	evaluateXPathToNodes<Node>(
		'//heading',
		document
	).reduce<TableOfContentsItem>(
		(lastSectionInfo, node) =>
			insertItemAtLevelRelativeToLast(
				lastSectionInfo,
				getFadSectionInfo(node, FAD_SECTIONS.HEADING)
			),
		root
	);
	return root.children;
}

function getFadSectionInfo(
	node: Node,
	sectionType: SectionTypeWithoutVariants
): TableOfContentsItem {
	return {
		id:
			typeof sectionType.id === 'string'
				? sectionType.id
				: sectionType.id(node),
		label:
			typeof sectionType.label === 'string'
				? sectionType.label
				: sectionType.label(node),

		node,

		traversal: sectionType.traversal || './node()',

		// FAD article TOC's do not have nesting, madlad
		children: [],
	};
}

export function getFadTableOfContentsItems(node: Node): TableOfContentsItem[] {
	return getMatchingFadSectionsTypesForNode(node, FAD_SECTIONS_LIST).map(
		(sectionType) => getFadSectionInfo(node, sectionType)
	);
}

export function getClosestTableOfContentsItem(
	node: Node,
	possibleSectionTypes: SectionType[] = FAD_SECTIONS_LIST
): TableOfContentsItem {
	const sectionType = possibleSectionTypes.find((sectionType) =>
		evaluateXPathToBoolean(sectionType.test, node)
	);
	if (sectionType && (sectionType as SectionTypeWithVariants).variants) {
		return getClosestTableOfContentsItem(
			node,
			(sectionType as SectionTypeWithVariants).variants
		);
	}
	if (!sectionType) {
		throw new Error(`Node <${node.nodeName}> is not a valid section`);
	}

	return getFadSectionInfo(node, sectionType as SectionTypeWithoutVariants);
}

export function getFadTableOfContents(
	node: Document | Node
): TableOfContentsItem[] {
	const documentElement =
		(node as Document).documentElement ||
		node.ownerDocument?.documentElement;
	if (!documentElement) {
		throw new Error(
			'Cannot compute table of contents for a detached node.'
		);
	}
	if (documentElement.nodeName === 'narrative') {
		// @TODO check this more nicely, keep namespaces into account etc.
		return getFadTableOfContentsForReadme(node);
	}

	return FAD_SECTIONS_FLAT_LIST.map((sectionType) => {
		const sectionNode = evaluateXPathToFirstNode<Node>(
			`./*${sectionType.test}`,
			documentElement
		);
		return sectionNode ? getFadSectionInfo(sectionNode, sectionType) : null;
	}).filter(Boolean) as TableOfContentsItem[];
}
