/* eslint-disable @typescript-eslint/no-explicit-any */

import type { MutableRefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export type SearchRequestResult = {
	pagePath: string;
	ancestry: string[];
	filePath: string;
	navtitle: string;
	navtitlePositions: [number, number][];
	product: string;
	refNodeId: string;
	rootNodeId: string | null;
	score: number;
	snippet?: string;
	snippetNodeLocalName?: string;
	snippetPositions?: [number, number][];
	title: string;
	titlePositions: [number, number][];
};

export type SearchRequestResults = {
	indexLoadMode: string;
	searchDuration: string;
	version: string;

	results: SearchRequestResult[];
	resultsCount: number;
};

export type OnSearchResultsCallback = (
	error: Error | null,
	isLoading: boolean,
	searchResults: SearchRequestResults | null,
	query: string
) => void;

export function getPathForSearchRoute(
	versionSlug: string,
	query: string,
	pageNumber: number
): string {
	return `/${versionSlug}/search?q=${encodeURIComponent(
		query
	)}&page=${pageNumber}`;
}
function getQueryParameterByName(
	queryString: string,
	name: string
): string | undefined {
	const regex = new RegExp(`[\\?&]${name}=([^&]*)`);
	const results = regex.exec(queryString);

	return !results || !results.length
		? undefined
		: decodeURIComponent(results[1].replace(/\+/g, ' '));
}
export function useSearchQueryParameters(): {
	page: number;
	query: string;
} {
	const { search } = useLocation();
	const searchQueryParameters = useMemo(
		() => ({
			page: parseInt(getQueryParameterByName(search, 'page') || '0', 10),
			query: getQueryParameterByName(search, 'q') || '',
		}),
		[search]
	);
	return searchQueryParameters;
}

export function useSearchRouteNavigation(
	versionSlug: string,
	initialQuery: string,
	initialResultPageNumber: number,
	scrollContainerRef: MutableRefObject<HTMLDivElement | null>
): {
	error: Error | null;
	isLoading: boolean;
	searchResults: SearchRequestResults | null;
	onSearchResults: OnSearchResultsCallback;
} {
	const [query, setQuery] = useState(initialQuery);
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [searchResults, setSearchResults] =
		useState<SearchRequestResults | null>(null);
	const initialSearchPageRef = useRef(initialResultPageNumber);

	const navigate = useNavigate();
	const onSearchResults: OnSearchResultsCallback = useCallback(
		(error, isLoading, searchResults, newQuery) => {
			const previousQuery = query;
			setError(error);
			setIsLoading(isLoading);
			setSearchResults(searchResults);
			setQuery(newQuery);

			scrollContainerRef.current?.scrollTo(0, 0);

			// Update location state when there is a onSearchResults context.
			// This ensures that the search query stays the same when navigation back to the search
			// page from a result page.
			navigate(
				getPathForSearchRoute(
					versionSlug,
					newQuery,
					initialSearchPageRef.current
				),
				{ replace: true }
			);

			if (previousQuery !== newQuery) {
				initialSearchPageRef.current = 0;
			}
		},
		[query, versionSlug, scrollContainerRef]
	);

	return { error, isLoading, searchResults, onSearchResults };
}

export function useSearchResultsFetcher(
	versionSlug: string,
	query: string,
	limit?: number,
	doFetch?: boolean,
	onSearchResults?: OnSearchResultsCallback
): {
	searchResults: SearchRequestResults | null;
	error: Error | null;
	isLoading: boolean;
} {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [searchResults, setSearchResults] =
		useState<SearchRequestResults | null>(null);
	const lastRequestAbortController = useRef<AbortController | null>(null);

	useEffect(
		(): (() => void) => {
			if (!query || !doFetch) {
				setError(null);
				setSearchResults(null);
				setIsLoading(false);

				if (onSearchResults) {
					onSearchResults(null, false, null, query);
				}

				return () => {
					/* no-op */
				};
			}

			setError(null);
			setIsLoading(true);

			if (onSearchResults) {
				onSearchResults(null, true, searchResults, query);
			}

			// Cancel the in flight fetch request, if any.
			if (lastRequestAbortController.current) {
				lastRequestAbortController.current.abort();
				lastRequestAbortController.current = null;
			}

			const abortController = (lastRequestAbortController.current =
				new AbortController());
			fetch(
				`/api/search/${versionSlug}?${
					limit ? `limit=${limit}&` : ''
				}q=${encodeURIComponent(query)}`,
				{
					signal: abortController.signal,
				}
			)
				.then(async (response) => {
					if (
						abortController !== lastRequestAbortController.current
					) {
						return;
					}

					if (response.status !== 200) {
						throw new Error(
							`Received non 200 status: ${response.status} ${response.statusText}.`
						);
					}

					const parsedResponse = await response.json();
					setError(null);
					setSearchResults(parsedResponse);
					setIsLoading(false);

					if (onSearchResults) {
						onSearchResults(null, false, parsedResponse, query);
					}

					if ((window as any).ga) {
						(window as any).ga(
							'send',
							'pageview',
							`/${versionSlug}/search?q=${encodeURIComponent(
								query
							)}&page=0${limit ? `&limit=${limit}` : ''}`
						);
					}
				})
				.catch((error) => {
					if (error && error.name === 'AbortError') {
						// The request was aborted due to a more recent query, ignore the error.
						return;
					}

					error = new Error(
						'An error occurred while performing the search, this might be due to an error in the search query, or with the search engine.'
					);

					setError(error);
					setSearchResults(null);
					setIsLoading(false);

					if (onSearchResults) {
						onSearchResults(error, false, null, query);
					}
				});

			return (): void => {
				// Cancel the in flight fetch request, if any.
				if (lastRequestAbortController?.current?.abort) {
					lastRequestAbortController.current.abort();
					lastRequestAbortController.current = null;
				}
			};
		},
		// Omitting dependency on `searchResults`, it would cause a fetch being followed up by the next fetch
		//   immediately. I am too lazy to hoist that object to a different ref object so I compare it later.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[versionSlug, query, limit, doFetch, onSearchResults]
	);

	return { searchResults, error, isLoading };
}
