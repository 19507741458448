import { useMemo } from 'react';

import type { SitemapJson } from '../types';
import { ENDPOINT_LOADING, useEndpointCache } from './endpoint-caching';
import { useSafeRoutingParameters } from './route-parameters';

export const SITEMAP_NODE_NOT_FOUND = '__SITEMAP_NODE_NOT_FOUND__';
export const SITEMAP_NOT_FOUND = '__SITEMAP_NOT_FOUND__';

const FETCH_SITEMAP_FOR_VERSION = async (
	versionSlug: string
): Promise<SitemapJson[] | typeof SITEMAP_NOT_FOUND> => {
	const response = await fetch(`/static/sitemap/${versionSlug}.json`);
	if (!response.ok) {
		return SITEMAP_NOT_FOUND;
	}
	return response.json();
};

export function useSitemap():
	| SitemapJson[]
	| typeof ENDPOINT_LOADING
	| typeof SITEMAP_NOT_FOUND {
	const { version } = useSafeRoutingParameters();
	return useEndpointCache(FETCH_SITEMAP_FOR_VERSION, version);
}

export function useSitemapAncestry():
	| SitemapJson[]
	| typeof ENDPOINT_LOADING
	| typeof SITEMAP_NODE_NOT_FOUND {
	const { path } = useSafeRoutingParameters();
	const sitemap = useSitemap();
	if (sitemap === ENDPOINT_LOADING || sitemap === SITEMAP_NOT_FOUND) {
		return ENDPOINT_LOADING;
	}
	return sitemap.reduce<SitemapJson[] | typeof SITEMAP_NODE_NOT_FOUND>(
		function r(trail, item): SitemapJson[] | typeof SITEMAP_NODE_NOT_FOUND {
			if (trail !== SITEMAP_NODE_NOT_FOUND) {
				return trail;
			}
			if (item.href === path) {
				return [item];
			}
			const descendantsMatching = item.children.reduce(r, trail);
			return descendantsMatching !== SITEMAP_NODE_NOT_FOUND
				? [item, ...descendantsMatching]
				: SITEMAP_NODE_NOT_FOUND;
		},
		SITEMAP_NODE_NOT_FOUND
	);
}

export function useMatchingSitemapNode(
	selector: (item: SitemapJson) => boolean
): SitemapJson | typeof ENDPOINT_LOADING | typeof SITEMAP_NODE_NOT_FOUND {
	const sitemap = useSitemap();
	const found = useMemo(() => {
		if (sitemap === ENDPOINT_LOADING) {
			return ENDPOINT_LOADING;
		}
		if (sitemap === SITEMAP_NOT_FOUND) {
			return SITEMAP_NODE_NOT_FOUND;
		}
		return (
			sitemap.reduce<SitemapJson | typeof SITEMAP_NODE_NOT_FOUND | null>(
				function recursive(
					found,
					item
				): SitemapJson | typeof SITEMAP_NODE_NOT_FOUND | null {
					if (found) {
						return found;
					}
					return selector(item)
						? item
						: item.children.reduce(recursive, found);
				},
				null
			) || SITEMAP_NODE_NOT_FOUND
		);
	}, [sitemap, selector]);
	return found;
}

export function useCurrentSitemapNode():
	| SitemapJson
	| typeof ENDPOINT_LOADING
	| typeof SITEMAP_NODE_NOT_FOUND {
	const { path } = useSafeRoutingParameters();
	return useMatchingSitemapNode((item) => item.href === path);
}
