import styled from '@emotion/styled/macro';

import { SPACING_SMALL } from '../shared/global';
import { mixinSpaceChildren } from '../shared/mixins';

export const Image = styled.img`
	max-width: 100%;
	height: auto;
	display: block;
	margin: 0 auto;
`;

export const Figure = styled.figure<{
	isFullWidth?: boolean;
	borderless?: boolean;
}>`
	${({ isFullWidth, borderless }) =>
		isFullWidth || borderless
			? ''
			: `width: calc(100% - 2 * var(--spacing-large));`}

	${({ borderless }) =>
		borderless
			? ''
			: `
				border: 1px solid var(--color-grey-100);
				padding: var(--spacing-small);
			`}
	margin-left: auto;
	margin-right: auto;
	font-size: var(--font-size-small);
	text-align: center;

	${mixinSpaceChildren(SPACING_SMALL)}
`;

export const VideoFigure = styled(Figure)`
	text-align: left;
`;
