import styled from '@emotion/styled/macro';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';

import { Paragraph } from '../content-components';

const SearchTipsContainer = styled.div`
	font-size: 14px;
	color: var(--color-grey-400);
`;

export const SearchTips: FunctionComponent = () => {
	return (
		<SearchTipsContainer>
			<Paragraph>
				Protip. You can use wildcards within terms (t*rm), and
				explicitly include (+term) or exclude (-term) specific terms.
			</Paragraph>
		</SearchTipsContainer>
	);
};

// Replace default XML entities with their actual characters
// Replace any occurrences of "&#nnn;" in markup with code point from "nnn".
// Replace any occurrences of "&#xnnnn;" in markup with code point from "nnnn".
const decodeXmlEntities = (text: string) =>
	text
		.replace(/&amp;/g, '&')
		.replace(/&lt;/g, '<')
		.replace(/&gt;/g, '>')
		.replace(/&#(\d{3});/g, (_, codePoint) =>
			String.fromCodePoint(parseInt(codePoint, 10))
		)
		.replace(/&#x([0-9a-fA-F]{4});/g, (_, codePoint) =>
			String.fromCodePoint(parseInt(codePoint, 16))
		);

const limitPrefixLength = (text: string, limit: number): string => {
	if (text.length <= limit) {
		return text;
	}
	let lastWhitespace = -1;
	const iLimit = text.length - limit;
	for (let i = text.length - 1; i >= 0; i--) {
		const char = text.charAt(i);
		if (char === ' ' && /[.:;]/.exec(text.charAt(i - 1))) {
			return text.substr(i + 1);
		}
		if (char === '\n') {
			return text.substr(i + 1);
		}
		if (i <= iLimit) {
			return text.substr(
				lastWhitespace > 0 ? lastWhitespace + 1 : -limit
			);
		}
		if (/[.:;\n\s]/.exec(char)) {
			lastWhitespace = i;
		}
	}
	return text.substr(-limit);
};

const limitPostfixLength = (text: string, limit: number): string => {
	if (text.length <= limit) {
		return text;
	}

	let lastWhitespace = -1;
	const iLimit = limit;
	for (let i = 0; i < text.length; i++) {
		const char = text.charAt(i);
		if (/[.:;]/.exec(char) && text.charAt(i + 1) === ' ') {
			return text.substr(0, i + 1);
		}
		if (char === '\n') {
			return text.substr(0, i);
		}
		if (i >= iLimit) {
			return text.substr(0, lastWhitespace > 0 ? lastWhitespace : limit);
		}
		if (/[.:;\n\s]/.exec(char)) {
			lastWhitespace = i;
		}
	}

	return text.substr(0, limit);
};

enum TextPartType {
	NORMAL,
	HIGHLIGHT,
	ELLIPSIS,
}

type TextPart = [string, TextPartType];

const LIMIT_MAX_TEXT_TOTAL = 550;
const LIMIT_MAX_TEXT_BEFORE = 50;
const LIMIT_MAX_TEXT_BETWEEN = 50;
const LIMIT_MAX_TEXT_AFTER = 50;

class TextPartArray extends Array<TextPart> {
	public totalTextLength: number;

	public constructor(...items: TextPart[]) {
		super(...items);

		this.totalTextLength = 0;
	}

	public push(...items: TextPart[]) {
		this.totalTextLength += items.reduce(
			(l, item) => l + item[0].length,
			0
		);
		return super.push(...items);
	}
}

const textAndPositionsReducer = (
	text: string,
	limitLength = false,
	acc: TextPartArray,
	position: [number, number],
	index: number,
	all: [number, number][]
) => {
	if (limitLength && acc.totalTextLength >= LIMIT_MAX_TEXT_TOTAL) {
		if (acc[acc.length - 1][1] !== TextPartType.ELLIPSIS) {
			acc.push([' … ', TextPartType.ELLIPSIS]);
		}
		return acc;
	}

	// Add text after before first start position.
	if (index === 0 && position[0] !== 0) {
		let prefixStartOffset = 0;
		let prefixText = decodeXmlEntities(
			text.substr(prefixStartOffset, position[0])
		);
		if (limitLength && prefixText.length > LIMIT_MAX_TEXT_BEFORE) {
			const fullPrefixTextLength = prefixText.length;
			prefixText = limitPrefixLength(prefixText, LIMIT_MAX_TEXT_BEFORE);
			prefixStartOffset =
				prefixStartOffset + fullPrefixTextLength - prefixText.length;
		}
		acc.push([prefixText, TextPartType.NORMAL]);
	}

	// Add text between previous end position and current start position...
	if (index > 0) {
		const previousPosition = all[index - 1];
		const previousEndPosition = previousPosition[0] + previousPosition[1];
		// ...but only if there is any text in between those positions.
		if (position[0] > previousEndPosition) {
			const textInBetween = decodeXmlEntities(
				text.substr(
					previousEndPosition,
					position[0] - previousEndPosition
				)
			);
			if (limitLength && textInBetween.length > LIMIT_MAX_TEXT_BETWEEN) {
				const postfixText = limitPostfixLength(
					textInBetween,
					LIMIT_MAX_TEXT_BETWEEN / 2
				);
				const prefixText = limitPrefixLength(
					textInBetween,
					LIMIT_MAX_TEXT_BETWEEN / 2
				);
				acc.push([postfixText, TextPartType.NORMAL]);
				acc.push([
					textInBetween.includes('\n') ? '\n…\n' : ' … ',
					TextPartType.ELLIPSIS,
				]);

				if (acc.totalTextLength >= LIMIT_MAX_TEXT_TOTAL) {
					return acc;
				}

				acc.push([prefixText, TextPartType.NORMAL]);
			} else {
				acc.push([textInBetween, TextPartType.NORMAL]);
			}
		}
	}

	// Add highlighted text for position.
	acc.push([
		decodeXmlEntities(text.substr(position[0], position[1])),
		TextPartType.HIGHLIGHT,
	]);

	// Add text after last end position, if any.
	const endPosition = position[0] + position[1];
	if (index === all.length - 1 && endPosition !== text.length) {
		let postfixEndOffset = text.length - position[1];
		let postfixText = decodeXmlEntities(
			text.substr(endPosition, postfixEndOffset)
		);
		if (limitLength && postfixText.length > LIMIT_MAX_TEXT_AFTER) {
			const fullPostfixTextLength = postfixText.length;
			postfixText = limitPostfixLength(postfixText, LIMIT_MAX_TEXT_AFTER);
			postfixEndOffset =
				postfixEndOffset - fullPostfixTextLength + postfixText.length;
		}
		acc.push([postfixText, TextPartType.NORMAL]);
	}

	return acc;
};

export const HighlightedText: FunctionComponent<{
	text: string;
	positions: [number, number][];
	highlightComponent: FunctionComponent<PropsWithChildren>;
	limitLength?: boolean;
}> = ({ text, positions, highlightComponent, limitLength }) => {
	// Memoize all text parts, containing highlighted and non highlighted parts.
	const textParts = useMemo<TextPartArray>(() => {
		if (!positions.length) {
			return new TextPartArray([text, TextPartType.NORMAL]);
		}
		const limitLengthActual =
			!!limitLength && text.length > LIMIT_MAX_TEXT_TOTAL;
		return positions.reduce<TextPartArray>(
			(...args) =>
				textAndPositionsReducer(text, limitLengthActual, ...args),
			new TextPartArray()
		);
	}, [text, positions, limitLength]);

	const Highlight = highlightComponent;
	return (
		<span>
			{textParts.map((part, index) => {
				const text = part[0];
				switch (part[1]) {
					case TextPartType.ELLIPSIS:
						return (
							<span key={index} className="ellipsis">
								{text}
							</span>
						);
					case TextPartType.HIGHLIGHT:
						return <Highlight key={index}>{text}</Highlight>;
					case TextPartType.NORMAL:
					default:
						return <span key={index}>{text}</span>;
				}
			})}
		</span>
	);
};
