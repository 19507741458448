import type { FunctionComponent } from 'react';

import {
	DOCUMENT_NOT_FOUND,
	getUrlForPath,
	useDocumentString,
} from '../app-hooks/content-rendering';
import { ENDPOINT_LOADING } from '../app-hooks/endpoint-caching';
import { useSafeRoutingParameters } from '../app-hooks/route-parameters';
import {
	SITEMAP_NODE_NOT_FOUND,
	useCurrentSitemapNode,
} from '../app-hooks/sitemap';
import { useVersionList } from '../app-hooks/versions';
import {
	CODE_BLOCK_LANGUAGE_MAP,
	CodeBlock,
	CodePhrase,
	CollapsibleBox,
	CollapsibleBoxGroup,
	Paragraph,
	Spinner,
	Table,
	TableCell,
	TableHeader,
	TableRow,
} from '../content-components';
import { LayoutContentSheet } from './layout';

const CollapsibleBoxWithXml: FunctionComponent = () => {
	const { path } = useSafeRoutingParameters();
	const str = useDocumentString(getUrlForPath(path));
	const isOk = str !== ENDPOINT_LOADING && str !== DOCUMENT_NOT_FOUND;
	return (
		<CollapsibleBox label={`Document XML${isOk ? '' : ` (${str})`}`}>
			{isOk ? (
				<CodeBlock language={CODE_BLOCK_LANGUAGE_MAP.xml}>
					{str}
				</CodeBlock>
			) : (
				<Spinner />
			)}
		</CollapsibleBox>
	);
};

export const DebugContent: FunctionComponent = () => {
	if (process.env.REACT_APP_DEBUG_CONTENT !== 'true') {
		return null;
	}

	return (
		<LayoutContentSheet>
			<DebugContentInner />
		</LayoutContentSheet>
	);
};

const DebugContentInner: FunctionComponent = () => {
	const routingParams = useSafeRoutingParameters();
	const currentSitemap = useCurrentSitemapNode();
	const versions = useVersionList();

	return (
		<CollapsibleBox label="Debugging this article">
			<Table>
				<TableRow>
					<TableHeader>
						<Paragraph>Path</Paragraph>
					</TableHeader>
					<TableCell>
						<CodePhrase>{routingParams.path}</CodePhrase>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableHeader>
						<Paragraph>Version</Paragraph>
					</TableHeader>
					<TableCell>
						<CodePhrase>{routingParams.version}</CodePhrase>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableHeader>
						<Paragraph>Document</Paragraph>
					</TableHeader>
					<TableCell>
						<CodePhrase>{routingParams.document}</CodePhrase>
					</TableCell>
				</TableRow>
			</Table>
			<CollapsibleBoxGroup>
				<CollapsibleBox
					label={`Sitemap node${
						typeof currentSitemap === 'string'
							? ` (${currentSitemap})`
							: ``
					}`}
				>
					<CodeBlock language={CODE_BLOCK_LANGUAGE_MAP.json}>
						{JSON.stringify(currentSitemap, null, '  ')}
					</CodeBlock>
				</CollapsibleBox>

				<CollapsibleBox
					label={`Version list${
						typeof versions === 'string' ? ` (${versions})` : ``
					}`}
				>
					<CodeBlock language={CODE_BLOCK_LANGUAGE_MAP.json}>
						{JSON.stringify(versions, null, '  ')}
					</CodeBlock>
				</CollapsibleBox>

				{currentSitemap !== SITEMAP_NODE_NOT_FOUND &&
					currentSitemap !== ENDPOINT_LOADING &&
					currentSitemap.href && <CollapsibleBoxWithXml />}
			</CollapsibleBoxGroup>
		</CollapsibleBox>
	);
};
