import type { FunctionComponent } from 'react';

import { ENDPOINT_LOADING } from '../app-hooks/endpoint-caching';
import {
	SITEMAP_NODE_NOT_FOUND,
	useSitemapAncestry,
} from '../app-hooks/sitemap';
import { useCurrentVersion } from '../app-hooks/versions';
import { Breadcrumbs, Spinner } from '../content-components';
import { SPACING_MEDIUM_SMALL } from '../content-components/shared/global';

export const ContextfulBreadcrumbs: FunctionComponent = () => {
	const ancestry = useSitemapAncestry();
	const currentVersion = useCurrentVersion();

	if (ancestry === ENDPOINT_LOADING || currentVersion === ENDPOINT_LOADING) {
		return <Spinner size={SPACING_MEDIUM_SMALL} />;
	}

	if (ancestry === SITEMAP_NODE_NOT_FOUND) {
		// If the sitemap yielded an error, don't show breadcrumbs at all
		return null;
	}

	return (
		<Breadcrumbs
			trail={ancestry.map((item, index) => ({
				label:
					index === 0
						? typeof currentVersion === 'string'
							? item.label
							: currentVersion.label
						: item.label,
				href:
					item.href && index < ancestry.length - 1
						? item.href
						: undefined,
			}))}
		/>
	);
};
