import styled from '@emotion/styled/macro';
import { faVideo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent } from 'react';

import { Anchor } from './links';

// DEV-22306 Unused VideoWrapper and VideoFrame as Cookiebot wasn't working for the video frame.
// Leaving the code here for future reference and when a soltion can be found for Cookiebot.
const VideoWrapper = styled.div`
	display: block;
	position: relative;
	padding: 56.25% 0 0 0;

	@media screen and (max-width: 1280px) {
		display: none !important;
	}
`;

const VideoIframe = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
`;

const VideoLink = styled.div`
	margin-bottom: 0;
`;

export const VideoPlayer: FunctionComponent<{
	src: string;
}> = ({ src = '' }) => {
	return (
		<VideoLink>
			<FontAwesomeIcon icon={faVideo} />{' '}
			<Anchor href={src} target="_blank">
				Watch the video
			</Anchor>
		</VideoLink>
	);
};
