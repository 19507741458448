import styled from '@emotion/styled/macro';
import type { FunctionComponent } from 'react';

import { Heading } from '../atoms/headings';
import { Anchor } from '../atoms/links';

export type PageMenuItemType = {
	label: string;
	href?: string;
	active?: boolean;
	children: PageMenuItemType[];
};

/**
 * Component for the text of one item
 */

type PageMenuItemLabelPropsType = {
	active?: boolean;
	level?: number;
};

const PageMenuItemLabel = styled.li<PageMenuItemLabelPropsType>`
	font-family: var(--font-primary);
	font-size: var(--font-size-small);
	/* line-height: 1.81; */

	list-style-type: none;

	padding-left: ${({ level }) =>
		`calc(var(--spacing-small) + (${level} * var(--spacing-medium-small)))`};

	box-shadow: ${({ active }) =>
		active ? 'inset 4px 0px var(--color-grey-800)' : 'none'};

	&:hover,
	&:focus {
		background-color: var(--color-purple-200);
	}
	cursor: pointer;

	color: var(--color-grey-800);
	text-decoration: none;

	> * {
		display: block;
		outline: none;
		color: inherit !important;
		text-decoration: inherit !important;
	}
`;

/**
 * Component for one item
 */

type PageMenuItemPropsType = {
	activeItem?: PageMenuItemType;
	item: PageMenuItemType;
	level: number;
};

/**
 * Component for the wrapper
 */

const PageMenuContainer = styled.nav`
	border-left: 4px solid var(--color-grey-800);

	// @TODO fix this, probably a bug in original Gatsby code:
	> H4 {
		padding-left: var(--spacing-small);
	}
`;

/**
 * Public API
 */

type PageMenuPropsType = {
	activeItem?: PageMenuItemType;
	items: PageMenuItemType[];
	label?: string;
};

export const PageMenuItem: FunctionComponent<PageMenuItemPropsType> = ({
	activeItem,
	item,
	level,
}) => (
	<>
		<PageMenuItemLabel
			level={level}
			active={activeItem === item || item.active}
		>
			{item.href ? (
				<Anchor href={item.href}>{item.label}</Anchor>
			) : (
				item.label
			)}
		</PageMenuItemLabel>
		{item.children.map((child, index) => (
			<PageMenuItem item={child} key={index} level={level + 1} />
		))}
	</>
);

export const PageMenu: FunctionComponent<PageMenuPropsType> = ({
	items,
	activeItem,
	label,
}) => {
	if (!items.length) {
		return null;
	}
	return (
		<PageMenuContainer>
			{label ? <Heading level={4}>{label}</Heading> : null}
			{items.map((item, index) => (
				<PageMenuItem
					key={index}
					item={item}
					activeItem={activeItem}
					level={0}
				/>
			))}
		</PageMenuContainer>
	);
};
