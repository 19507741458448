import styled from '@emotion/styled/macro';

import { SPACING_SMALL } from '../shared/global';
import { mixinSpaceChildren } from '../shared/mixins';

export const ListItem = styled.li<{
	spacing?: string;
}>`
	margin-left: 0;
	padding-left: var(--spacing-medium);

	${({ spacing = SPACING_SMALL }) => mixinSpaceChildren(spacing)};

	& > :first-of-type {
		margin-top: 0;
	}
	& > :last-child {
		margin-bottom: 0;
	}
`;

export const UnorderedList = styled.ul<{
	spacing?: string;
}>`
	margin-left: 0;
	display: flex;
	flex-direction: column;

	list-style-type: disc;

	${({ spacing = SPACING_SMALL }) => mixinSpaceChildren(spacing)};

	> ${ListItem}::marker {
		color: var(--color-grey-800);
	}
`;

export const OrderedList = styled.ol<{
	spacing?: string;
}>`
	margin-left: 0;
	display: flex;
	flex-direction: column;

	${({ spacing = SPACING_SMALL }) => mixinSpaceChildren(spacing)};

	> ${ListItem}::marker {
		color: var(-color-grey-800);
	}
`;
