import styled from '@emotion/styled';
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent } from 'react';
import { useCallback, useMemo } from 'react';

import type { SearchRequestResult } from '../app-hooks/search';
import { Anchor, Heading } from '../content-components';
import { HighlightedText } from './search-shared';

const SearchPagingContainer = styled.div`
	margin-top: calc(var(--spacing-large) * -1 + var(--spacing-medium));
	margin-bottom: var(--spacing-large);

	text-align: center;

	> span {
		display: inline-block;
		width: 48px;
		height: 48px;
		text-align: center;
		vertical-align: middle;
		line-height: 48px;
		background-color: var(--color-grey-50);

		&.page {
			cursor: pointer;
		}

		&.active {
			color: var(--color-grey-50);
			background-color: var(--color-purple-400);
		}

		&.page:hover {
			background-color: var(--color-purple-50);
		}

		&.previous,
		&.next {
			background-color: transparent;
			color: var(--color-grey-800);
			cursor: pointer;
			font-size: 24px;

			&:hover {
				color: var(--color-purple-400);
			}

			&.hidden {
				visibility: hidden;
			}
		}

		&.previous {
			margin-right: var(--spacing-medium);
		}

		&.next {
			margin-left: var(--spacing-medium);
		}
	}

	> span + span {
		margin-left: 1px;
	}
`;

function createRange(start: number, end: number) {
	return Array.from(Array(end - start + 1), (_, index) => index + start);
}

const PAGE_BUTTON_LIMIT = 9;
export const SearchPaging: FunctionComponent<{
	activePage: number;
	numberOfPages: number;
	onPageChange(page: number): void;
}> = ({ activePage, numberOfPages, onPageChange }) => {
	const pages = useMemo<(number | null)[]>(() => {
		if (numberOfPages <= PAGE_BUTTON_LIMIT) {
			return createRange(0, numberOfPages - 1);
		}
		if (activePage <= 3) {
			return [
				...createRange(0, PAGE_BUTTON_LIMIT - 5),
				null,
				...createRange(numberOfPages - 3, numberOfPages - 1),
			];
		}
		if (activePage >= numberOfPages - 4) {
			return [
				...createRange(0, 2),
				null,
				...createRange(
					numberOfPages - PAGE_BUTTON_LIMIT + 4,
					numberOfPages - 1
				),
			];
		}
		return [
			...createRange(0, 1),
			null,
			...createRange(activePage - 1, activePage + PAGE_BUTTON_LIMIT - 8),
			null,
			...createRange(numberOfPages - 2, numberOfPages - 1),
		];
	}, [activePage, numberOfPages]);

	const onClickHandler = useCallback(
		(event: React.MouseEvent<HTMLSpanElement>) => {
			onPageChange(
				parseInt(event?.currentTarget?.dataset?.page || '', 10)
			);
		},
		[onPageChange]
	);

	if (numberOfPages <= 1) {
		return null;
	}

	return (
		<SearchPagingContainer>
			<span
				className={`previous${activePage <= 0 ? ' hidden' : ''}`}
				onClick={onClickHandler}
				data-page={activePage - 1}
			>
				<FontAwesomeIcon icon={faChevronLeft} />
			</span>

			{pages.map((page, index) => {
				if (page === null) {
					return (
						<span className="ellipsis" key={`ell-${index}`}>
							…
						</span>
					);
				}
				return (
					<span
						className={page === activePage ? 'active' : 'page'}
						key={page}
						onClick={onClickHandler}
						data-page={page}
					>
						{page + 1}
					</span>
				);
			})}

			<span
				className={`next${
					activePage >= numberOfPages - 1 ? ' hidden' : ''
				}`}
				onClick={onClickHandler}
				data-page={activePage + 1}
			>
				<FontAwesomeIcon icon={faChevronRight} />
			</span>
		</SearchPagingContainer>
	);
};

export const SearchPageSearchTipsContainer = styled.div`
	padding: var(--spacing-small) 0;
`;

export const SearchPageBottomCenterContainer = styled.div`
	margin-top: var(--spacing-medium);
	text-align: center;
`;

const SearchPageTermHighlight = styled.span`
	background-color: var(--color-purple-200);
`;
const SearchPageResultAncestry = styled.div`
	color: var(--color-grey-800);
	font-size: 14px;
	line-height: normal;
`;
const SearchPageSnippet = styled.div`
	color: var(--color-grey-800);
	margin-top: var(--spacing-medium-small);

	// &.snippet--code-block,
	// &.snippet--codeblock,
	// &.snippet--example,
	// &.snippet--pre {
	// 	font-family: monospace;
	// 	white-space: pre-wrap;
	// }

	span.ellipsis {
		color: var(--color-grey-800);
		font-style: italic;
		margin: 0 var(--spacing-small);
	}
`;
const SearchResultContainer = styled.div`
	h2 {
		margin-top: var(--spacing-large);
	}

	a {
		color: inherit;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
`;
export const SearchResult: FunctionComponent<{
	result: SearchRequestResult;
	versionSlug: string;
}> = ({ result, versionSlug }) => {
	const ancestryString = useMemo(
		() => result.ancestry.slice().reverse().join(' > '),
		[result.ancestry]
	);

	const snippetClassName = useMemo(
		() => `snippet--${result.snippetNodeLocalName}`,
		[result.snippetNodeLocalName]
	);

	return (
		<SearchResultContainer>
			<Heading level={2}>
				<Anchor href={`/${versionSlug}/${result.pagePath}`}>
					<HighlightedText
						text={result.title}
						positions={result.titlePositions}
						highlightComponent={SearchPageTermHighlight}
					/>
				</Anchor>
			</Heading>

			<SearchPageResultAncestry>
				{ancestryString}
			</SearchPageResultAncestry>

			{!!result.snippet && (
				<SearchPageSnippet className={snippetClassName}>
					<HighlightedText
						text={result.snippet}
						positions={result.snippetPositions || []}
						highlightComponent={SearchPageTermHighlight}
						limitLength={true}
					/>
				</SearchPageSnippet>
			)}
		</SearchResultContainer>
	);
};
