import { useEffect, useMemo, useState } from 'react';

class EndpointCache<T, K extends unknown[]> {
	private readonly cached = new Map<string, Error | T>();

	private busy: { [key: string]: Promise<T> | false } = {};

	private readonly fetcher: (...keys: K) => Promise<T>;

	public constructor(fetch: (...keys: K) => Promise<T>) {
		this.fetcher = fetch;
	}

	private createStorageKey(keys: K) {
		return keys.join('|');
	}

	public async fetch(keys: K) {
		const key = this.createStorageKey(keys);
		if (this.busy[key]) {
			await this.busy[key];
			return this.cached.get(key);
		}
		if (!this.cached.get(key)) {
			try {
				const promise = this.fetcher(...keys);
				this.busy[key] = promise;
				const response = await promise;
				this.cached.set(key, response);
			} catch (e: unknown) {
				this.cached.set(key, e as Error);
			}
			this.busy[key] = false;
		}
		return this.cached.get(key);
	}

	public get(keys: K) {
		return this.cached.get(this.createStorageKey(keys)) || ENDPOINT_LOADING;
	}
}

const CACHES = new Map<() => unknown, EndpointCache<unknown, unknown[]>>();

export const ENDPOINT_LOADING = '__ENDPOINT_LOADING__';

/**
 * Performs an expensive task (calling an endpoint) using the provided fetcher and the given keys. Prevents concurrent
 * and duplicate requests for the same unique keys.
 */
export function useEndpointCache<K extends unknown[], T = unknown>(
	fetcher: (...keys: K) => Promise<T>,
	...keys: K
): T | typeof ENDPOINT_LOADING {
	// Make sure there is a cache
	if (!CACHES.get(fetcher)) {
		const cache = new EndpointCache<T, K>(fetcher);
		CACHES.set(
			fetcher,
			cache as unknown as EndpointCache<unknown, unknown[]>
		);
	}
	const cache = useMemo(
		() => CACHES.get(fetcher) as unknown as EndpointCache<T, K>,
		[fetcher]
	);

	// Set the initial value synchronously, if we can
	const [value, setValue] = useState<Error | T | typeof ENDPOINT_LOADING>(
		cache.get(keys)
	);

	useEffect(() => {
		if (value !== ENDPOINT_LOADING && value === cache.get(keys)) {
			// If the value is set and it correlates with the key, stop.
			return;
		}
		void cache.fetch(keys).then((sitemap) => {
			if (!sitemap) {
				return;
			}
			setValue(sitemap);
		});
		setValue(ENDPOINT_LOADING);
	}, [cache, value, keys]);

	if (value instanceof Error) {
		throw value;
	}

	return value;
}
