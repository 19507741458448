import styled from '@emotion/styled/macro';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Styled components
 */

const TableOverflowCurtains = styled.div<{
	showLeftCurtain?: boolean;
	showRightCurtain?: boolean;
}>`
	position: relative;

	::before {
		content: '';

		display: ${({ showLeftCurtain }) =>
			showLeftCurtain ? 'block' : 'none'};
		position: absolute;

		top: 0;
		bottom: 0;
		left: 0;

		width: 24px;

		background: linear-gradient(
			to left,
			rgba(42, 42, 42, 0) 0%,
			rgba(42, 42, 42, 0.1) 75%,
			rgba(42, 42, 42, 0.2) calc(100% - 1px),
			rgba(42, 42, 42, 0.6)
		);
	}
	::after {
		content: '';

		display: ${({ showRightCurtain }) =>
			showRightCurtain ? 'block' : 'none'};
		position: absolute;

		top: 0;
		bottom: 0;
		right: 0;

		width: 24px;

		background: linear-gradient(
			to right,
			rgba(42, 42, 42, 0) 0%,
			rgba(42, 42, 42, 0.1) 75%,
			rgba(42, 42, 42, 0.2) calc(100% - 1px),
			rgba(42, 42, 42, 0.6)
		);
	}
`;
const TableViewport = styled.div`
	overflow-x: auto;
`;
const TableStyledComponent = styled.table`
	border-collapse: collapse;
`;

export const Table: FunctionComponent<PropsWithChildren> = ({ children }) => {
	const viewportRef = useRef<HTMLTableElement>(null);

	const [showLeftCurtain, setShowLeftCurtain] = useState(false);
	const [showRightCurtain, setShowRightCurtain] = useState(false);

	const updateCurtains = useCallback(() => {
		if (!viewportRef.current) {
			return;
		}

		setShowLeftCurtain(viewportRef.current.scrollLeft > 0);
		const maximumScrollDistance =
			viewportRef.current.scrollWidth - viewportRef.current.offsetWidth;

		setShowRightCurtain(
			maximumScrollDistance > 0 &&
				Math.abs(
					maximumScrollDistance - viewportRef.current.scrollLeft
				) > 1
		);
	}, []);

	useEffect(() => {
		window.addEventListener('resize', updateCurtains);
		updateCurtains();
		return () => {
			window.removeEventListener('resize', updateCurtains);
		};
	}, [updateCurtains]);

	const handleScroll = useCallback(() => {
		updateCurtains();
	}, [updateCurtains]);

	return (
		<TableOverflowCurtains
			showLeftCurtain={showLeftCurtain}
			showRightCurtain={showRightCurtain}
		>
			<TableViewport onScroll={handleScroll} ref={viewportRef}>
				<TableStyledComponent>
					<tbody>{children}</tbody>
				</TableStyledComponent>
			</TableViewport>
		</TableOverflowCurtains>
	);
};

export const TableRow = styled.tr``;

export const TableCell = styled.td<{ isWide?: boolean }>`
	padding: var(--spacing-small);
	font-size: var(--font-size-small);
	border: 1px solid var(--legacy-color-medium-grey);

	text-align: left;
	vertical-align: top;

	min-width: ${({ isWide }) => (isWide ? 250 : 80)}px;

	> * + * {
		margin-top: var(--spacing-medium-small) !important;
	}
`;

export const TableHeader = styled.th<{ isWide?: boolean }>`
	padding: var(--spacing-small);
	font-size: var(--font-size-small);

	border: 1px solid var(--legacy-color-medium-grey);

	text-align: left;
	vertical-align: top;

	min-width: ${({ isWide }) => (isWide ? 250 : 80)}px;

	> * + * {
		margin-top: var(--spacing-medium-small) !important;
	}

	background-color: var(--legacy-color-light-grey);
`;
