import styled from '@emotion/styled/macro';
import { faChevronDoubleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent, PropsWithChildren } from 'react';

import { Heading } from '../atoms/headings';
import { Bold } from '../atoms/inline-formatting';
import { Anchor } from '../atoms/links';
import { Paragraph } from '../atoms/paragraphs';
import { SPACING_MEDIUM_SMALL, SPACING_SMALL } from '../shared/global';
import { mixinSpaceChildren } from '../shared/mixins';

export const LargeList = styled.nav`
	> * + * {
		margin-top: var(--spacing-medium-small);
	}
`;

const LargeListItemStyledComponent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;

	> * + * {
		margin-left: var(--spacing-small);
	}
`;

const LargeListItemHeadingStyledComponent = styled.div`
	> a {
		display: inline-block;

		margin-left: calc(var(--spacing-small) / 2 * -1);
		padding: calc(var(--spacing-small) / 2);

		text-decoration: none;

		&:hover {
			background-color: var(--color-purple-50);
		}
	}
`;

const LargeListItemContentsStyledComponent = styled.div`
	${mixinSpaceChildren(SPACING_MEDIUM_SMALL)}
`;

const LargeListItemChildStyledComponent = styled.div`
	margin-left: var(--spacing-medium);

	${mixinSpaceChildren(SPACING_SMALL)}
`;

export const LargeListItem: FunctionComponent<
	PropsWithChildren & {
		href?: string;
		label: string;
	}
> = ({ label, href, children }) => (
	<LargeListItemStyledComponent>
		<FontAwesomeIcon
			icon={faChevronDoubleRight}
			style={{ width: 14, height: 11 }}
		/>

		<LargeListItemContentsStyledComponent>
			<LargeListItemHeadingStyledComponent>
				<Heading level={4}>
					{href ? <Anchor href={href}>{label}</Anchor> : label}
				</Heading>
			</LargeListItemHeadingStyledComponent>

			{children}
		</LargeListItemContentsStyledComponent>
	</LargeListItemStyledComponent>
);
export const LargeListItemChild: FunctionComponent<
	PropsWithChildren & {
		href?: string;
		label: string;
	}
> = ({ label, href, children }) => (
	<LargeListItemChildStyledComponent>
		<Paragraph>
			<Bold>{href ? <Anchor href={href}>{label}</Anchor> : label}</Bold>
		</Paragraph>

		{children}
	</LargeListItemChildStyledComponent>
);
