import type { FunctionComponent } from 'react';
import { createElement, useMemo } from 'react';

import {
	DOCUMENT_NOT_FOUND,
	useDocumentDom,
} from '../app-hooks/content-rendering';
import { ENDPOINT_LOADING } from '../app-hooks/endpoint-caching';
import { Anchor, Bold, Note, Paragraph, Spinner } from '../content-components';
import { SPACING_MEDIUM_SMALL } from '../content-components/shared/global';
import { RULES_RSS } from '../content-rules';

const FONTO_BLOG_RSS_FEED = process.env.REACT_APP_FONTO_BLOG_RSS_FEED || '';

export const RssFeed: FunctionComponent = () => {
	const dom =
		FONTO_BLOG_RSS_FEED === ''
			? DOCUMENT_NOT_FOUND
			: // eslint-disable-next-line react-hooks/rules-of-hooks
			  useDocumentDom(FONTO_BLOG_RSS_FEED);
	const rules = RULES_RSS;

	const renderedContent = useMemo(() => {
		switch (dom) {
			case ENDPOINT_LOADING:
				return <Spinner size={SPACING_MEDIUM_SMALL} />;
			case DOCUMENT_NOT_FOUND:
				return (
					<Note type="tip">
						<Paragraph>
							<Bold>Tip:</Bold> Your question might be answered in
							one of our{' '}
							<Anchor
								external={true}
								href="https://www.fontoxml.com/fonto-why-how/?utm_source=documentation&utm_medium=feed&utm_campaign=documentation"
							>
								developer blogs.
							</Anchor>
						</Paragraph>
					</Note>
				);
			default:
				return (
					<Note type="tip">
						<Paragraph>
							<Bold>Tip:</Bold> Your question might be answered in
							one of our{' '}
							<Anchor
								external={true}
								href="https://www.fontoxml.com/fonto-why-how/?utm_source=documentation&utm_medium=feed&utm_campaign=documentation"
							>
								developer blogs.
							</Anchor>
						</Paragraph>
						<Paragraph>
							<Bold>Latest Fonto Why &amp; How: </Bold>
							{rules.render(
								createElement,
								dom as unknown as Node
							)}
						</Paragraph>
					</Note>
				);
		}
	}, [rules, dom]);

	return <>{renderedContent}</>;
};
