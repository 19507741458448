import styled from '@emotion/styled/macro';
import type { FunctionComponent } from 'react';

import { Layout, LayoutContentSheet } from '../app-components/layout';
import { SearchInput } from '../app-components/search-input';
import { SEO } from '../app-components/seo';
import { useSitemap } from '../app-hooks/sitemap';
import urlTo404Svg from '../app-images/404.svg';
import { Anchor, Heading, Paragraph } from '../content-components';

const SplitColumnsContainer = styled.div`
	display: flex;
	flex-direction: row;

	> * + * {
		margin-left: var(--spacing-large);
	}
`;

const ContentColumn = styled.div`
	flex: 1;
`;

const PageContentContainer = styled.div`
	display: block;

	> * + * {
		margin-top: var(--spacing-large) !important;
	}
`;

const NotFoundComic = styled.img`
	flex: none;

	width: 505px;
	height: 593px;
`;

export const NotFoundLayoutContent: FunctionComponent = () => {
	const sitemap = useSitemap();
	const goBackHref = typeof sitemap === 'string' ? '/' : sitemap[0].href;

	return (
		<SplitColumnsContainer>
			<ContentColumn>
				<Heading level={1}>404</Heading>

				<PageContentContainer>
					<Heading level={2}>Oops... Something went wrong.</Heading>

					<SearchInput isStandalone />

					{goBackHref && (
						<Paragraph>
							Or go back to{' '}
							<Anchor href={goBackHref}>Overview</Anchor>
						</Paragraph>
					)}
				</PageContentContainer>
			</ContentColumn>

			<NotFoundComic src={urlTo404Svg} />
		</SplitColumnsContainer>
	);
};

export const NotFoundRoute: FunctionComponent = () => {
	return (
		<Layout enableSidebar={false}>
			<SEO
				title="Page not found"
				description="The requested page could not be found."
				canonicalLinkPath="/404"
			/>
			<LayoutContentSheet>
				<NotFoundLayoutContent />
			</LayoutContentSheet>
		</Layout>
	);
};
