import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent, MouseEvent } from 'react';
import { useCallback, useState } from 'react';

function copyStringToClipboard(str: string) {
	// https://www.30secondsofcode.org/blog/s/copy-text-to-clipboard-with-javascript
	const element = window.document.createElement('textarea');
	element.value = str;
	element.setAttribute('readonly', '');
	element.style.position = 'absolute';
	element.style.left = '-9999px';
	window.document.body.appendChild(element);
	element.select();
	window.document.execCommand('copy');
	window.document.body.removeChild(element);
}

export const DeeplinkButton: FunctionComponent<{ id: string }> = ({ id }) => {
	const [isHovered, setIsHovered] = useState(false);
	const onClick = useCallback(
		(event: MouseEvent<SVGSVGElement, globalThis.MouseEvent>) => {
			event.stopPropagation();
			copyStringToClipboard(
				`${window.location.href.split('#').shift()}${
					id ? `#${id}` : ''
				}`
			);
		},
		[id]
	);
	return (
		<FontAwesomeIcon
			icon={faLink}
			onClick={onClick}
			fixedWidth
			className="deeplink-button"
			onMouseEnter={() => {
				setIsHovered(true);
			}}
			onMouseLeave={() => {
				setIsHovered(false);
			}}
			color={
				isHovered ? `var(--color-grey-600)` : `var(--color-grey-300)`
			}
		/>
	);
};
