import { evaluateXPathToMap, evaluateXPathToStrings } from 'fontoxpath';

/**
 * Converts the DITA CALS syntax for cell spanning, which means mentioning the starting column and end coliumn by name,
 *   into an HTML-table style numeric value.
 *
 * The properties "rowSpan" and "colSpan" of the returned value are used as props to <TableCell> and <TableHeader>
 *   components.
 */
export function getDitaCalsCellSpan(node: Node): {
	colSpan: number;
	rowSpan: number;
} {
	const { morerows, namest, nameend } = evaluateXPathToMap(
		`
			map {
				'morerows': string(@morerows),
				'namest': string(@namest),
				'nameend': string(@nameend)
			}
		`,
		node
	);
	let colspan = 1;
	if (namest && nameend) {
		const [min, max] = evaluateXPathToStrings(
			`
			./ancestor::tgroup[1]/colspec[
				@colname = ($namest, $nameend)
			]/@colnum`,
			node,
			null,
			{ namest, nameend }
		).map((num) => (num ? parseInt(num, 10) : 1));
		colspan = max - min + 1;
	}
	return {
		rowSpan: morerows ? parseInt(morerows, 10) : 1,
		colSpan: colspan,
	};
}
