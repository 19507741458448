import { css } from '@emotion/react';
import type { SerializedStyles } from '@emotion/utils';

export const mixinSpaceChildren = (spaceSize: string): SerializedStyles => css`
	> * {
		margin-top: 0;
		margin-bottom: ${spaceSize};
		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const mixinFrame = (paddingSize: string): SerializedStyles => css`
	border: 1px solid var(--legacy-color-medium-grey);
	padding: ${paddingSize};
`;
