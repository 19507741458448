import { evaluateXPathToBoolean } from 'fontoxpath';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PageMenu } from '../content-components';
import {
	getDitaTableOfContents,
	getFadTableOfContents,
} from '../content-rules';
import { NS_URI_FAD } from '../shared/constants';

// Note: using querySelector is normally not a good idea in a React app,
// but we can get away with it since these pages are static.
// Ideally the refs to the scrollContainer and pageMenuItems are injected as
// props (or passed via React context).

function useLocationUpdateForScrollPosition(
	scrollContainerIdentifier: string,
	headingSelector: string
): void {
	// Note: IntersectionObserver does not call its callback often enough and
	// reliably after the user stops scrolling. So we use manual detection
	// whenever the user has finished scrolling (debounce of 100ms).
	const navigate = useNavigate();
	const navigateToClosestHeading = useCallback(() => {
		if (!headingSelector) {
			return;
		}
		const scrollContainer = window.document.querySelector(
			`#${scrollContainerIdentifier}`
		);
		if (!scrollContainer) {
			return;
		}

		// Prevent double slashes when changing location.
		let currentPath = window.location.pathname;
		if (currentPath.endsWith('/')) {
			currentPath = currentPath.slice(0, -1);
		}

		// Get all heading elements to which can be linked to, sorted by distance.
		const scrollContainerRect = scrollContainer.getBoundingClientRect();
		const headingElements = Array.from(
			window.document.querySelectorAll(headingSelector)
		).sort(
			(a, b) =>
				Math.abs(a.getBoundingClientRect().top) -
				Math.abs(b.getBoundingClientRect().top)
		);
		for (const headingElement of headingElements) {
			// Only if the parent (section) element of the heading is (partly)
			// visible within the scroll containers viewport, ...
			const parentElementRect =
				headingElement.parentElement?.getBoundingClientRect();
			if (
				parentElementRect &&
				parentElementRect.top < scrollContainerRect.bottom &&
				parentElementRect.bottom > scrollContainerRect.top
			) {
				const id = headingElement.getAttribute('id');
				// ... and if the id is different,
				if (id && window.location.hash !== `#${id}`) {
					// Record the current scroll position and after changing the
					// hash, scroll back to it so the visual scroll position doesn't
					// suddenly jump when the user stops scrolling.
					const scrollTop = scrollContainer.scrollTop;
					navigate(`#${id}`, { replace: true });
					scrollContainer.scrollTo({ top: scrollTop });
				}

				// Found closest within viewport, so done navigating to closest heading.
				return;
			}

			// ...Otherwise continue with the next closest heading.
		}

		// When no heading is closest and within the scroll containers viewport, navigate to none of
		// them (no id hash).
		navigate(currentPath, { replace: true });
	}, [headingSelector, scrollContainerIdentifier, navigate]);

	const timeoutRef = useRef<number>();

	const handleScroll = useCallback(() => {
		// Debounce the scroll event
		window.clearTimeout(timeoutRef.current);
		timeoutRef.current = window.setTimeout(navigateToClosestHeading, 100);
	}, [navigateToClosestHeading]);

	useEffect(() => {
		const scrollContainer = window.document.querySelector(
			`#${scrollContainerIdentifier}`
		);
		scrollContainer?.addEventListener('scroll', handleScroll);
		return () => {
			scrollContainer?.removeEventListener('scroll', handleScroll);

			window.clearTimeout(timeoutRef.current);
		};
	}, [handleScroll, scrollContainerIdentifier]);
}

export default function usePageMenuForDocumentScrollPosition(
	xmlDocument: Node
): JSX.Element {
	const { hash } = useLocation();

	const pageMenuItems = useMemo(() => {
		const tableOfContents = evaluateXPathToBoolean(
			'namespace-uri(/*) = $fadNsUri',
			xmlDocument,
			null,
			{ fadNsUri: NS_URI_FAD }
		)
			? getFadTableOfContents(xmlDocument)
			: getDitaTableOfContents(xmlDocument);

		// A non-recursive function to transform table-of-contents items to a PageMenu items tree, and
		// ignore the 3rd level and beyond.
		return tableOfContents.map((item) => ({
			...item,
			href: `#${item.id}`,
			active: `#${item.id}` === hash,
			children: item.children.map((child) => ({
				...child,
				href: `#${child.id}`,
				active: `#${child.id}` === hash,
				children: [],
			})),
		}));
	}, [xmlDocument, hash]);

	// This "query" is used to get the DOM elements associated with page menu items. Those elements, in turn, are used
	//   to determine where the browser viewport is approximately scrolled to.
	// Stringified here to help React more easily determine when this cross-section changes --> probably never.
	const headerSelectorQuery = (function r(items): string[] {
		return items.reduce<string[]>(
			(flat, item) =>
				flat.concat([`#${item.id}`]).concat(r(item.children)),
			[]
		);
	})(pageMenuItems).join(', ');

	useLocationUpdateForScrollPosition('scroll-container', headerSelectorQuery);

	const pageMenu = useMemo(
		() => <PageMenu label="On this page" items={pageMenuItems} />,
		[pageMenuItems]
	);

	return pageMenu;
}
