import styled from '@emotion/styled/macro';
import type { FunctionComponent, PropsWithChildren } from 'react';

export const Underline = styled.u``;

export const Bold = styled.b``;

export const Italic = styled.i``;

export const Subscript = styled.span`
	vertical-align: sub;
	font-size: 85%;
`;

export const Superscript = styled.span`
	vertical-align: super;
	font-size: 85%;
`;

export const StrikeThrough = styled.span`
	text-decoration: line-through;
`;

const CodePhraseStyledComponent = styled.code``;

export const CodePhrase: FunctionComponent<
	PropsWithChildren & {
		// options the children prop when set to something else than undefined.
		text?: string;
	}
> = (props) => (
	<CodePhraseStyledComponent {...props}>
		{props.text === undefined ? props.children : props.text}
	</CodePhraseStyledComponent>
);
