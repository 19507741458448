import { evaluateXPathToBoolean, evaluateXPathToString } from 'fontoxpath';
import { serializeToWellFormedString } from 'slimdom';
import { ReactRenderer } from 'xml-renderer';

import {
	Anchor,
	BrokenAnchor,
	CamelCaseWordBreak,
	Heading,
	ListItem,
	Paragraph,
	Section,
	UnorderedList,
} from '../../content-components';
import { getClosestTableOfContentsItem } from '../querying/fad-table-of-contents';
import type { RuleContext } from '../types';

function stringifyElement(node: Node) {
	const attributes: string = (node as any).attributes.reduce(
		(str: string, attr: any) => `${str} ${attr.localName}="${attr.value}"`,
		''
	);
	return `<${node.nodeName}${attributes} />`;
}

const rules = new ReactRenderer<RuleContext>();

// :
// :   SHARED
// :
// <<<<<<<

/**
 * Node types
 */
rules.add('self::text()', ({ node }) => <>{node.nodeValue}</>);

rules.add('self::element()', ({ traverse, node }) => {
	// eslint-disable-next-line no-console
	console.log(`- Unconfigured element: ${stringifyElement(node)}`);
	return <>{traverse()}</>;
});

rules.add('self::document-node()', ({ traverse }) => <>{traverse()}</>);

rules.add('self::processing-instruction()', () => null);

rules.add('self::comment()', () => null);

// For text nodes that are prone to containing long camel-cased phrases
// (probably API concepts), splice in zero-width spaces on each capital letter
// to improve word-wrapping in browser:
rules.add(
	`
			self::text() and ancestor::*[
				(: Inspired by some selectors from DITA rules :)
				self::title[parent::topic or parent::task] or
				self::codeph or
				self::xref[@format="fad"] or

				(: Inspired by some selectors from FAD rules :)
				self::name or
				self::heading
			]
		`,
	({ node }) =>
		node.nodeValue ? <CamelCaseWordBreak text={node.nodeValue} /> : null
);

rules.add('self::node()', ({ node }) => {
	throw new Error(
		`Unconfigured node: ${serializeToWellFormedString(node as any)}`
	);
});

rules.add('self::inbound-references', ({ traverse, node }) => {
	const hasDitaReferences = evaluateXPathToBoolean('./*[@type="dita"]', node);
	const hasFadReferences = evaluateXPathToBoolean('./*[@type="fad"]', node);
	const sectionInfo = getClosestTableOfContentsItem(node);
	return (
		<Section>
			<Heading level={2} id={sectionInfo.id}>
				{sectionInfo.label}
			</Heading>

			{hasDitaReferences && (
				<>
					<Paragraph>Guides and concept pages:</Paragraph>
					<UnorderedList
						spacing="0px"
						style={{
							display: 'block',
							columns: 2,
							fontSize: 'var(--font-size-small)',
						}}
					>
						{traverse('./*[@type = "dita"]')}
					</UnorderedList>
				</>
			)}

			{hasFadReferences && (
				<>
					<Paragraph>API reference pages:</Paragraph>
					<UnorderedList
						spacing="0px"
						style={{
							display: 'block',
							columns: 2,
							fontSize: 'var(--font-size-small)',
						}}
					>
						{traverse('./*[@type = "fad"]')}
					</UnorderedList>
				</>
			)}
		</Section>
	);
});

rules.add('self::inbound-reference', ({ node, traverse }) => {
	const href = evaluateXPathToString('./@reference', node);
	return (
		<ListItem>
			<Anchor href={href}>{traverse()}</Anchor>
		</ListItem>
	);
});
rules.add('self::inbound-reference[@unresolved]', ({ traverse }) => (
	<ListItem>
		<BrokenAnchor>{traverse()}</BrokenAnchor>
	</ListItem>
));

export default rules;
