import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import type { FunctionComponent, PropsWithChildren } from 'react';

import { DeeplinkButton } from '../../app-components/deep-linking';

const headingSharedStyles = css`
	margin: 0 !important;
	overflow-wrap: break-word;

	> .deeplink-button {
		visibility: hidden;
		cursor: pointer;
		line-height: inherit;
		vertical-align: baseline;
	}

	&:hover > .deeplink-button {
		visibility: visible;
	}
`;

// Export the styled components so they can be reused for styling
const HeadingStyledComponent = [
	styled.h1`
		color: var(--color-grey-800);
		font-family: var(--font-secondary);
		font-size: 48px;
		font-weight: var(--font-weight-bold);
		line-height: 1.58;

		${headingSharedStyles}
		> .deeplink-button {
			font-size: 32px;
		}
	`,
	styled.h2`
		color: var(--color-grey-800);
		font-family: var(--font-primary);
		font-size: 32px;
		font-weight: 600;
		line-height: 1.71;

		${headingSharedStyles}
		> .deeplink-button {
			font-size: 16px;
		}
	`,
	styled.h3`
		color: var(--color-grey-800);
		font-family: var(--font-secondary);
		font-size: 24px;
		font-weight: var(--font-weight-bold);
		line-height: 1.76;

		${headingSharedStyles}
		> .deeplink-button {
			font-size: 16px;
		}
	`,
	styled.h4`
		color: var(--color-grey-800);
		font-family: var(--font-primary);
		font-size: 18px;
		font-weight: 800;
		line-height: 1.78;

		${headingSharedStyles}
		> .deeplink-button {
			font-size: 16px;
		}
	`,
];

export const Heading: FunctionComponent<
	PropsWithChildren & {
		deepLinkableId?: string;
		level?: number;
		id?: string;
	}
> = ({ deepLinkableId, children, level = 6, id, ...restProps }) => {
	const effectiveLevel = Math.max(
		0,
		Math.min(HeadingStyledComponent.length - 1, level - 1)
	);
	const Component = HeadingStyledComponent[effectiveLevel];

	return (
		<Component id={id} {...restProps}>
			{children}
			{deepLinkableId !== undefined ? (
				<>
					{' '}
					<DeeplinkButton id={deepLinkableId} />
				</>
			) : null}
		</Component>
	);
};

Heading.defaultProps = {
	level: 6,
};
