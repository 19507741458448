import styled from '@emotion/styled/macro';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent } from 'react';
import { useMemo } from 'react';

import type {
	SearchRequestResult,
	SearchRequestResults,
} from '../app-hooks/search';
import { Anchor, Heading, Note, Paragraph } from '../content-components';
import { HighlightedText, SearchTips } from './search-shared';

const SearchFormDropContainer = styled.div`
	flex: none;
	background-color: var(--color-grey-50);
	box-shadow: var(--elevation-400);
	position: absolute;
	margin: 0;
	top: 40px;
	left: 0;
	width: 100%;
	@media (max-width: 1000px) {
		width: calc(100vw - 2 * var(--spacing-medium-small));
	}

	h4 {
		margin: var(--spacing-medium);
		margin-bottom: var(--spacing-small);
		color: var(--color-grey-400);
		font-size: 14px;
		font-weight: var(--font-weight-normal);
		line-height: normal;
		text-transform: uppercase;
	}
`;

const SearchDropResultContainer = styled(Paragraph)<{
	isShowAll?: boolean;
}>`
	a {
		display: block;
		padding: var(--spacing-small) var(--spacing-medium);

		color: ${({ isShowAll }) =>
			isShowAll ? 'var(--color-purple-600)' : 'inherit'};
		text-decoration: ${({ isShowAll }) =>
			isShowAll ? 'underline' : 'none'};

		&:hover {
			background-color: var(--color-purple-50);
		}
	}

	&:last-of-type {
		margin-bottom: var(--spacing-small);
	}
`;

const SearchDropErrorContainer = styled.div`
	margin: var(--spacing-small) var(--spacing-medium);
	margin-bottom: var(--spacing-medium);
`;

const SearchDropResultAncestry = styled.span`
	color: var(--color-grey-600);
	font-size: 14px;
	font-weight: var(--font-weight-normal);
	line-height: normal;
	text-decoration: none;
	margin-left: var(--spacing-medium-small);
`;

const SearchDropTermHighlight = styled.span`
	background-color: var(--color-green-300-translucent);
`;

const SearchDropTipsContainer = styled.div`
	padding: var(--spacing-small) var(--spacing-medium);
`;

const SearchDropResult: FunctionComponent<{
	result: SearchRequestResult;
	versionSlug: string;
}> = ({ result, versionSlug }) => {
	const ancestryString = useMemo(
		() => result.ancestry.slice().reverse().join(' > '),
		[result.ancestry]
	);

	return (
		<SearchDropResultContainer>
			<Anchor href={`/${versionSlug}/${result.pagePath}`}>
				<HighlightedText
					text={result.title}
					positions={result.titlePositions}
					highlightComponent={SearchDropTermHighlight}
				/>
				<SearchDropResultAncestry>
					|&nbsp;&nbsp;{ancestryString}
				</SearchDropResultAncestry>
			</Anchor>
		</SearchDropResultContainer>
	);
};

export const SearchDropResults: FunctionComponent<{
	error?: Error | null;
	isLoading: boolean;
	query: string;
	results?: SearchRequestResults | null;
	versionSlug: string;
}> = ({ error, isLoading, query, results, versionSlug }) => {
	if (error) {
		return (
			<SearchFormDropContainer>
				<Heading level={4}>Search returned no results.</Heading>
				<SearchDropTipsContainer>
					<SearchTips />
				</SearchDropTipsContainer>
				<SearchDropErrorContainer>
					{/* <Note type="warning" isCompact={true}> */}
					<Note type="warning" icon={faExclamationTriangle}>
						<Paragraph>{error.message}</Paragraph>
					</Note>
				</SearchDropErrorContainer>
			</SearchFormDropContainer>
		);
	}

	if (!query) {
		return (
			<SearchFormDropContainer>
				<SearchDropTipsContainer>
					<SearchTips />
				</SearchDropTipsContainer>
			</SearchFormDropContainer>
		);
	}

	if (results && !results.resultsCount) {
		return (
			<SearchFormDropContainer>
				<Heading level={4}>
					{'Search returned no results. '}
					{isLoading && (
						<FontAwesomeIcon
							fixedWidth={true}
							icon={faSpinner}
							spin={true}
						/>
					)}
				</Heading>
				<SearchDropTipsContainer>
					<SearchTips />
				</SearchDropTipsContainer>
			</SearchFormDropContainer>
		);
	}

	return (
		<SearchFormDropContainer>
			<Heading level={4}>
				{'Top search results '}
				{isLoading && (
					<FontAwesomeIcon
						fixedWidth={true}
						icon={faSpinner}
						spin={true}
					/>
				)}
			</Heading>

			{(!query || (!!results && results.resultsCount < 3)) && (
				<SearchDropTipsContainer>
					<SearchTips />
				</SearchDropTipsContainer>
			)}

			{!!results &&
				results.results.map((result) => (
					<SearchDropResult
						key={result.filePath}
						result={result}
						versionSlug={versionSlug}
					/>
				))}

			{!error && !!query && !!results?.resultsCount && (
				<SearchDropResultContainer isShowAll={true}>
					<Anchor
						href={`/${versionSlug}/search?q=${encodeURIComponent(
							query
						)}`}
					>
						Show all
					</Anchor>
				</SearchDropResultContainer>
			)}
		</SearchFormDropContainer>
	);
};
