import type { FunctionComponent } from 'react';
import { createElement, Fragment, useMemo } from 'react';

type CamelCaseWordBreakPropsType = {
	text: string;
};

export const CamelCaseWordBreak: FunctionComponent<CamelCaseWordBreakPropsType> =
	({ text }) => {
		const parts = useMemo(() => {
			const camelCaseRegexp = /[^\b][A-Z]/g;
			const parts = [];
			let result;
			let previousIndex = 0;
			while ((result = camelCaseRegexp.exec(text)) !== null) {
				parts.push(
					text.slice(previousIndex, result.index + 1),
					<wbr />
				);
				previousIndex = result.index + 1;
			}
			parts.push(text.slice(previousIndex));

			return parts;
		}, [text]);

		return createElement(Fragment, {}, ...parts);
	};
