import type { RefObject, UIEvent, WheelEvent } from 'react';
import { useCallback, useRef } from 'react';

const highestMainNavigationMarginTop = 0;

export function useScrollToToggleMainNavigation({
	mainNavigationRef,
	contentScrollContainerRef,
}: {
	mainNavigationRef: RefObject<HTMLElement>;
	contentScrollContainerRef: RefObject<HTMLElement>;
}): {
	onContentWheel(event: WheelEvent): void;
	onContentScroll(event: UIEvent): void;
} {
	const scrollDeltaX = useRef(0);
	const scrollDeltaY = useRef(0);

	const handleContentWheel = useCallback(
		(event: WheelEvent) => {
			scrollDeltaX.current = event.deltaX;
			scrollDeltaY.current = event.deltaY;

			if (
				mainNavigationRef.current &&
				contentScrollContainerRef.current &&
				scrollDeltaY.current < 0 &&
				scrollDeltaX.current === 0
			) {
				const currentMainNavigationMarginTop = parseInt(
					window.getComputedStyle(mainNavigationRef.current)
						.marginTop,
					10
				);

				if (
					currentMainNavigationMarginTop !==
					highestMainNavigationMarginTop
				) {
					mainNavigationRef.current.style.marginTop = `${highestMainNavigationMarginTop}px`;
				}
			}
		},
		[contentScrollContainerRef, mainNavigationRef]
	);

	const handleContentScroll = useCallback(() => {
		if (
			contentScrollContainerRef.current &&
			mainNavigationRef.current &&
			scrollDeltaY.current &&
			scrollDeltaX.current === 0
		) {
			const currentMainNavigationMarginTop = parseInt(
				window.getComputedStyle(mainNavigationRef.current).marginTop,
				10
			);

			if (
				scrollDeltaY.current > 0 &&
				currentMainNavigationMarginTop ===
					highestMainNavigationMarginTop
			) {
				mainNavigationRef.current.style.marginTop = `calc(-1 * var(--responsive-corporate-menu-height))`;
			}
		}
	}, [contentScrollContainerRef, mainNavigationRef]);

	return {
		onContentWheel: handleContentWheel,
		onContentScroll: handleContentScroll,
	};
}
