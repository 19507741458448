import styled from '@emotion/styled';
import { evaluateXPathToFirstNode } from 'fontoxpath';
import type { FunctionComponent } from 'react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { serializeToWellFormedString } from 'slimdom';

export const CONTENT_CALLBACK_AND_URL_BY_URN = {
	'urn:fonto:decision-tree:app': {
		getContent: (objectNode: Node): string | null => {
			const content = evaluateXPathToFirstNode<Node>(
				'./child::decisionTreeContainer/child::Q{http://schemas.fontoxml.com/decision-tree/1.0/decisionTree.xsd}decisionTree',
				objectNode
			);
			return content
				? serializeToWellFormedString(
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						content as any
				  )
				: null;
		},
		url: 'https://fontoxmldocumentation.blob.core.windows.net/decisiontree-production/index.html',
	},
};

type IFrameWrapperProps = {
	isFullScreen: boolean;
};

const IFrameWrapper = styled.div<IFrameWrapperProps>`
	${({ isFullScreen }) =>
		isFullScreen
			? `
				background-color: var(--color-grey-50);
				z-index: 2;
				height: 90vh;
				width: 95vw;
				box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
				0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
			`
			: `
				height: 70vh;
				margin-left: 0;
				margin-right: 0;
				`}
`;

const IFrameOverlay = styled.div<IFrameWrapperProps>`
	${({ isFullScreen }) =>
		isFullScreen &&
		`
			display: flex;
			align-items: center;
			justify-content: center;
			position: fixed;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(17, 12, 39, 0.6);
			z-index: 2;
			height: 100vh;
			margin-top: 0px !important;
		`}
`;

export const ObjectIframe: FunctionComponent<{
	sdkVersionName: string;
	id: string;
	url: string;
	content: string;
}> = ({ sdkVersionName, id, url, content }) => {
	const navigate = useNavigate();
	const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
	const src = `${url}?iframeId=${encodeURIComponent(
		id
	)}&sdkVersion=${encodeURIComponent(sdkVersionName)}`;

	useEffect(() => {
		function handleMessage(event: MessageEvent) {
			if (
				!event ||
				!event.source ||
				!event.data ||
				!event.data.type ||
				event.data.iframeId !== id
			) {
				return;
			}

			switch (event.data.type) {
				case 'iframe-data-request':
					(event.source as Window).postMessage(
						{
							iframeId: id,
							type: 'iframe-data-response',
							data: content,
						},
						'*'
					);
					break;
				case 'iframe-fullscreen-request':
					(event.source as Window).postMessage(
						{
							iframeId: id,
							type: 'iframe-fullscreen-response',
							isFullscreen: event.data.isFullscreen,
						},
						'*'
					);
					setIsFullScreen(event.data.isFullscreen);
					break;
				case 'iframe-navigation-request':
					navigate(event.data.target);
					break;
			}
		}

		window.addEventListener('message', handleMessage);

		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, [content, id, navigate]);

	return (
		<IFrameOverlay isFullScreen={isFullScreen}>
			<IFrameWrapper isFullScreen={isFullScreen}>
				<iframe src={src} frameBorder="0" width="100%" height="100%" />
			</IFrameWrapper>
		</IFrameOverlay>
	);
};
