import type { FunctionComponent, PropsWithChildren } from 'react';

import { Heading } from './headings';

type TutorialInfoPropsType = PropsWithChildren & {
	showHeading?: boolean;
};

export const TutorialInfo: FunctionComponent<TutorialInfoPropsType> = ({
	showHeading,
	children,
}) => {
	return (
		<>
			{showHeading ? (
				<Heading level={4}>Tutorial Information</Heading>
			) : null}
			{children}
		</>
	);
};
